import * as React from "react";
import { useSnackbar } from "notistack";
import { CtxDispatchViewport, CtxSetSnackbar } from "./contexts";
const AreaContext = ({ children, dispatch }) => {
    const { enqueueSnackbar } = useSnackbar();
    const setSnackbar = React.useCallback((variant, message) => {
        enqueueSnackbar(message, { variant });
    }, [enqueueSnackbar]);
    return (React.createElement(CtxSetSnackbar.Provider, { value: setSnackbar },
        React.createElement(CtxDispatchViewport.Provider, { value: dispatch }, children)));
};
export default AreaContext;
