import { FileType } from "@warda/library-ui/interfaces";
export const getFileId = (f) => {
    if (f.type === FileType.FOLDER) {
        const data = f.data;
        return data.folderId;
    }
    if (f.type === FileType.MEDIA) {
        const data = f.data;
        return data.fileId;
    }
    if (f.type === FileType.PORTAL) {
        const data = f.data;
        return data.hierarchyId;
    }
};
const getIdsFile = (files, filesSlcId) => {
    const idsFolder = [];
    const idsMedia = [];
    const idsPortal = [];
    files
        .filter((f) => {
        if (!filesSlcId)
            return true;
        return filesSlcId.some((i) => i === f.id);
    })
        .forEach((f) => {
        if (f.type === FileType.FOLDER) {
            idsFolder.push(Number(getFileId(f)));
        }
        if (f.type === FileType.MEDIA) {
            idsMedia.push(String(getFileId(f)));
        }
        if (f.type === FileType.PORTAL) {
            idsPortal.push(String(getFileId(f)));
        }
    });
    return {
        idsFolder,
        idsMedia,
        idsPortal,
    };
};
export default getIdsFile;
