import { FileType, } from "@warda/library-ui/interfaces";
import { KEY_ASSIGNMENTS, KEY_ATTRIBUTE_SETS, KEY_BUSINESS_IDS, KEY_CARRYOVER_PARENT, KEY_CATALOG, KEY_EDITED_ATTRIBUTES, KEY_ENTITY_ID, KEY_ENTITY_STRUCTURE_ID, KEY_ENTITY_TYPE, KEY_MEDIA, KEY_PUBLC_ATTRS, KEY_PUBLC_MEDIA, KEY_READY_ATTRS, KEY_READY_MEDIA, KEY_ROOT_DOCUMENT_ID, KEY_VIEWS_EDITING_DOCUMENT, KEY_VIEWS_EDITING_MEDIA, KEY_VIEW_CHECK, KEY_VIEW_DATA, KEY_VIEW_STATUS, } from "./components/AreaProducts/constants";
export var WsConfMM;
(function (WsConfMM) {
    WsConfMM["PUBLISHED"] = "PUBLISHED";
    WsConfMM["DRAFT"] = "DRAFT";
})(WsConfMM || (WsConfMM = {}));
export var WsOperML;
(function (WsOperML) {
    WsOperML["CREATE"] = "CREATE";
    WsOperML["DELETE"] = "DELETE";
    WsOperML["UPDATE"] = "UPDATE";
    WsOperML["MOVE"] = "MOVE";
})(WsOperML || (WsOperML = {}));
export var CellType;
(function (CellType) {
    CellType["FOLDER"] = "folder";
    CellType["FILE"] = "file";
})(CellType || (CellType = {}));
export var FileSection;
(function (FileSection) {
    FileSection["MY_FILES"] = "myfiles";
    FileSection["SHARES_PRIVATE"] = "privateshares";
})(FileSection || (FileSection = {}));
export var ArchiverStatus;
(function (ArchiverStatus) {
    ArchiverStatus["STARTED"] = "Started";
    ArchiverStatus["COMPLETED"] = "Completed";
})(ArchiverStatus || (ArchiverStatus = {}));
export var AttributeType;
(function (AttributeType) {
    AttributeType["SYSTEM"] = "SYSTEM";
    AttributeType["USER"] = "USER";
    AttributeType["MASTER"] = "MASTER";
})(AttributeType || (AttributeType = {}));
export var ContentType;
(function (ContentType) {
    ContentType["MEDIA"] = "Media";
    ContentType["ATTRIBUTE"] = "Attribute";
})(ContentType || (ContentType = {}));
export var AnnotationsRes;
(function (AnnotationsRes) {
    AnnotationsRes["NONE"] = "NONE";
    AnnotationsRes["RESOLVED"] = "RESOLVED";
    AnnotationsRes["UNRESOLVED"] = "UNRESOLVED";
})(AnnotationsRes || (AnnotationsRes = {}));
export var ViewStatus;
(function (ViewStatus) {
    ViewStatus["DEFAULT"] = "DEFAULT";
    ViewStatus["REVIEW"] = "REVIEW";
})(ViewStatus || (ViewStatus = {}));
export var ViewCheck;
(function (ViewCheck) {
    ViewCheck["NO_CHECK"] = "NO_CHECK";
    ViewCheck["CHECK"] = "CHECK";
    ViewCheck["CHECK_TO_VERIFY"] = "CHECK_TO_VERIFY";
})(ViewCheck || (ViewCheck = {}));
export var MediaType;
(function (MediaType) {
    MediaType["DOCUMENT"] = "DOCUMENT";
    MediaType["IMAGE_SH"] = "SHOOTING";
    MediaType["IMAGE_PP"] = "POST_PRODUCED";
    MediaType["VIDEO"] = "VIDEO";
})(MediaType || (MediaType = {}));
export const MediaTypeLabel = {
    [MediaType.IMAGE_SH]: "Shooting",
    [MediaType.IMAGE_PP]: "Post Production",
    [MediaType.VIDEO]: "Video",
};
export var XlsType;
(function (XlsType) {
    XlsType["UPLOAD_XLS_EDITOR"] = "editor";
    XlsType["UPLOAD_XLS_PRO"] = "pro";
})(XlsType || (XlsType = {}));
export var ViewOpt;
(function (ViewOpt) {
    ViewOpt["OPTIONAL"] = "OPTIONAL";
    ViewOpt["MANDATORY"] = "MANDATORY";
})(ViewOpt || (ViewOpt = {}));
export var ViewStatusAnnotated;
(function (ViewStatusAnnotated) {
    ViewStatusAnnotated["ANNOTATION_NOT_RESOLVED"] = "ANNOTATION_NOT_RESOLVED";
    ViewStatusAnnotated["ANNOTATION_RESOLVED"] = "ANNOTATION_RESOLVED";
    ViewStatusAnnotated["NOT_ANNOTATED"] = "NOT_ANNOTATED";
})(ViewStatusAnnotated || (ViewStatusAnnotated = {}));
export var EditFieldType;
(function (EditFieldType) {
    EditFieldType["TextField"] = "TextField";
    EditFieldType["DateField"] = "DateField";
    EditFieldType["BooleanField"] = "BooleanField";
    EditFieldType["SelectField"] = "SelectField";
    EditFieldType["TextAreaField"] = "TextAreaField";
    EditFieldType["TimestampField"] = "TimestampField";
    EditFieldType["MultiStringField"] = "MultiStringField";
    EditFieldType["CategoryField"] = "CategoryField";
    EditFieldType["MultiLinkField"] = "MultiLinkField";
    EditFieldType["HtmlField"] = "HtmlField";
})(EditFieldType || (EditFieldType = {}));
export var AType;
(function (AType) {
    AType["links"] = "links";
    AType["link"] = "link";
    AType["date"] = "date";
    AType["text"] = "text";
    AType["string"] = "string";
    AType["strings"] = "strings";
    AType["boolean"] = "boolean";
    AType["timestamp"] = "timestamp";
    AType["dictionaryEntry"] = "dictionaryEntry";
    AType["dictionaryEntries"] = "dictionaryEntries";
    AType["categories"] = "categories";
    AType["passThrough"] = "passThrough";
})(AType || (AType = {}));
export var ViewType;
(function (ViewType) {
    ViewType["MEDIA"] = "media";
    ViewType["DOCUMENT"] = "document";
})(ViewType || (ViewType = {}));
// LINK https://wardafactory.atlassian.net/wiki/spaces/SEEC/pages/1241939989/Dizionari
export var DictionaryType;
(function (DictionaryType) {
    DictionaryType["A"] = "plain";
    DictionaryType["B"] = "entriesByCatalog";
    DictionaryType["C"] = "byCatalog";
    DictionaryType["D"] = "byCatalogMultiLanguage";
    DictionaryType["E"] = "multiLanguageEntries";
})(DictionaryType || (DictionaryType = {}));
export var StringType;
(function (StringType) {
    StringType["textEasy"] = "Text";
    StringType["textarea"] = "Textarea";
    StringType["textHtml"] = "Text HTML";
})(StringType || (StringType = {}));
export var LinkType;
(function (LinkType) {
    LinkType["plain"] = "plain";
    LinkType["carryover"] = "carry-over";
})(LinkType || (LinkType = {}));
export var IAssignmentType;
(function (IAssignmentType) {
    IAssignmentType["DEFAULT"] = "DEFAULT";
})(IAssignmentType || (IAssignmentType = {}));
export var IAssignmentStatus;
(function (IAssignmentStatus) {
    IAssignmentStatus["DEFAULT"] = "DEFAULT";
})(IAssignmentStatus || (IAssignmentStatus = {}));
export var IAssignmentContentType;
(function (IAssignmentContentType) {
    IAssignmentContentType["VIEW"] = "VIEW";
    IAssignmentContentType["ATTRIBUTE"] = "ATTRIBUTE";
    IAssignmentContentType["ENTITY"] = "ENTITY";
})(IAssignmentContentType || (IAssignmentContentType = {}));
export var RequestTypeBulkEditing;
(function (RequestTypeBulkEditing) {
    RequestTypeBulkEditing["ATTRIBUTE_EDITING"] = "ATTRIBUTE_EDITING";
    RequestTypeBulkEditing["ASSIGNMENT"] = "ASSIGNMENT";
    RequestTypeBulkEditing["VIEW_EDITING"] = "VIEW_EDITING";
    RequestTypeBulkEditing["VIEW_RESET"] = "VIEW_RESET";
    RequestTypeBulkEditing["READY"] = "READY";
    RequestTypeBulkEditing["PUBLISHED"] = "PUBLISHED";
})(RequestTypeBulkEditing || (RequestTypeBulkEditing = {}));
export var RequestType;
(function (RequestType) {
    RequestType["READY"] = "READY";
    RequestType["READY_MEDIA"] = "READY_MEDIA";
    RequestType["READY_ATTRIBUTE"] = "READY_ATTRIBUTE";
    RequestType["ATTRIBUTE_EDITING"] = "ATTRIBUTE_EDITING";
    RequestType["ASSIGNMENT"] = "ASSIGNMENT";
    RequestType["VIEW_EDITING"] = "VIEW_EDITING";
    RequestType["VIEW_RESET"] = "VIEW_RESET";
    RequestType["PUBLISHED"] = "PUBLISHED";
    RequestType["REMOVE_READY_MEDIA"] = "REMOVE_READY_MEDIA";
})(RequestType || (RequestType = {}));
export var RequestStatus;
(function (RequestStatus) {
    RequestStatus["error"] = "error";
    RequestStatus["pending"] = "pending";
    RequestStatus["done"] = "done";
    RequestStatus["todo"] = "todo";
})(RequestStatus || (RequestStatus = {}));
export var Category;
(function (Category) {
    Category["DEFAULT"] = "DEFAULT";
    Category["STILL_LIFE"] = "STILL-LIFE";
    Category["WORN"] = "WORN";
})(Category || (Category = {}));
export var CdnPublishedStatus;
(function (CdnPublishedStatus) {
    CdnPublishedStatus[CdnPublishedStatus["NOT_PUBLISHED"] = 0] = "NOT_PUBLISHED";
    CdnPublishedStatus[CdnPublishedStatus["IN_PUBLISHING"] = 1] = "IN_PUBLISHING";
    CdnPublishedStatus[CdnPublishedStatus["PUBLISHED"] = 2] = "PUBLISHED";
    CdnPublishedStatus[CdnPublishedStatus["IN_UNPUBLISHING"] = 3] = "IN_UNPUBLISHING";
})(CdnPublishedStatus || (CdnPublishedStatus = {}));
export var AttributeFamily;
(function (AttributeFamily) {
    AttributeFamily["ALL_ATTRIBUTES"] = "ALL ATTRIBUTES";
    AttributeFamily["CATEGORIES"] = "CATEGORIES";
    AttributeFamily["MEDIA"] = "MEDIA";
    AttributeFamily["MASTER"] = "MASTER";
    AttributeFamily["PLANNING"] = "PLANNING";
    AttributeFamily["EDITORIAL"] = "EDITORIAL";
    AttributeFamily["ASSOCIATION"] = "ASSOCIATION";
    AttributeFamily["MONITORING"] = "MONITORING";
    AttributeFamily["OTHERS"] = "OTHERS";
    AttributeFamily["DOCUMENTS"] = "DOCUMENTS";
    AttributeFamily["GLOBAL"] = "GLOBAL";
    AttributeFamily["METADATA"] = "METADATA";
})(AttributeFamily || (AttributeFamily = {}));
export var AttrFamily;
(function (AttrFamily) {
    AttrFamily["CATEGORIES"] = "CATEGORIES";
    AttrFamily["MASTER"] = "MASTER";
    AttrFamily["PLANNING"] = "PLANNING";
    AttrFamily["EDITORIAL"] = "EDITORIAL";
    AttrFamily["ASSOCIATION"] = "ASSOCIATION";
    AttrFamily["MONITORING"] = "MONITORING";
})(AttrFamily || (AttrFamily = {}));
export var SheetLayout;
(function (SheetLayout) {
    SheetLayout["HIDE"] = "hide";
    SheetLayout["OPEN"] = "open";
    SheetLayout["MEDIA_ZOOM"] = "media-zoom";
    SheetLayout["MEDIA_ATTR"] = "media-attr";
})(SheetLayout || (SheetLayout = {}));
export var SheetStatus;
(function (SheetStatus) {
    SheetStatus["PHOLDER"] = "pholder";
    SheetStatus["LOADING"] = "loading";
    SheetStatus["VISIBLE"] = "visible";
})(SheetStatus || (SheetStatus = {}));
export var AreaType;
(function (AreaType) {
    AreaType["AREA"] = "AREA";
    AreaType["CONF"] = "CONF";
})(AreaType || (AreaType = {}));
export var PermissionType;
(function (PermissionType) {
    PermissionType["M2M"] = "M2M_PERMISSION";
    PermissionType["USER"] = "USER_PERMISSION";
    PermissionType["PORTAL"] = "PORTAL_USER_PERMISSION";
})(PermissionType || (PermissionType = {}));
export var GridMedia;
(function (GridMedia) {
    GridMedia["IN_READY"] = "IN_READY";
    GridMedia["LAST_UPLOAD"] = "LAST_UPLOAD";
    GridMedia["UPLOAD_AFTER_READY_SH"] = "UPLOAD_AFTER_READY_SH";
    GridMedia["UPLOAD_AFTER_READY_PP"] = "UPLOAD_AFTER_READY_PP";
})(GridMedia || (GridMedia = {}));
export var PropagationStatus;
(function (PropagationStatus) {
    PropagationStatus["NONE"] = "NONE";
    PropagationStatus["FETCHING"] = "FETCHING";
    PropagationStatus["COMPLETE"] = "COMPLETE";
})(PropagationStatus || (PropagationStatus = {}));
export var ChartType;
(function (ChartType) {
    ChartType["BAR"] = "BAR";
    ChartType["PIE"] = "PIE";
})(ChartType || (ChartType = {}));
export var ChartGroup;
(function (ChartGroup) {
    ChartGroup["READY"] = "READY";
    ChartGroup["COMPL"] = "COMPL";
})(ChartGroup || (ChartGroup = {}));
export var RENDITION;
(function (RENDITION) {
    RENDITION["original"] = "original";
    RENDITION["image_identify"] = "identify";
    RENDITION["image_xs"] = "xs";
    RENDITION["image_s"] = "s";
    RENDITION["image_l"] = "l";
    RENDITION["image_xxl"] = "xxl";
    RENDITION["video_LQ"] = "LQ";
    RENDITION["video_HQ"] = "HQ";
})(RENDITION || (RENDITION = {}));
export var FileAccess;
(function (FileAccess) {
    FileAccess["OWNER"] = "O";
    FileAccess["ADMIN"] = "A";
    FileAccess["WRITE"] = "W";
    FileAccess["READ"] = "R";
    FileAccess["VARIES"] = "VARIES";
})(FileAccess || (FileAccess = {}));
export var SearchMode;
(function (SearchMode) {
    SearchMode["DEFAULT"] = "DEFAULT";
    SearchMode["HIERARCHY"] = "HIERARCHY";
})(SearchMode || (SearchMode = {}));
export var MultiReadyAction;
(function (MultiReadyAction) {
    MultiReadyAction["SET"] = "SET";
    MultiReadyAction["UNSET"] = "UNSET";
})(MultiReadyAction || (MultiReadyAction = {}));
export var MultiReadyTarget;
(function (MultiReadyTarget) {
    MultiReadyTarget["MEDIA"] = "media";
    MultiReadyTarget["DOCUMENT"] = "document";
    MultiReadyTarget["ATTRIBUTE"] = "attribute";
})(MultiReadyTarget || (MultiReadyTarget = {}));
export var INDEX_NAME;
(function (INDEX_NAME) {
    INDEX_NAME["TABULAR"] = "tabular";
    INDEX_NAME["TABULARROOT"] = "tabularroot";
    INDEX_NAME["CONFIG"] = "config";
    INDEX_NAME["DICTIONARIES"] = "dictionaries";
    INDEX_NAME["CATALOGS"] = "catalogs";
    INDEX_NAME["CATEGORIES"] = "categories";
    INDEX_NAME["MEDIA"] = "media";
    INDEX_NAME["PUBLC"] = "publications";
    INDEX_NAME["READY"] = "ready";
    INDEX_NAME["FILE_STORAGE"] = "file-storage";
    INDEX_NAME["CDN_USAGE"] = "cdn-usage";
    INDEX_NAME["MEDIALIBRARY"] = "medialibrary";
})(INDEX_NAME || (INDEX_NAME = {}));
export var PortalSignupStatus;
(function (PortalSignupStatus) {
    PortalSignupStatus["PENDING"] = "PENDING";
    PortalSignupStatus["ACCEPTED"] = "ACCEPTED";
    PortalSignupStatus["REJECTED"] = "REJECTED";
})(PortalSignupStatus || (PortalSignupStatus = {}));
export var OType;
(function (OType) {
    OType["PUBLISHED"] = "PUBLISHED";
    OType["TO_CREATE"] = "TO_CREATE";
    OType["CREATE"] = "CREATED";
    OType["UPDATE"] = "UPDATED";
    OType["DELETE"] = "DELETED";
})(OType || (OType = {}));
