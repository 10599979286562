import * as React from "react";
import PanelFiltersList from "./PanelFiltersList";
import { reducer, initState, ACT_PANEL } from "./reducer";
import { updateFilters, getSlcSaveValues, } from "./utils";
import PanelFiltersTitle from "./PanelFiltersTitle";
import Drawer from "../Drawer";
import { CtxDispatchFilters } from "./contexts";
import { PREF_KEY } from "../../constants/userpref";
import { CtxUserPref } from "../contexts";
const PanelFilters = ({ refState, children, searchEs, columns = [], catalogs = [], filters = [], overrideMapId, overrideMapType, mngSets = undefined, // se undefined allora il pannello NON mostra i sets
mngSetsContext = "", mngSetsOnError, mngSetsOnChange, mngLevels = [], onFiltersChange, onSlcCondChange, slcCondVisible, slcCond, slcSaveVisible = true, enableShared, enableLevels, enableSearch, }) => {
    const userPref = React.useContext(CtxUserPref);
    const [statePanel, dispatchPanel] = React.useReducer(reducer, initState);
    const { mngSetsOpen, appliedOpen, searchInput } = statePanel;
    const slcCtlg = !!userPref ? userPref[PREF_KEY.filterCtlg] : undefined;
    const slcLang = !!userPref ? userPref[PREF_KEY.filterLang] : undefined;
    const slcSave = getSlcSaveValues();
    const onSearchInput = React.useCallback((value) => {
        dispatchPanel({ type: ACT_PANEL.SEARCH_INPUT, value });
    }, []);
    const onFilterCount = React.useCallback(() => {
        dispatchPanel({ type: ACT_PANEL.OPEN_APPLIED, open: true });
    }, []);
    const onMenuAppliedOpen = React.useCallback((open) => {
        dispatchPanel({ type: ACT_PANEL.OPEN_APPLIED, open });
    }, []);
    const onFilterValue = React.useCallback((fNew) => {
        onFiltersChange(updateFilters(fNew, filters));
    }, [filters, onFiltersChange]);
    React.useEffect(() => {
        if (!!refState) {
            dispatchPanel({ type: ACT_PANEL.RESET });
        }
    }, [refState]);
    return (React.createElement(CtxDispatchFilters.Provider, { value: dispatchPanel },
        React.createElement(Drawer, { open: true, border: "right", borderShadow: true },
            children,
            React.createElement(PanelFiltersTitle, { filters: filters, onFiltersChange: onFiltersChange, menuValuedOpen: appliedOpen, onMenuAppliedOpen: onMenuAppliedOpen, mngSets: mngSets, mngSetsOpen: mngSetsOpen, mngSetsContext: mngSetsContext, mngSetsOnError: mngSetsOnError, mngSetsOnChange: mngSetsOnChange, mngLevels: mngLevels, slcCond: slcCond, slcCondVisible: slcCondVisible, onSlcCondChange: onSlcCondChange, slcSave: slcSave, slcSaveVisible: slcSaveVisible, enableShared: enableShared, enableLevels: enableLevels, enableSearch: enableSearch, onSearchInput: onSearchInput, searchInput: searchInput }),
            React.createElement(PanelFiltersList, { columns: columns, filters: filters, overrideMapId: overrideMapId, overrideMapType: overrideMapType, mngSets: mngSets, mngSetsContext: mngSetsContext, mngSetsOnChange: mngSetsOnChange, onFilterValue: onFilterValue, onFilterCount: onFilterCount, searchEs: searchEs, catalogs: catalogs, slcCtlg: slcCtlg, slcLang: slcLang, searchInput: searchInput }))));
};
export default PanelFilters;
