import * as React from "react";
import isEmpty from "lodash-es/isEmpty";
import CardDraggable from "@warda/library-ui/core/CardDraggable";
import { v4 as uuidv4 } from "uuid";
import emptyFn from "@warda/library-ui/utils/emptyFn";
import { createUseStyles } from "react-jss";
import classnames from "classnames";
import { Collapse } from "@warda/library-ui/core/Transitions";
import Divider from "@warda/library-ui/core/Divider";
import { FixedSizeList } from "react-window";
import getMimeType from "@warda/library-ui/utils/getMimeType";
import MsgStopUpload from "./parts/MsgStopUpload";
import Toolbar from "./parts/Toolbar";
import isFileProcessingFinished from "../utils/isFileProcessingFinished";
import UploadListItem from "./parts/UploadListItem";
import { ERROR_GENERIC } from "../../../constants/keys";
import UploadItemStatus from "../uploadItemStatus/UploadItemStatus";
import reducer, { ACTION, reducerInitState } from "./reducer";
import S3Upload from "../S3Uploader/S3Uploader";
const getErrorText = async (err) => {
    try {
        const errJson = await err.response.json();
        return errJson.error || errJson.message;
    }
    catch {
        return ERROR_GENERIC;
    }
};
const dragCls = `multiupload_drag_${uuidv4()}`;
const useStyles = createUseStyles({
    draggableUploads: {
        width: 410,
    },
});
const i18n = {
    filesUploaded: "Files uploaded",
    filesProcessed: "Files processed",
    msgStopUploadsBtnInterrupt: "Interrupt",
    msgStopUploadsBtnContinue: "Continue",
    msgStopUploadsTitle: "Stop uploads?",
    msgStopUploadsContent: "There are some uploads in progress, do you want to interrupt your current uploads?",
};
const getUploadItemId = (item) => `${item.sessionUploadId}_${item.id}`;
const renderRow = (props) => {
    const { index, style } = props;
    const { items, uploadProgressMap } = props.data;
    const item = items[index];
    const id = getUploadItemId(item);
    return (React.createElement(UploadListItem, { id: id, file: item.file, fileStatus: item.status, tooltip: item.tooltip, uploadProgress: uploadProgressMap.get(id), style: style }));
};
const ModalUploads = ({ items = [], className, style, onCloseToolbar, msgStopUpload, onContinue, onFinish = emptyFn, onStopUploads, expanded = false, onExpand, uploadRequestHeaders = { "x-amz-acl": "public-read" }, getSignedUrl, signingUrlMethod = "GET", onPutS3File, onUpdateFileStatus, }) => {
    const [state, dispatch] = React.useReducer(reducer, reducerInitState);
    const S3UploadRef = React.useRef(new Map());
    const { uploadProgressMap, fileIdMap } = state;
    const onUpdate = React.useCallback((item) => {
        onUpdateFileStatus(item);
        const id = getUploadItemId(item);
        S3UploadRef.current.delete(id);
    }, [onUpdateFileStatus]);
    const getSignedUrlCb = React.useCallback((item) => async (file, callback) => {
        try {
            const res = await getSignedUrl(item);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (!res.fileId)
                throw res;
            callback(res);
        }
        catch (err) {
            console.log(`error in getSignedUrl ${JSON.stringify(item)}: ${JSON.stringify(err)}`);
            const tooltip = await getErrorText(err);
            const itemToUpdate = {
                ...item,
                tooltip,
                status: UploadItemStatus.Error,
            };
            onUpdate(itemToUpdate);
        }
    }, [getSignedUrl, onUpdate]);
    const onUploadFinishS3Put = React.useCallback((item) => async (fileId) => {
        const afterPutS3Action = async (uploadItem, fileId) => {
            try {
                await onPutS3File(uploadItem, fileId);
                const itemToUpdate = {
                    ...uploadItem,
                    status: UploadItemStatus.Completed,
                    tooltip: "",
                };
                onUpdate(itemToUpdate);
                // console.log(`Confirmed ${JSON.stringify(uploadItem)} with ${fileId}}`);
            }
            catch (err) {
                console.log(`error in confirm ${JSON.stringify(uploadItem)}: ${JSON.stringify(err)}`);
                const itemToUpdate = {
                    ...uploadItem,
                    status: UploadItemStatus.Error,
                    tooltip: "Unable to upload this file, please check it and try again.",
                };
                onUpdate(itemToUpdate);
            }
        };
        // console.log(`Finish uploading to S3 ${JSON.stringify(item)} with ${fileId}`);
        if (onPutS3File) {
            const id = getUploadItemId(item);
            dispatch({ type: ACTION.PUTTING_S3, id, fileId });
            // console.log(`Start to comfirm  ${JSON.stringify(item)} with ${fileId}`);
            afterPutS3Action(item, fileId);
        }
        else {
            const itemToUpdate = {
                ...item,
                tooltip: "",
                status: onPutS3File
                    ? UploadItemStatus.AwaitingConfirmation
                    : UploadItemStatus.Completed,
            };
            onUpdate(itemToUpdate);
        }
    }, [onPutS3File, onUpdate]);
    const onUploadError = React.useCallback((item) => (error) => {
        console.log(`onUploadError   ${JSON.stringify({ ifile: item, error })}`);
        const itemToUpdate = {
            ...item,
            status: UploadItemStatus.Error,
            tooltip: "Unable upload this file, please check it and try again.",
        };
        onUpdate(itemToUpdate);
    }, [onUpdate]);
    const onUploadAbort = React.useCallback((item) => () => {
        console.log(`onUploadAbort ${JSON.stringify(item)}`);
        const itemToUpdate = {
            ...item,
            status: UploadItemStatus.Abort,
            tooltip: "Upload canceled",
        };
        onUpdate(itemToUpdate);
    }, [onUpdate]);
    const onUploadProgress = React.useCallback((item) => (uploadProgress) => {
        const id = getUploadItemId(item);
        dispatch({ type: ACTION.UPLOAD_PROGRESS, id, uploadProgress });
    }, []);
    React.useEffect(() => {
        if (!(S3UploadRef.current instanceof Map)) {
            S3UploadRef.current = new Map();
        }
        items
            .filter(({ status }) => status === UploadItemStatus.ReadyToUpload)
            .forEach((uploadItem) => {
            const id = getUploadItemId(uploadItem);
            if (!S3UploadRef.current.has(id)) {
                const { type, name } = uploadItem.file;
                const mimeType = getMimeType(name, type);
                const s3Upload = new S3Upload({
                    getSignedUrl: getSignedUrlCb(uploadItem),
                    onFinishS3Put: onUploadFinishS3Put(uploadItem),
                    onProgress: onUploadProgress(uploadItem),
                    onError: onUploadError(uploadItem),
                    onAbort: onUploadAbort(uploadItem),
                    signingUrlMethod: signingUrlMethod,
                    uploadRequestHeaders: uploadRequestHeaders,
                    contentDisposition: "auto",
                });
                S3UploadRef.current.set(id, s3Upload);
                // console.log(`Start uploading ${id}`);
                s3Upload.handleFileSelect(uploadItem.file, mimeType);
                const itemToUpdate = {
                    ...uploadItem,
                    status: UploadItemStatus.S3Uploading,
                };
                onUpdateFileStatus(itemToUpdate);
            }
        });
    }, [
        items,
        getSignedUrlCb,
        onUploadFinishS3Put,
        onUpdateFileStatus,
        onUploadProgress,
        onUploadError,
        onUploadAbort,
        signingUrlMethod,
        uploadRequestHeaders,
    ]);
    React.useEffect(() => {
        items
            .filter(({ status }) => status === UploadItemStatus.AwaitingConfirmation)
            .forEach((uploadItem) => {
            const afterPutS3Action = async (uploadItem, fileId) => {
                try {
                    await onPutS3File(uploadItem, fileId);
                    const itemToUpdate = {
                        ...uploadItem,
                        status: UploadItemStatus.Completed,
                        tooltip: "",
                    };
                    onUpdate(itemToUpdate);
                    // console.log(`Confirmed ${JSON.stringify(uploadItem)} with ${fileId}}`);
                }
                catch (err) {
                    console.log(`error in confirm ${JSON.stringify(uploadItem)}: ${JSON.stringify(err)}`);
                    const itemToUpdate = {
                        ...uploadItem,
                        status: UploadItemStatus.Error,
                        tooltip: "Unable to upload this file, please check it and try again.",
                    };
                    onUpdate(itemToUpdate);
                }
            };
            const id = getUploadItemId(uploadItem);
            if (fileIdMap.has(id)) {
                const fileId = fileIdMap.get(id);
                // console.log(`Start to comfirm  ${JSON.stringify(uploadItem)} with ${fileId}`);
                afterPutS3Action(uploadItem, fileId);
            }
            else {
                console.log(`Missing fileId for ${JSON.stringify(uploadItem)}`);
            }
        });
    }, [items, fileIdMap, onPutS3File, onUpdate]);
    React.useEffect(() => {
        if (!!onFinish && isFileProcessingFinished(items) && !isEmpty(items)) {
            onFinish(items);
        }
    }, [items, onFinish]);
    const classes = useStyles({});
    return (React.createElement(React.Fragment, null,
        React.createElement(CardDraggable, { dragCls: dragCls, position: {
                bottom: 25,
                right: 25,
            }, cardProps: {
                style,
                className: classnames({
                    [classes.draggableUploads]: true,
                    [className]: !!className,
                }),
            } },
            React.createElement(Toolbar, { i18n: i18n, expanded: expanded, files: items, onExpand: onExpand, onClose: onCloseToolbar, className: dragCls }),
            React.createElement(Collapse, { open: expanded },
                React.createElement(Divider, null),
                React.createElement(FixedSizeList, { itemSize: 40, height: 140, itemCount: items.length, itemData: { items, uploadProgressMap }, width: "100%", children: renderRow }))),
        React.createElement(MsgStopUpload, { i18n: i18n, open: msgStopUpload, onContinue: onContinue, onStop: onStopUploads })));
};
export default ModalUploads;
