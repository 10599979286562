import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import Icon from "@warda/library-ui/core/Icon";
import Text from "@warda/library-ui/core/Text";
import BtnBase from "@warda/library-ui/core/BtnBase";
import { createUseStyles } from "react-jss";
import hexToRgbA from "@warda/library-ui/utils/hexToRgbA";
import BorderShadow from "@warda/library-ui/core/BorderShadow";
import WindowTenants from "./WindowTenants";
import { CtxUserData } from "../contexts";
import { DRAWER_WIDTH } from "../../constants/keys";
const WIDTH = DRAWER_WIDTH + 1;
const useStyles = createUseStyles({
    panel: {
        height: "-webkit-fill-available",
        width: WIDTH,
        minWidth: WIDTH,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        position: "relative",
        backgroundColor: getTheme().colors.grayDrawer,
    },
    btnTenants: {
        margin: "0 25px",
        alignItems: "center",
        minHeight: 30,
        width: 260,
        display: "flex",
        borderRadius: getTheme().borderRadius,
        transition: "all 350ms",
        color: getTheme().colors.theme1,
        backgroundColor: ({ menuOpen }) => !menuOpen ? "transparent" : hexToRgbA(getTheme().colors.theme1, 0.15),
        "&:hover": {
            backgroundColor: ({ disabled }) => disabled ? "transparent" : hexToRgbA(getTheme().colors.theme1, 0.15),
        },
    },
    icon: {
        minWidth: 18,
        margin: "0px 5px",
        display: ({ disabled }) => (disabled ? "none" : "block"),
    },
});
const BtnWindowTenants = () => {
    const { tenants, tenantId } = React.useContext(CtxUserData);
    const tenantCur = tenants.find((t) => t.tenantId === tenantId).label;
    const menuRef = React.useRef(null);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const onMenuOpen = React.useCallback(() => {
        setMenuOpen(true);
    }, []);
    const onMenuClose = React.useCallback(() => {
        setMenuOpen(false);
    }, []);
    const disabled = tenants.length < 2;
    const classes = useStyles({ disabled, menuOpen });
    return (React.createElement("div", { className: classes.panel },
        React.createElement(BtnBase, { ref: menuRef, className: classes.btnTenants, onClick: tenants.length === 1 ? undefined : onMenuOpen },
            React.createElement(Icon, { className: classes.icon, children: "" }),
            React.createElement("div", { style: { flex: 1 } }),
            React.createElement(Text, { size: 2, weight: "bolder", ellipsis: true, children: tenantCur }),
            React.createElement("div", { style: { flex: 1 } }),
            React.createElement(Icon, { className: classes.icon, children: "keyboard_arrow_down" })),
        React.createElement(WindowTenants, { anchorEl: menuRef.current, open: menuOpen, onClose: onMenuClose }),
        React.createElement(BorderShadow, { position: "right" })));
};
export default BtnWindowTenants;
