import { Condition } from "@warda/library-ui/interfaces";
const hasPerms = (keys, permissions, condition = Condition.AND) => {
    const permsId = new Set(permissions.map((p) => p.id));
    if (condition === Condition.AND) {
        return !keys.some((key) => !permsId.has(key));
    }
    else {
        return keys.some((key) => permsId.has(key));
    }
};
export default hasPerms;
