import * as moment from "moment";
import { DATE_FORMAT, MISSING_KEY } from "@warda/library-ui/constants";
import { FilterTypeCategory, FilterTypeDictionary, FilterTypeUser, FilterTypeSingleSelection, FilterTypeMultiSelection, FilterTypeText, FilterTypeDate, FilterTypeBoolean, FilterTypePercentage, FilterTypeSimpleSelection, } from "../utils";
const d = (date) => moment(date).format(DATE_FORMAT);
const getChips = (o) => {
    const { value, type } = o;
    try {
        if (value === undefined ||
            value === null ||
            JSON.stringify(value) === JSON.stringify([]))
            throw "NO_VALUE";
        switch (type) {
            case FilterTypeCategory:
            case FilterTypeDictionary:
            case FilterTypeUser:
            case FilterTypeSimpleSelection:
            case FilterTypeSingleSelection:
            case FilterTypeMultiSelection: {
                const f = { ...o };
                const selectValue = f.value;
                return selectValue;
            }
            case FilterTypeBoolean: {
                const f = { ...o };
                return [
                    {
                        id: "bool",
                        label: f.value ? "Yes" : "No",
                    },
                ];
            }
            case FilterTypeDate: {
                const f = { ...o };
                const { startDate, endDate } = f.value;
                const oneDay = endDate - startDate <= 24 * 60 * 60 * 1000;
                return [
                    {
                        id: "date",
                        label: oneDay ? d(startDate) : `${d(startDate)} - ${d(endDate)}`,
                    },
                ];
            }
            case FilterTypeText: {
                const f = { ...o };
                if (f.switchNoValue) {
                    return [{ id: MISSING_KEY, label: "No value" }];
                }
                return typeof f.value === "string"
                    ? [{ id: f.value, label: f.value }]
                    : f.value.filter((l) => !!l).map((l) => ({ id: l, label: l }));
            }
            case FilterTypePercentage: {
                const f = { ...o };
                const [v1, v2] = f.value || [0, 100];
                return [
                    {
                        id: "perc",
                        label: v1 === v2 ? `${v1}%` : `${v1}% - ${v2}%`,
                    },
                ];
            }
            default:
                throw "NO_TYPE";
        }
    }
    catch (err) {
        return [];
    }
};
export default getChips;
