export const getEnv = () => {
    switch (process.env.BASE_URL_GATEWAY) {
        case "https://api.wardacloud.com":
            return "PROD";
        case "https://api-test.wardacloud.com":
            return "TEST";
        default:
            return "DEV";
    }
};
export const PRODUCT_ID = "seecommerce";
export const BASE_URL = `${process.env.BASE_URL_GATEWAY || ""}`;
export const WEBSOCKET_URL = `${process.env.WEBSOCKET_URL || ""}`;
export const PANEL_DETAIL_WIDTH_CLOSE = 22;
export const PANEL_DETAIL_WIDTH = 420;
export const FIELD_WIDTH = 360;
export const DRAWER_WIDTH = 280;
export const AREA_PRODUCTS = "products"; // key used by user's JsonStore file
export const AREA_PRODUCTS_CONFIG = "products_config";
export const AREA_MEDIA_LIBRARY = "media_library";
export const AREA_MEDIA_METADATA = "media_metedata";
export const AREA_CATALOGS = "catalogs";
export const AREA_CATEGORIES = "categories";
export const AREA_USERMNG_SC = "usermanagement_sc";
export const AREA_USERMNG_PORTAL = "usermanagement_portal";
export const AREA_USERMNG_M2M = "usermanagement_m2m";
export const AREA_COMPLETENESS = "completeness";
export const AREA_USAGESTATS = "usage_stats";
export const AREA_SHAREPAGE = "sharepage";
export const LAYOUT_GRID = "grid"; // used in jsonStore id - not change
export const ID_COLUMNS = "columns"; // used in jsonStore id - not change
export const ID_FILTERS = "filters"; // used in jsonStore id - not change
export const ID_ATTRIBS = "fields_panel_details"; // used in jsonStore id - not change
export const CATEGORY_DEFAULT = "DEFAULT";
export const FOLDER_ROOT_ID = "root";
export const FOLDER_ROOT_LABEL = "Root";
export const DEFAULT_WIDTH_COLUMN = 120;
export const MAX_COLUMN_SORT = 5;
export const MAX_PRODUCTS_SELECTABLE = 10000; // for ElasticSearch and Export XLSX
export const MAX_PRODUCTS_MASSIVE_ACTIONS = 500;
export const MAX_PAGINATION = 500;
export const MAX_UPLOAD = 3;
export const MAX_CATEGORIES = 5000;
export const ERROR_INTERNALSERVER = "Internal server error";
export const ERROR_TOO_MANY_ITEMS = "Please try select a smaller number of items";
export const ERROR_GENERIC = "Something went wrong, please retry";
export const ERROR_NOT_FOUND = "Unable get file data, please refresh and retry";
export const ERROR_SAVE_SETS = "Unable to save your Sets, please refresh and retry";
export const ERROR_SAVE_PREF = "Unable to save your preferences, please refresh and retry";
export const ERROR_WRONG_TYPE = "The files you’re trying to upload are not compliant with the choosen type";
