import { NotificationType, } from "@warda/library-ui/contexts/AppWebSocketContext";
import { ArchiverStatus } from "../../interfaces";
import { AREA_SHAREPAGE } from "../../constants/keys";
import { ACT_VPORT } from "./reducer";
const downloadSet = new Set([
    NotificationType.EXPORT,
    NotificationType.ARCHIVING,
]);
const wsCallbackDownloads = (itemsWs, dispatch) => {
    itemsWs
        .filter((c) => downloadSet.has(c.notificationType))
        .map(({ isError, payload }) => {
        if (isError || !!payload.error) {
            dispatch({ type: ACT_VPORT.DOWNLOADS_ERROR, payload });
            return null;
        }
        if (payload.status === ArchiverStatus.STARTED) {
            dispatch({ type: ACT_VPORT.DOWNLOADS_UPDATE, payload });
            return null;
        }
        if (payload.status === ArchiverStatus.COMPLETED) {
            dispatch({ type: ACT_VPORT.DOWNLOADS_FINISH, payload });
            return null;
        }
    });
};
const wsCallbacks = [
    {
        id: `${AREA_SHAREPAGE}_archiver`,
        callback: wsCallbackDownloads,
    },
];
export default wsCallbacks;
