import * as React from "react";
import * as moment from "moment";
import { createUseStyles } from "react-jss";
import { FieldText } from "@warda/library-ui/core/Field";
import Preview from "@warda/library-ui/core/Preview";
import Placeholder from "@warda/library-ui/core/Placeholder";
import { getTheme } from "@warda/library-ui/theme";
import { DATE_FORMAT } from "@warda/library-ui/constants";
import bytesFormat from "@warda/library-ui/utils/bytesFormat";
import { isMobile } from "@warda/library-ui/utils/deviceUtils";
import Toolbar from "@warda/library-ui/core/Toolbar";
import Btn from "@warda/library-ui/core/Btn";
import { useAppMediaTypeCtx } from "@warda/library-ui/contexts/AppMediaTypeContext";
import getFileIcon from "@warda/library-ui/utils/getFileIcon";
import { FileType } from "@warda/library-ui/interfaces";
import { CtxDispatchViewport } from "../contexts";
import apiUrls from "../../../api/apiUrlsAnonymous";
import getThumbRendition from "../../../utils/getThumbRendition";
import { DRAWER_WIDTH } from "../constants";
import getFileExtension from "../../../utils/getFileExtension";
import { ACT_VPORT } from "../reducer";
import Dialog from "../Dialog";
const useStyles = createUseStyles({
    preview: {
        margin: "0 auto",
        border: `1px solid ${getTheme().colors.grayBorder}`,
        backgroundColor: getTheme().colors.background,
    },
    boxFields: {
        padding: "10px 0 40px",
    },
    fieldDesktopMini: {
        backgroundColor: getTheme().colors.background,
        margin: "15px 5px",
        width: DRAWER_WIDTH / 2 - 10,
    },
    fieldDesktopFull: {
        backgroundColor: getTheme().colors.background,
        margin: "15px 5px",
        width: DRAWER_WIDTH - 10,
    },
    fieldMobile: {
        backgroundColor: getTheme().colors.background,
        margin: "15px 0px",
        width: window.innerWidth - 20,
    },
});
const DialogDetail = ({ open, file }) => {
    const appMediaTypeCtx = useAppMediaTypeCtx();
    const classes = useStyles({});
    const [mousehover, setMouseover] = React.useState(false);
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const id = (file === null || file === void 0 ? void 0 : file.id) || "";
    const type = (file === null || file === void 0 ? void 0 : file.type) || FileType.FOLDER;
    const data = (file === null || file === void 0 ? void 0 : file.data) || {};
    const { fileId, name, mimeType, created, dimensions, sizeInBytes, } = data;
    const cbOnClose = React.useCallback(() => {
        dispatchViewport({ type: ACT_VPORT.DIALOG_DETAIL });
    }, [dispatchViewport]);
    const cbOnDownload = React.useCallback(() => {
        dispatchViewport({ type: ACT_VPORT.DIALOG_DOWNLOAD, fileId: id });
    }, [dispatchViewport, id]);
    const cbOnViewer = React.useCallback(() => {
        dispatchViewport({ type: ACT_VPORT.DIALOG_VIEWER });
    }, [dispatchViewport]);
    const cbOnPreviewMousehoverEnter = React.useCallback(() => {
        setMouseover(true);
    }, []);
    const cbOnPreviewMousehoverLeave = React.useCallback(() => {
        setMouseover(false);
    }, []);
    if (!file) {
        return (React.createElement(Dialog, { open: open, fullscreen: true, title: "Detail", onClose: cbOnClose },
            React.createElement(Placeholder, { icon: "chrome_reader_mode", label: "Select an item to see details" })));
    }
    return (React.createElement(Dialog, { open: open, fullscreen: true, title: isMobile() ? name : "Detail", onClose: cbOnClose, buttons: [
            {
                icon: "fullscreen",
                tooltip: "Zoom",
                onClick: cbOnViewer,
            },
            {
                icon: "download",
                tooltip: "Download",
                onClick: cbOnDownload,
            },
        ] },
        React.createElement("div", { onMouseEnter: cbOnPreviewMousehoverEnter, onMouseLeave: cbOnPreviewMousehoverLeave },
            React.createElement(Preview, { previewWidth: isMobile() ? window.innerWidth - 20 : DRAWER_WIDTH, previewHeight: isMobile() ? 200 : 260, srcUrl: apiUrls.mediaDownload.url(fileId, getThumbRendition(2, mimeType, appMediaTypeCtx.mediaKind)), mimeType: mimeType, placeholderIcon: getFileIcon(mimeType, appMediaTypeCtx.mediaKind, type), className: classes.preview, onClick: cbOnViewer, mousehover: mousehover })),
        React.createElement(Toolbar, { style: { padding: "0 5px" } },
            React.createElement(Btn, { label: "General Info" })),
        React.createElement("div", { className: classes.boxFields },
            React.createElement(FieldText, { readOnly: true, className: isMobile() ? classes.fieldMobile : classes.fieldDesktopFull, label: "Name", value: name }),
            type === FileType.MEDIA ? (React.createElement(FieldText, { readOnly: true, className: isMobile() ? classes.fieldMobile : classes.fieldDesktopMini, label: "Type", value: getFileExtension(mimeType), adornmentIcon: getFileIcon(mimeType, appMediaTypeCtx.mediaKind), adornmentIconTooltip: mimeType })) : (React.createElement(FieldText, { readOnly: true, className: isMobile() ? classes.fieldMobile : classes.fieldDesktopMini, label: "Type", value: type === FileType.FOLDER ? "Folder" : "Touchpoint", adornmentIcon: type === FileType.FOLDER ? "folder" : "cast_connected" })),
            React.createElement(FieldText, { readOnly: true, className: isMobile() ? classes.fieldMobile : classes.fieldDesktopMini, label: "Creation date", value: moment(created).format(`${DATE_FORMAT} HH:mm`) }),
            type === FileType.FOLDER ? null : (React.createElement(React.Fragment, null,
                React.createElement(FieldText, { readOnly: true, className: isMobile() ? classes.fieldMobile : classes.fieldDesktopMini, label: "Dimensions", value: dimensions || "" }),
                React.createElement(FieldText, { readOnly: true, className: isMobile() ? classes.fieldMobile : classes.fieldDesktopMini, label: "Size", value: bytesFormat(sizeInBytes || 0) }))))));
};
export default DialogDetail;
