import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { createUseStyles } from "react-jss";
import Btn from "@warda/library-ui/core/Btn";
import { Zoom } from "@warda/library-ui/core/Transitions";
import Text from "@warda/library-ui/core/Text";
import Icon from "@warda/library-ui/core/Icon";
import InputFile from "@warda/library-ui/core/InputFile";
import hexToRgbA from "@warda/library-ui/utils/hexToRgbA";
import Avatar from "@warda/library-ui/core/Avatar";
import { getUser } from "@warda/library-ui/utils/getUser";
import { CtxUserData, CtxTenantM2ms, CtxTenantUsers } from "../contexts";
const SIZE = 100;
const ZINDEX = {
    avatarPreview: 1,
    btnUpload: 2,
    btnDelete: 4,
    uploading: 5,
};
const useStyles = createUseStyles({
    profileAvatar: {
        position: "relative",
        margin: "auto",
    },
    avatarPreview: {
        zIndex: ZINDEX.avatarPreview,
        "& *": {
            fontSize: 30,
        },
    },
    avatarButtons: {
        width: SIZE,
        height: SIZE,
        borderRadius: SIZE,
        backgroundColor: hexToRgbA(getTheme().colors.theme1, 0.75),
        position: "absolute",
        zIndex: ZINDEX.btnUpload,
        top: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& *": {
            color: "#fff !important",
        },
    },
    btnDelete: {
        position: "absolute",
        bottom: 5,
        left: 5,
        margin: 0,
        zIndex: ZINDEX.btnDelete,
    },
    btnUpload: {
        width: SIZE,
        height: SIZE,
        borderRadius: SIZE,
        position: "absolute",
        top: 0,
        left: 0,
        overflow: "hidden",
    },
    uploading: {
        width: SIZE,
        height: SIZE,
        borderRadius: SIZE,
        backgroundColor: hexToRgbA("#000", 0.75),
        position: "absolute",
        zIndex: ZINDEX.uploading,
        top: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        "& *": {
            color: "#fff !important",
        },
    },
});
const ProfileAvatar = ({ srcUrl, progress, onReplacePhoto, onRemovePhoto, }) => {
    const classes = useStyles({});
    const users = React.useContext(CtxTenantUsers);
    const m2ms = React.useContext(CtxTenantM2ms);
    const currUser = React.useContext(CtxUserData);
    const userMock = getUser(currUser.userId, users, m2ms);
    const [mousehover, setMousehover] = React.useState(false);
    const onMouseEnter = React.useCallback(() => {
        setMousehover(true);
    }, []);
    const onMouseLeave = React.useCallback(() => {
        setMousehover(false);
    }, []);
    const onRefresh = React.useCallback(() => {
        if (progress === 100)
            location.reload();
    }, [progress]);
    return (React.createElement("div", { role: "presentation", className: classes.profileAvatar, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave },
        React.createElement(Avatar, { className: classes.avatarPreview, src: srcUrl, size: SIZE, text: userMock.avatarText, icon: userMock.avatarIcon }),
        React.createElement(Zoom, { open: mousehover && !progress, className: classes.avatarButtons, unmountOnExit: false },
            React.createElement(Icon, { children: "upload" }),
            React.createElement("div", { className: classes.btnUpload },
                React.createElement(InputFile, { onChangeInput: onReplacePhoto })),
            !srcUrl ? null : (React.createElement(Btn, { color: getTheme().colors.msgFail, icon: "delete", variant: "bold", onClick: onRemovePhoto, small: true, className: classes.btnDelete }))),
        progress === 0 ? null : (React.createElement("div", { role: "presentation", className: classes.uploading, onClick: onRefresh }, progress === 100 ? (React.createElement(Icon, { children: "refresh" })) : (React.createElement(Text, { weight: "bolder", size: 4, children: String(progress) + "%" }))))));
};
export default ProfileAvatar;
