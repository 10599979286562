import { FileType } from "@warda/library-ui/interfaces";
import { FileAccess, } from "../../../../interfaces";
import { AREA } from "../../constants";
export const mergeAccess = (arrayOfAccess) => {
    const mergedMap = new Map();
    const mergeEntries = (entries) => {
        entries.forEach(({ userId, level }) => {
            const existingLevel = mergedMap.get(userId);
            // Mantieni il livello più alto (ADMIN > WRITE > READ)
            if (!existingLevel || compareLevels(level, existingLevel) > 0) {
                mergedMap.set(userId, level);
            }
        });
    };
    // Mergiamo gli array
    arrayOfAccess.forEach((access) => mergeEntries(access));
    // Convertiamo la mappa in un array
    const merged = Array.from(mergedMap, ([userId, level]) => ({
        userId,
        level,
    }));
    return merged;
};
export const compareLevels = (level1, level2) => {
    const priority = {
        [FileAccess.ADMIN]: 3,
        [FileAccess.WRITE]: 2,
        [FileAccess.READ]: 1,
    };
    return priority[level1] - priority[level2];
};
const getFileAccess = ({ area, userId, owner, creator, access = [], accessInherited = [], }) => {
    if (area === undefined)
        return FileAccess.ADMIN; // caso in cui si è fuori dalla ML (es. sezione Products)
    if (area === AREA.PORTAL)
        return FileAccess.ADMIN; // if temporaneo fintanto che il BE non passa gli accessi del portal al FE
    if (area === AREA.PORTALS_LIST)
        return FileAccess.ADMIN;
    if (area === AREA.CORPORATE)
        return FileAccess.ADMIN;
    if (creator === userId)
        return FileAccess.ADMIN;
    if (owner === userId)
        return FileAccess.ADMIN;
    const mergedAccess = mergeAccess([access, accessInherited]);
    const accesses = mergedAccess
        .filter((a) => a.userId == userId)
        .map((a) => a.level);
    if (accesses.length > 1)
        return FileAccess.VARIES;
    if (accesses.length === 1)
        return accesses[0];
    return FileAccess.READ;
};
export const getFilesSlcAccess = ({ area, userId, files, filesSlcId, accessInherited, }) => {
    const setIds = new Set(filesSlcId);
    const filesSlc = files.filter((f) => setIds.has(f.id));
    const allPortals = !filesSlc.some((f) => f.type !== FileType.PORTAL);
    if (allPortals) {
        return FileAccess.ADMIN;
    }
    else {
        const filesSlcAccess = filesSlc.map((f) => getFileAccess({
            area,
            userId,
            owner: f.data.owner,
            creator: f.data.creator,
            access: f.data.access,
            accessInherited,
        }));
        const setAccess = new Set(filesSlcAccess);
        if (setAccess.size > 1)
            return FileAccess.VARIES;
        return filesSlcAccess[0];
    }
};
export default getFileAccess;
