import getMimeType from "@warda/library-ui/utils/getMimeType";
import getErrorMsg from "@warda/library-ui/utils/getErrorMsg";
import { Service, } from "@warda/library-ui/contexts/AppWebSocketContext";
import { ContentType, DictionaryType, IAssignmentType, IAssignmentStatus, MediaType, XlsType, RENDITION, ViewType, MultiReadyAction, INDEX_NAME, } from "../interfaces";
import { MAX_CATEGORIES, MAX_PAGINATION, BASE_URL } from "../constants/keys";
import escapeKeyEs from "../utils/escapeKeyEs";
import apiUrls from "./apiUrlsProducts";
import { normalizeElasticSrc } from "../utils/elasticsearch";
import sortCategories from "../utils/sortCategories";
import { fetchCookieJwtWithRefreshToken } from "./fetchCookieJwt";
import normalizeElasticCatalog from "../utils/normalizeElasticCatalog";
const mapMediaType = {
    [MediaType.VIDEO]: "video",
    [MediaType.IMAGE_PP]: "post",
    [MediaType.IMAGE_SH]: "shooting",
    [MediaType.DOCUMENT]: "document",
    [XlsType.UPLOAD_XLS_EDITOR]: "xlsx",
    [XlsType.UPLOAD_XLS_PRO]: "xlsxpro",
};
const defaultSearchRes = {
    items: [],
    itemsTotal: 0,
    aggregations: [],
};
export const search = async ({ searchEs, getUrl, }) => {
    const { aggs, from, query, _source } = searchEs;
    const { url, method } = apiUrls.search;
    const res = await fetchCookieJwtWithRefreshToken({
        url: getUrl(url),
        method,
        jsonBody: {
            track_total_hits: true,
            aggs,
            from: from || 0,
            size: getSearchSize(searchEs),
            sort: getSearchSort(searchEs),
            query,
            _source,
        },
    });
    if (res.error === "Index not found for tenant")
        return defaultSearchRes;
    if (res.error === "Index not found")
        return defaultSearchRes;
    return normalizeElasticSrc(res);
};
const getSearchSize = ({ size, aggs }) => {
    if (!!aggs)
        return 0;
    if (size !== undefined)
        return size; // can be 0
    return MAX_PAGINATION;
};
const getSearchSort = ({ sort }) => {
    return (sort || []).map((s) => {
        const obj = {};
        Object.keys(s).forEach((sKey) => {
            obj[sKey] = {
                ...s[sKey],
                unmapped_type: "keyword",
            };
        });
        return obj;
    });
};
export const editAttributeBulk = async (jsonBody) => {
    const { url, method } = apiUrls.editMultiProduct;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody,
    });
    return res;
};
export const editAttributeSingle = async (jsonBody) => {
    const { url, method } = apiUrls.editProduct;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody,
    });
    return res;
};
export const setReady = async ({ idType = ContentType.MEDIA, entityId, id, catalogs, version, }) => {
    const mapIdTypeType = {
        [ContentType.MEDIA]: "media",
        [ContentType.ATTRIBUTE]: "attribute",
    };
    const { url, method } = apiUrls.postReady;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(mapIdTypeType[idType]),
        method,
        jsonBody: {
            idType,
            entityId,
            id,
            catalogs,
            version,
        },
    });
    return res;
};
export const deleteReady = async ({ idType = ContentType.MEDIA, entityId, id, catalogs, version, }) => {
    const mapIdTypeType = {
        [ContentType.MEDIA]: "media",
        [ContentType.ATTRIBUTE]: "attribute",
    };
    const { url, method } = apiUrls.deleteReady;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(mapIdTypeType[idType]),
        method,
        jsonBody: {
            idType,
            entityId,
            id,
            catalogs,
            version,
        },
    });
    return res;
};
export const setViews = async ({ entityId, view, status, version, }) => {
    const { url, method } = apiUrls.postViews;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: {
            entityId,
            view,
            status,
            version,
        },
    });
    return res;
};
export const setViewCheck = async ({ entityId, viewName, check, version, }) => {
    const { url, method } = apiUrls.postViewCheck;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: {
            entityId,
            viewName,
            check,
            version,
        },
    });
    return res;
};
export const searchExport = async ({ index, size, _source, query, }) => {
    const { url, method } = apiUrls.search;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(index, Service.SC),
        method,
        jsonBody: {
            size,
            _source,
            query,
        },
    });
    return res;
};
export const exportData = async ({ headers, rows, fileName }) => {
    const { url, method } = apiUrls.postExportData;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: {
            headers,
            rows,
            fileName,
        },
    });
    return res;
};
export const downloadMassiveSC = async ({ correlationId, connectionId, groups, }) => {
    if (!connectionId) {
        return { success: false, message: "Missing webSocket ID" };
    }
    if (!correlationId) {
        return { success: false, message: "CorrelationId is empty" };
    }
    try {
        const { url, method } = apiUrls.downloadMassiveSC;
        const message = await fetchCookieJwtWithRefreshToken({
            url: url(),
            method,
            jsonBody: {
                correlationId,
                connectionUUID: connectionId,
                renditionGroups: groups,
            },
        });
        return { success: true, message };
    }
    catch (err) {
        const message = await getErrorMsg(err);
        return { success: false, message };
    }
};
export const getDictionariesBody = ({ dictionaryId, dictionaryType, value, }) => {
    const valueConditions = Object.keys(value).map((catalogId) => {
        const conditions = [];
        if (!!catalogId && dictionaryType !== DictionaryType.A) {
            conditions.push({ term: { catalog: catalogId } });
        }
        conditions.push({
            bool: {
                should: value[catalogId].map((code) => ({
                    wildcard: {
                        code: {
                            value: code,
                            case_insensitive: true,
                        },
                    },
                })),
            },
        });
        return { bool: { must: conditions } };
    });
    return {
        from: 0,
        size: 1000,
        sort: [],
        query: {
            bool: {
                must: [
                    { term: { dictionaryId } },
                    { term: { dictionaryType } },
                    { bool: { should: valueConditions } },
                ],
            },
        },
    };
};
export const searchDictionaries = async ({ dictionaryId, dictionaryType, }) => {
    const { url, method } = apiUrls.search;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(INDEX_NAME.DICTIONARIES, Service.SC),
        method,
        jsonBody: {
            from: 0,
            size: 1000,
            sort: [],
            query: {
                bool: {
                    must: [{ term: { dictionaryId } }, { term: { dictionaryType } }],
                },
            },
        },
    });
    const { items } = normalizeElasticSrc(res);
    return items.map(({ id, data }) => ({ id, ...data }));
};
export const searchCatalogs = async () => {
    const { url, method } = apiUrls.search;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(INDEX_NAME.CATALOGS, Service.SC),
        method,
        jsonBody: {
            from: 0,
            size: 1000,
        },
    });
    return normalizeElasticCatalog(res);
};
export const searchCategories = async ({ root, catalog, searchableValue, }) => {
    try {
        const queryConditions = [];
        if (!!searchableValue) {
            queryConditions.push({
                wildcard: {
                    searchableValue: {
                        value: `*${escapeKeyEs(searchableValue)}*`,
                        case_insensitive: true,
                    },
                },
            });
        }
        if (!!catalog) {
            queryConditions.push({
                term: { catalog },
            });
        }
        if (!!root) {
            queryConditions.push({
                term: { root },
            });
        }
        const { url, method } = apiUrls.search;
        const res = await fetchCookieJwtWithRefreshToken({
            url: url(INDEX_NAME.CATEGORIES, Service.SC),
            method,
            jsonBody: {
                from: 0,
                size: MAX_CATEGORIES,
                sort: [],
                query: {
                    bool: {
                        must: queryConditions,
                    },
                },
            },
        });
        const { items, itemsTotal } = normalizeElasticSrc(res);
        return {
            items: sortCategories(items),
            itemsTotal,
        };
    }
    catch (err) {
        console.warn("searchCategories", err);
        return {
            items: [],
            itemsTotal: 0,
        };
    }
};
export const multiReady = async (payload, multiReadyTarget, multiReadyAction) => {
    let method;
    let key;
    switch (multiReadyAction) {
        case MultiReadyAction.SET:
            method = "POST";
            key = "readySet";
            break;
        case MultiReadyAction.UNSET:
            method = "DELETE";
            key = "removeSet";
            break;
        default:
            throw `multi ready op ${multiReadyAction} unmanaged!`;
    }
    const res = await fetchCookieJwtWithRefreshToken({
        url: apiUrls.postMultiReady.url(multiReadyTarget),
        method,
        jsonBody: {
            [key]: payload,
        },
    });
    return res;
};
export const deleteMedia = async ({ id, mediatype, viewType, }) => {
    const isDocument = viewType === ViewType.DOCUMENT;
    const { url, method } = apiUrls.deleteSeecommerceMedia;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(id, isDocument ? mapMediaType[MediaType.DOCUMENT] : mapMediaType[mediatype]),
        method,
    });
    return res;
};
export const deleteMediaMassive = async (medias) => {
    const { url, method } = apiUrls.deleteSeecommerceMedia;
    const res = await Promise.all(medias.map(({ id, mediatype }) => fetchCookieJwtWithRefreshToken({
        url: url(id, mapMediaType[mediatype]),
        method,
    })));
    return res;
};
const checkAssignmentsDefault = (assignments) => {
    return assignments.map((a) => ({
        ...a,
        catalogs: [],
        assignmentType: IAssignmentType.DEFAULT,
        status: IAssignmentStatus.DEFAULT,
    }));
};
export const assignEntity = async ({ entityId, assignmentsToAdd = [], assignmentsToRemove = [], version, }) => {
    const { url, method } = apiUrls.assignment;
    return await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: {
            entityId,
            add: checkAssignmentsDefault(assignmentsToAdd),
            remove: checkAssignmentsDefault(assignmentsToRemove),
            version,
        },
    });
};
export const multiassignEntity = async (modifiedAssignments) => {
    const { url, method } = apiUrls.multiAssignment;
    await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: {
            modifiedAssignments: modifiedAssignments.map((m) => ({
                entityId: m.entityId,
                add: checkAssignmentsDefault(m.assignmentsToAdd),
                remove: checkAssignmentsDefault(m.assignmentsToRemove),
                version: m.version,
            })),
        },
    });
};
export const setDistribution = async ({ fileId, viewName, entityId, version, disable, }) => {
    const { url, method } = apiUrls.distributions;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: {
            fileId,
            viewName,
            entityId,
            version,
            disable,
        },
    });
    return res;
};
export const updateViews = async (modifyViews, type) => {
    let url = "";
    let method = "";
    if (type === ViewType.MEDIA) {
        const api = apiUrls.multiViewDataMedia;
        url = api.url();
        method = api.method;
    }
    if (type === ViewType.DOCUMENT) {
        const api = apiUrls.multiViewDataDocuments;
        url = api.url();
        method = api.method;
    }
    const res = await fetchCookieJwtWithRefreshToken({
        url,
        method,
        jsonBody: {
            modifyViews,
        },
    });
    return res;
};
export const resetViews = async ({ resetViews, viewType }) => {
    const { url, method } = apiUrls.multiResetViews;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: {
            resetViews,
            mediaViews: viewType === ViewType.MEDIA,
            documentViews: viewType === ViewType.DOCUMENT,
        },
    });
    return res;
};
export const resetAttribute = async ({ entityId, attributeName, version, ctlgId, langId, }) => {
    const { url, method } = apiUrls.resetAttribute;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: {
            entityId,
            attributeName,
            version,
            catalog: ctlgId || undefined,
            language: langId || undefined,
        },
    });
    return res;
};
export const canPublicFile = async ({ service, fileId, }) => {
    const { url, method } = apiUrls.canPublicFile;
    const bool = await fetchCookieJwtWithRefreshToken({
        url: url(service, fileId),
        method,
    });
    return bool;
};
export const postProduction = async ({ productId, productVersion, mediaId, mediaPostProduce, }) => {
    const { url, method } = apiUrls.postProduction;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: {
            entityId: productId,
            fileId: mediaId,
            postProduce: mediaPostProduce,
            version: productVersion,
        },
    });
    return res;
};
export const createNotification = async ({ toUsers, type, payload, }) => {
    const { url, method } = apiUrls.createNotification;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: {
            toUsers,
            notificationType: type,
            payload,
        },
    });
    return res;
};
export const exportProductEvents = async ({ correlationId, connectionId, aggregateId, }) => {
    const { url, method } = apiUrls.exportProductEvents;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: {
            correlationId,
            connectionUUID: connectionId,
            aggregateId,
        },
    });
    return res;
};
export const exportXlsx = async ({ columns, views, entities, correlationId, connectionId, propagateValues, sorted, }) => {
    const { url, method } = apiUrls.exportXlsx;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: {
            entities,
            columns,
            views,
            correlationId,
            connectionUUID: connectionId,
            propagateValues,
            sorted,
        },
    });
    return res;
};
export const postEasyCatalog = async ({ catalog, entities, correlationId, connectionId, }) => {
    const { url, method } = apiUrls.postEasyCatalog;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: {
            catalog,
            entities,
            correlationId,
            connectionUUID: connectionId,
        },
    });
    return res;
};
const buildConfirmUrl = (url, fileId, service, mediaType, importXls) => {
    if (mediaType) {
        return url(fileId, service, mapMediaType[mediaType]);
    }
    if (importXls) {
        return url(fileId, service, mapMediaType[importXls]) + "?renditions=false";
    }
    return url(fileId, service);
};
export const mcrConfirm = async (fileId, service, item) => {
    var _a, _b;
    const { url, method } = apiUrls.newUploadConfirm;
    const mediaType = (_a = item === null || item === void 0 ? void 0 : item.metadata) === null || _a === void 0 ? void 0 : _a.mediaType;
    const importXls = (_b = item === null || item === void 0 ? void 0 : item.metadata) === null || _b === void 0 ? void 0 : _b.importXls;
    await fetchCookieJwtWithRefreshToken({
        url: buildConfirmUrl(url, fileId, service, mediaType, importXls),
        method,
    });
};
export const getSignedUrl = (urlMethod) => async (item) => {
    const { file: { type, name }, metadata = {}, deferTenantSpecificRenditions = true, } = item;
    const { url, method } = urlMethod;
    const mediaType = metadata.mediaType;
    const metadataFix = { ...metadata };
    if (mediaType === MediaType.DOCUMENT) {
        delete metadataFix.mediaType;
    }
    return await signUrl({
        url: url(mapMediaType[mediaType]),
        method,
        filename: name,
        mimeType: getMimeType(name, type),
        metadata: metadataFix,
        targets: ["PRODUCT"],
        deferTenantSpecificRenditions,
    });
};
export const signUrl = async ({ url, method, filename, mimeType, metadata = {}, targets = [], deferTenantSpecificRenditions, }) => {
    try {
        const res = await fetchCookieJwtWithRefreshToken({
            url,
            method,
            jsonBody: {
                mimeType,
                filename,
                metadata,
                targets,
                deferTenantSpecificRenditions,
            },
        });
        return {
            ...res,
            signedUrl: res.signedURL,
        };
    }
    catch (err) {
        console.log("[signUrl] ", err);
        return err;
    }
};
export const getAttributeDicts = async (jsonBody) => {
    const { url, method } = apiUrls.getAttributeDicts;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody,
    });
    return res;
};
export const getBoxDocumentData = async (fileId) => {
    const { docId } = await fetchCookieJwtWithRefreshToken({
        url: apiUrls.getMcrDocumentId.url(fileId),
        method: apiUrls.getMcrDocumentId.method,
    });
    const token = await fetchCookieJwtWithRefreshToken({
        url: apiUrls.getMcrDocumentToken.url(docId),
        method: apiUrls.getMcrDocumentToken.method,
    });
    return {
        docId,
        token,
    };
};
export const getCompletenessCount = async (chart, catalog) => {
    const { url, method } = apiUrls.completenessCount;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: {
            attributeId: chart.id,
            multiCtlg: chart.multiCtlg,
            multiLang: chart.multiLang,
            catalog,
        },
    });
    return res;
};
export const getEntityBussinessIds = async (entityId) => {
    const { url, method } = apiUrls.getEntityBussinessIds;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(entityId),
        method,
    });
    return res;
};
const getMediaInfoIdentify = async (fileId) => {
    try {
        const { url, method } = apiUrls.downloadMediaSC;
        const firmedUrl = await fetchCookieJwtWithRefreshToken({
            url: url(fileId, {
                redirect: false,
                rendition: RENDITION.image_identify,
            }),
            method,
        });
        const res = await fetch(firmedUrl, { method });
        const resJson = await res.json();
        let resImage = null;
        if (Array.isArray(resJson)) {
            resImage = resJson[0].image;
        }
        else {
            resImage = resJson.image;
        }
        if (!resImage)
            throw "err";
        return {
            sizeInBytes: resImage.filesize,
            dimensions: `${resImage.geometry.width} x ${resImage.geometry.height} px`,
        };
    }
    catch {
        return null;
    }
};
export const getMediaDataSC = async (fileId) => {
    try {
        const { items } = await search({
            getUrl: () => `${BASE_URL}/${Service.SC}/products-medias`,
            searchEs: {
                from: 0,
                size: 1,
                query: { bool: { must: { terms: { _id: [fileId] } } } },
            },
        });
        const data = items[0].data;
        if (!data.dimensions || !data.sizeInBytes) {
            // lato BE non c'è stata la migrazione di size & dimensions dei media legacy
            // quindi questo taccone serve per andarle a leggere dalla rendition identify
            try {
                const { sizeInBytes, dimensions } = await getMediaInfoIdentify(fileId);
                data.sizeInBytes = sizeInBytes;
                data.dimensions = dimensions;
            }
            catch {
                console.warn("no rendition identify");
            }
        }
        return data;
    }
    catch {
        return null;
    }
};
export const getMediaRenditionsCount = async (files) => {
    try {
        const keyId = "renditions";
        const { aggregations } = await search({
            getUrl: () => `${BASE_URL}/${Service.SC}/products-medias`,
            searchEs: {
                size: 0,
                query: {
                    bool: { must: { terms: { _id: files.map((f) => f.fileId) } } },
                },
                aggs: {
                    [keyId]: {
                        nested: { path: keyId },
                        aggs: {
                            [keyId]: {
                                terms: { field: "renditions.code", size: 1000 },
                            },
                        },
                    },
                },
            },
        });
        const mapRenditionCount = aggregations[0].items.reduce((a, c) => {
            a[c.key] = c.doc_count;
            return a;
        }, {});
        return mapRenditionCount;
    }
    catch {
        return {};
    }
};
export const getSrcFirmed = async (sourceUrl) => {
    const sourceUrlFirmed = await fetchCookieJwtWithRefreshToken({
        url: sourceUrl,
        method: "GET",
    });
    return sourceUrlFirmed;
};
export const deleteEntities = async (ids) => {
    const { url, method } = apiUrls.deleteEntities;
    await fetchCookieJwtWithRefreshToken({
        url: url(ids),
        method,
        jsonBody: {
            requests: ids.map((entityId) => ({
                entityId,
                version: -1,
            })),
        },
    });
};
