import * as React from "react";
import { Initialize } from "@warda/library-ui/interfaces";
import Text from "@warda/library-ui/core/Text";
import { getTheme } from "@warda/library-ui/theme";
import { FilterWrapper, FilterSelect, } from "@warda/library-ui/core/Filter";
import { MISSING_KEY } from "@warda/library-ui/constants";
import reducer, { reducerInitState, ACTION } from "./reducer";
import { searchDictionaries } from "../../../api/fetchesApiProducts";
import { ATT_NO_LANG_ID } from "../../AreaProducts/PanelDetail/constants";
import { FilterTypeSingleSelection } from "../utils";
const getLabelLang = (dictionary, langId) => dictionary.value[langId] || dictionary.value[ATT_NO_LANG_ID];
const FilterSearchDictionaries = (p) => {
    const { id, label, labelSub, type, value = [], onChange, onClickCount, className, collapsed, collapsedHelp, multiCatalog, multiLanguage, column, slcCtlg, slcLang, mandatory, } = p;
    const [state, dispatch] = React.useReducer(reducer, reducerInitState);
    const { initialize, error, optionsDict } = state;
    const loadingSearch = initialize === Initialize.START || initialize === Initialize.WAIT;
    const { editField } = column;
    const options = optionsDict
        .filter(({ id, dictionary }) => {
        if (id === MISSING_KEY)
            return true;
        if (!multiCatalog && !multiLanguage)
            return true;
        return dictionary.catalog === slcCtlg;
    })
        .map(({ id, label, dictionary }) => {
        if (id === MISSING_KEY)
            return { id, label };
        return {
            id: dictionary.code,
            label: getLabelLang(dictionary, slcLang),
            labelSub: dictionary.code,
        };
    })
        .sort((a, b) => {
        if (a.label > b.label)
            return 1;
        if (a.label < b.label)
            return -1;
        return 0;
    });
    React.useEffect(() => {
        (async () => {
            if (initialize === Initialize.START) {
                dispatch({ type: ACTION.INIT_LOADING });
                try {
                    if (!editField)
                        throw `Filter config without "editField"`;
                    const { dictionaryId, dictionaryType } = editField;
                    const items = await searchDictionaries({
                        dictionaryId,
                        dictionaryType,
                    });
                    const options = items.map((dictionary) => ({
                        dictionary,
                        id: dictionary.id,
                        label: "xxx",
                    }));
                    options.splice(0, 0, {
                        dictionary: undefined,
                        id: MISSING_KEY,
                        label: "No value",
                    });
                    dispatch({ type: ACTION.INIT_SUCC, options });
                }
                catch (err) {
                    dispatch({ type: ACTION.INIT_FAIL, error: err });
                }
            }
        })();
    }, [editField, initialize]);
    if (error) {
        return (React.createElement(FilterWrapper, { key: id, label: label, color: getTheme().colors.theme1 },
            React.createElement(Text, { style: {
                    marginLeft: 15,
                    color: getTheme().colors.disable,
                }, children: error })));
    }
    return (React.createElement(FilterSelect, { id: id, type: type, label: label, labelSub: labelSub, onChange: onChange, onClickCount: onClickCount, options: options, value: value.map((v) => v.id), maxItems: type === FilterTypeSingleSelection ? 1 : undefined, className: className, collapsed: collapsed, collapsedHelp: collapsedHelp, mandatory: mandatory, searchable: true, loadingSearch: loadingSearch }));
};
export default FilterSearchDictionaries;
