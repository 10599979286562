import * as React from "react";
import { createUseStyles } from "react-jss";
import Btn from "@warda/library-ui/core/Btn";
import Text from "@warda/library-ui/core/Text";
import Zoom from "@warda/library-ui/core/Transitions/Zoom";
import hexToRgbA from "@warda/library-ui/utils/hexToRgbA";
import { getTheme } from "@warda/library-ui/theme";
import { isMobile } from "@warda/library-ui/utils/deviceUtils";
import { CtxDispatchViewport } from "./contexts";
import { ACT_VPORT } from "./reducer";
const useStyles = createUseStyles({
    zoom: {
        flex: isMobile() ? 1 : undefined,
    },
    group: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: 3,
        backgroundColor: ({ color }) => hexToRgbA(color, 0.1),
        borderRadius: isMobile() ? 5 : 100,
        flex: isMobile() ? 1 : undefined,
    },
    groupText: {
        margin: "0 20px",
        flex: isMobile() ? 1 : undefined,
    },
    groupBtn: {
        borderRadius: isMobile() ? undefined : 100,
    },
});
const SelectActions = ({ filesSlcId }) => {
    const color = getTheme().colors.theme2;
    const classes = useStyles({ color });
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const cbOnDeselect = React.useCallback(() => {
        dispatchViewport({ type: ACT_VPORT.DESELECT_FILES });
    }, [dispatchViewport]);
    const cbOnDownload = React.useCallback(() => {
        dispatchViewport({ type: ACT_VPORT.DIALOG_DOWNLOAD });
    }, [dispatchViewport]);
    return (React.createElement(Zoom, { open: !!filesSlcId.length, className: classes.zoom },
        React.createElement("div", { className: classes.group },
            React.createElement(Btn, { variant: "bold", color: color, className: classes.groupBtn, tooltip: "Deselect all", icon: "close", onClick: cbOnDeselect }),
            React.createElement(Text, { className: classes.groupText, style: { color }, weight: "bolder", children: `${filesSlcId.length} item${filesSlcId.length > 0 ? "s" : ""} selected` }),
            React.createElement(Btn, { className: classes.groupBtn, color: color, tooltip: "Download", icon: "file_download", iconStyle: { color }, onClick: cbOnDownload }))));
};
export default SelectActions;
