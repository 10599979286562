import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { createUseStyles } from "react-jss";
import IconDrag from "@warda/library-ui/core/IconDrag";
import classnames from "classnames";
import mixColors from "@warda/library-ui/utils/mixColors";
import hexToRgbA from "@warda/library-ui/utils/hexToRgbA";
import PanelFiltersListitemRender from "./PanelFiltersListitemRender";
const hoverColor = mixColors(0.08, getTheme().colors.grayDrawer, getTheme().colors.typography);
const dragColor = getTheme().colors.theme2;
const useStyles = createUseStyles({
    item: {
        position: "relative",
        padding: "0 22px 5px 22px",
        border: `1px solid transparent`,
    },
    itemHover: {
        backgroundColor: hoverColor,
    },
    itemDragging: {
        borderColor: dragColor,
        borderRadius: getTheme().borderRadius,
        backgroundColor: getTheme().colors.background,
    },
    icon: {
        backgroundColor: hoverColor,
        top: 0,
        left: 0,
        height: "100%",
        alignItems: "normal",
        lineHeight: "40px",
        opacity: 0,
    },
    iconOpen: {
        opacity: 1,
    },
    iconDragging: {
        borderRadius: 0,
        backgroundColor: hexToRgbA(dragColor, 0.1),
        color: dragColor,
    },
});
const PanelFiltersListitem = ({ catalogs = [], columns = [], onFilterValue, onFilterCount, searchEs, overrideMapId = {}, overrideMapType = {}, slcCtlg, slcLang, filter, dragProvided, dragSnapshot, isDraggingOver, isDragDisabled, }) => {
    const classes = useStyles({});
    const { isDragging } = dragSnapshot;
    const [areaHover, setAreaHover] = React.useState(false);
    const onAreaMouseEnter = React.useCallback(() => {
        if (isDragDisabled)
            return;
        setAreaHover(true);
    }, [isDragDisabled]);
    const onAreaMouseLeave = React.useCallback(() => {
        if (isDragDisabled)
            return;
        setAreaHover(false);
    }, [isDragDisabled]);
    const [dragHover, setDragHover] = React.useState(false);
    const onDragMouseEnter = React.useCallback(() => {
        if (isDragDisabled)
            return;
        setDragHover(true);
    }, [isDragDisabled]);
    const onDragMouseLeave = React.useCallback(() => {
        if (isDragDisabled)
            return;
        setDragHover(false);
    }, [isDragDisabled]);
    return (React.createElement("div", { className: classnames({
            [classes.item]: true,
            [classes.itemHover]: dragHover,
            [classes.itemDragging]: isDragging,
        }), ref: dragProvided.innerRef, onMouseEnter: onAreaMouseEnter, onMouseMove: onAreaMouseEnter, onMouseLeave: onAreaMouseLeave, ...dragProvided.draggableProps },
        React.createElement(IconDrag, { open // the handler is needed alwayas to Draggable component
            : true, className: classnames({
                [classes.icon]: true,
                [classes.iconOpen]: isDragging || (areaHover && !isDraggingOver),
                [classes.iconDragging]: isDragging,
            }), onMouseEnter: onDragMouseEnter, onMouseMove: onDragMouseEnter, onMouseLeave: onDragMouseLeave, ...dragProvided.dragHandleProps }),
        React.createElement(PanelFiltersListitemRender, { catalogs: catalogs, columns: columns, onFilterValue: onFilterValue, onFilterCount: onFilterCount, searchEs: searchEs, overrideMapId: overrideMapId, overrideMapType: overrideMapType, slcCtlg: slcCtlg, slcLang: slcLang, filter: filter })));
};
export default PanelFiltersListitem;
