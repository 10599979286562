import { multiselectCtrl, multiselectMeta, } from "@warda/library-ui/utils/multiselect";
import last from "@warda/library-ui/utils/last";
import { itemsTypeSort } from "../View/utils";
const sortFiles = (array) => {
    return array.sort((a, b) => {
        const indexA = itemsTypeSort.indexOf(a.type);
        const indexB = itemsTypeSort.indexOf(b.type);
        if (indexA === -1 && indexB === -1)
            return 0;
        if (indexA === -1)
            return 1;
        if (indexB === -1)
            return -1;
        return indexA - indexB;
    });
};
const updateFilesSlcId = ({ files, filesSlcId, id, keyDownCtrl, keyDownMeta, }) => {
    if (keyDownMeta) {
        // files può essere composto da folder ed media
        // in modo disordinato quindi prima di conoscere l'index
        // dell'elemento cliccato bisogna ridividerli per types
        const filesSorted = sortFiles(files);
        return multiselectMeta(filesSlcId, filesSorted.map((i) => i.id), filesSorted.findIndex((i) => i.id === last(filesSlcId)), filesSorted.findIndex((i) => i.id === id));
    }
    if (keyDownCtrl) {
        return multiselectCtrl(filesSlcId, id);
    }
    return [id];
};
export default updateFilesSlcId;
