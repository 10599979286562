import { KEY_ATTRIBUTE_SETS, KEY_BUSINESS_IDS, KEY_MEDIA, KEY_PUBLC_ATTRS, KEY_PUBLC_MEDIA, } from "../constants";
import { decorateMediaAnnotations, decorateAttributesCategory, decorateAttributesDictionary, } from "./";
export const mergeProductDatas = ({ tabularroot, tabular, ready, media, publications, dictionaries, annotations, attributeSets, categories, columns, businessIds, }) => ({
    id: tabular.entityId,
    ...tabularroot,
    ...tabular,
    ...ready,
    [KEY_PUBLC_ATTRS]: publications[KEY_PUBLC_ATTRS] || [],
    [KEY_PUBLC_MEDIA]: publications[KEY_PUBLC_MEDIA] || [],
    ...decorateAttributesCategory(tabular, columns, categories),
    ...decorateAttributesDictionary(tabular, columns, dictionaries),
    [KEY_ATTRIBUTE_SETS]: attributeSets,
    [KEY_BUSINESS_IDS]: businessIds || [],
    [KEY_MEDIA]: decorateMediaAnnotations((media === null || media === void 0 ? void 0 : media.media) || [], annotations),
});
