import getErrorMsg from "@warda/library-ui/utils/getErrorMsg";
import { Service } from "@warda/library-ui/contexts/AppWebSocketContext";
import { getPathCurrentUrl } from "@warda/library-ui/layout/Login";
import { SearchMode, } from "../interfaces";
import { BASE_URL, PRODUCT_ID } from "../constants/keys";
import apiUrls from "./apiUrlsAnonymous";
import { SEARCH_FILES_RESULT_ERROR, composeFolderPath, normalizeMediaLibrary, } from "./fetchesApiMediaLibrary";
import { getAccessCode } from "../components/AreaSharepage/constants";
import { normalizeElasticSrc } from "../utils/elasticsearch";
const fetchAnonymous = async ({ url, method = "GET", jsonBody = {}, headers: headersAdd = {}, }) => {
    try {
        if (!!!!Object.keys(headersAdd).length) {
            console.log("NOTE: portals the headers must be passed by the BE for cookie management", headersAdd);
        }
        const headers = { "Content-Type": "application/json", ...headersAdd };
        const options = method !== "GET" && url !== "HEAD"
            ? {
                credentials: "include",
                method,
                headers,
                body: JSON.stringify(jsonBody),
            }
            : { credentials: "include", method, headers };
        const response = await fetch(url, options);
        const { status } = response;
        if (status >= 400)
            throw { status, response };
        return await response.json();
    }
    catch (err) {
        throw err;
    }
};
export const getPasswordPolicy = async (groupId) => {
    const res = await fetchAnonymous({
        url: `${BASE_URL}/${Service.USERS}/passwordPolicy/${groupId}`,
        method: "GET",
    });
    return res;
};
export const onChoosePassword = async ({ authTmpCode, authGroupId, username, password, }) => {
    let url;
    let jsonBody;
    if (getPathCurrentUrl() === "choosepassword") {
        url = `${BASE_URL}/${Service.USERS}/token/user/activate`;
        jsonBody = {
            email: username,
            temporaryPassword: authTmpCode,
            newPassword: password,
            groupId: authGroupId,
            applicationId: PRODUCT_ID,
        };
    }
    else {
        url = `${BASE_URL}/${Service.USERS}/confirmForgotPassword`;
        jsonBody = {
            email: username,
            confirmationCode: authTmpCode,
            newPassword: password,
            groupId: authGroupId,
            applicationId: PRODUCT_ID,
        };
    }
    await fetchAnonymous({
        url,
        method: "POST",
        jsonBody,
    });
    return { success: true, message: "" };
};
export const onDemandPassword = async ({ tenantId, username, }) => {
    const url = `${BASE_URL}/${Service.USERS}/forgotPassword`;
    const res = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            email: username,
            tenantId,
            applicationId: PRODUCT_ID,
        }),
    });
    const status = res.status;
    const { error, message, code } = await res.json();
    return { success: status < 400, message: error || message || code };
};
export const anonymousSearchFiles = async ({ area, hrch, prnt, searchMode, searchEsFolder, searchEsMedia, }) => {
    try {
        const accessCode = getAccessCode();
        const { url, method } = apiUrls.searchFiles;
        const payload = {
            accessCodeToken: accessCode,
            searchMode,
            folder: searchEsFolder,
            media: searchEsMedia,
            parentFolder: undefined,
            hierarchyId: undefined,
        };
        if (searchMode === SearchMode.DEFAULT &&
            hrch !== undefined &&
            prnt !== undefined) {
            payload.parentFolder = {
                hierarchyId: hrch,
                folderId: prnt,
            };
        }
        if (searchMode === SearchMode.HIERARCHY) {
            payload.hierarchyId = hrch;
        }
        const res = await fetchAnonymous({
            url: url(),
            method,
            jsonBody: payload,
        });
        return normalizeMediaLibrary(area, res);
    }
    catch (err) {
        const errMsg = await getErrorMsg(err);
        return { ...SEARCH_FILES_RESULT_ERROR, error: errMsg };
    }
};
export const anonymousDownloadMassive = async ({ correlationId, connectionId, groups, }) => {
    if (!connectionId) {
        return { success: false, message: "Missing 'websocketId'" };
    }
    if (!correlationId) {
        return { success: false, message: "Missing 'correlationId'" };
    }
    try {
        const { url, method } = apiUrls.downloadMassive;
        const message = await fetchAnonymous({
            url: url(),
            method,
            jsonBody: {
                correlationId,
                connectionUUID: connectionId,
                renditionGroups: groups,
            },
        });
        return { success: true, message };
    }
    catch (err) {
        const message = await getErrorMsg(err);
        return { success: false, message };
    }
};
export const getViewerImgSrcFirmed = async (sourceUrl) => {
    const sourceUrlFirmed = await fetchAnonymous({
        url: sourceUrl,
        method: "GET",
    });
    return sourceUrlFirmed;
};
export const getViewerBoxData = async (fileId) => {
    const apiDocId = apiUrls.boxDocumentId;
    const { docId } = await fetchAnonymous({
        url: apiDocId.url(fileId),
        method: apiDocId.method,
    });
    const apiDocToken = apiUrls.boxDocumentToken;
    const token = await fetchAnonymous({
        url: apiDocToken.url(docId),
        method: apiDocToken.method,
    });
    return {
        docId,
        token,
    };
};
export const getFilePath = async (area, file) => {
    const accessCode = getAccessCode();
    const { url, method } = apiUrls.searchFiles;
    const hrch = file.data.hierarchyId;
    const prnt = file.data.parentFolderId;
    const res = await fetchAnonymous({
        url: url(),
        method,
        jsonBody: {
            accessCodeToken: accessCode,
            searchMode: SearchMode.DEFAULT,
            parentFolder: {
                hierarchyId: hrch,
                folderId: prnt,
            },
            folder: { from: 0, size: 0 },
            media: { from: 0, size: 0 },
        },
    });
    const normLocation = normalizeElasticSrc(res.folderLocation);
    return composeFolderPath(area, normLocation.items);
};
