import * as React from "react";
import ViewBlockAsset from "@warda/library-ui/layout/ViewBlockAsset";
import { CtxDispatchViewport } from "../../contexts";
import { getAssetPropsFolder } from "../utils";
const hTitle = 50;
const hMargin = 10;
export const blockHeightFolder = hTitle + hMargin * 2;
const ViewBlockAssetFolder = ({ id, color, size, data, selected, selectedIds, contextmenuOriginAnchor, contextmenuOriginTransf, contextmenuPosizionZone, onContextMenu, }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const props = getAssetPropsFolder({
        dispatchViewport,
        color,
        id,
        data,
        selected,
        selectedIds,
        onContextMenu,
        contextmenuOriginAnchor,
        contextmenuOriginTransf,
        contextmenuPosizionZone,
    });
    return React.createElement(ViewBlockAsset, { ...props, width: size[0] - hMargin * 2 });
};
export default ViewBlockAssetFolder;
