import * as React from "react";
import { AREA_PRODUCTS, AREA_PRODUCTS_CONFIG, AREA_USERMNG_M2M, AREA_COMPLETENESS, AREA_USAGESTATS, AREA_MEDIA_LIBRARY, AREA_MEDIA_METADATA, AREA_USERMNG_SC, AREA_USERMNG_PORTAL, } from "../constants/keys";
import PlaceholderMain from "./PlaceholderMain";
import PlaceholderViewport from "./PlaceholderViewport";
const AreaProducts = React.lazy(() => import("./AreaProducts"));
const AreaMediaLibrary = React.lazy(() => import("./AreaMediaLibrary"));
const AreaMediaMetadata = React.lazy(() => import("./AreaMediaMetadata"));
const AreaProductsConfig = React.lazy(() => import("./AreaProductsConfig"));
const AreaCompleteness = React.lazy(() => import("./AreaCompleteness"));
const AreaUsageStats = React.lazy(() => import("./AreaUsageStats"));
const AreaUsermngM2m = React.lazy(() => import("./AreaUsermngM2m"));
const AreaUsermngSc = React.lazy(() => import("./AreaUsermngSc"));
const AreaUsermngPortal = React.lazy(() => import("./AreaUsermngPortal"));
const MainSectionContent = ({ viewId }) => {
    switch (viewId) {
        case AREA_PRODUCTS:
            return React.createElement(AreaProducts, null);
        case AREA_MEDIA_LIBRARY:
            return React.createElement(AreaMediaLibrary, null);
        case AREA_PRODUCTS_CONFIG:
            return React.createElement(AreaProductsConfig, null);
        case AREA_COMPLETENESS:
            return React.createElement(AreaCompleteness, null);
        case AREA_USAGESTATS:
            return React.createElement(AreaUsageStats, null);
        case AREA_MEDIA_METADATA:
            return React.createElement(AreaMediaMetadata, null);
        case AREA_USERMNG_SC:
            return React.createElement(AreaUsermngSc, null);
        case AREA_USERMNG_PORTAL:
            return React.createElement(AreaUsermngPortal, null);
        case AREA_USERMNG_M2M:
            return React.createElement(AreaUsermngM2m, null);
        default:
            return React.createElement(PlaceholderMain, null);
    }
};
const MainSection = (a) => (React.createElement(React.Suspense, { fallback: React.createElement(PlaceholderViewport, null) },
    React.createElement(MainSectionContent, { ...a })));
export default MainSection;
