import * as React from "react";
import Btn from "@warda/library-ui/core/Btn";
import { ACT_VPORT } from "../reducer";
import { CtxDispatchViewport } from "../contexts";
import { ViewMode } from "../constants";
const BtnSearch = ({ className, style, viewMode }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const cbOnClick = React.useCallback(() => {
        dispatchViewport({ type: ACT_VPORT.VIEW_MODE_SEARCH });
    }, [dispatchViewport]);
    if (viewMode === ViewMode.SEARCH)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(Btn, { className: className, style: style, icon: "search", onClick: cbOnClick })));
};
export default BtnSearch;
