import * as React from "react";
import Btn from "@warda/library-ui/core/Btn";
import { deleteNotifDrc } from "../../../api/fetchesApiNotifications";
import { ACTION } from "../reducer";
const BtnDelete = ({ classes, dispatch, id }) => {
    const [fetch, setFetch] = React.useState(false);
    const [click, setClick] = React.useState(false);
    const onClick = React.useCallback(() => setClick(true), []);
    React.useEffect(() => {
        if (click && !fetch) {
            (async () => {
                try {
                    setFetch(true);
                    await deleteNotifDrc(id);
                    dispatch({ type: ACTION.DRC_DELETE_ONE, id });
                }
                catch (err) {
                    console.log("[BtnDelete] error", err);
                }
                setClick(false);
                setFetch(false);
            })();
        }
    }, [click, dispatch, fetch, id]);
    return (React.createElement(Btn, { small: true, className: classes.btnDelete, icon: "close", tooltip: "Remove from listing", onClick: onClick }));
};
export default BtnDelete;
