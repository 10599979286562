import PERMS from "../../constants/permissions";
export var AREA;
(function (AREA) {
    AREA["MYFILES"] = "myfiles";
    AREA["PORTALS_LIST"] = "portals-list";
    AREA["PORTAL"] = "portal";
    AREA["SHARED_WITH_ME"] = "shared-with-me";
    AREA["CORPORATE"] = "corporate";
    AREA["STAGING"] = "staging";
    AREA["SHAREPAGE"] = "sharepage";
})(AREA || (AREA = {}));
export const AREA_ICONS = {
    [AREA.MYFILES]: "folder",
    [AREA.STAGING]: "dataset",
    [AREA.PORTALS_LIST]: "cast_connected",
    [AREA.SHARED_WITH_ME]: "share",
    [AREA.CORPORATE]: "business_center",
    [AREA.PORTAL]: "",
    [AREA.SHAREPAGE]: "",
};
export const AREA_TEXTS = {
    [AREA.MYFILES]: "My Files",
    [AREA.STAGING]: "Staging Area",
    [AREA.PORTALS_LIST]: "Brand Portals",
    [AREA.SHARED_WITH_ME]: "Shared with me",
    [AREA.CORPORATE]: "Corporate",
    [AREA.PORTAL]: "",
    [AREA.SHAREPAGE]: "",
};
export const AREA_PERMS = {
    [AREA.MYFILES]: PERMS.access_medialibrary_area,
    [AREA.STAGING]: PERMS.access_staging_area,
    [AREA.PORTALS_LIST]: PERMS.medialibrary_touchpoint_manager,
    [AREA.SHARED_WITH_ME]: PERMS.access_medialibrary_area,
    [AREA.CORPORATE]: PERMS.access_medialibrary_area,
    [AREA.PORTAL]: PERMS.medialibrary_touchpoint_manager,
    [AREA.SHAREPAGE]: PERMS.access_medialibrary_area,
};
