import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { createUseStyles } from "react-jss";
import Toolbar from "@warda/library-ui/core/Toolbar";
import Divider from "@warda/library-ui/core/Divider";
import Drawer from "../Drawer";
import { PANEL_DETAIL_WIDTH_CLOSE } from "../../constants/keys";
import PlaceholderBtn from "./PlaceholderBtn";
import PlaceholderSpinner from "./PlaceholderSpinner";
const useStyles = createUseStyles({
    toolbar: {
        padding: "0 15px",
        alignSelf: "stretch",
    },
    divider: {
        alignSelf: "stretch",
    },
    content: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        backgroundColor: getTheme().colors.background,
    },
    detail: {
        width: PANEL_DETAIL_WIDTH_CLOSE,
        borderLeft: `1px solid ${getTheme().colors.grayBorder}`,
        backgroundColor: getTheme().colors.background,
    },
});
const PlaceholderViewport = () => {
    const classes = useStyles({});
    return (React.createElement(React.Fragment, null,
        React.createElement(Drawer, { open: true, border: "right" },
            React.createElement(Toolbar, { className: classes.toolbar },
                React.createElement("div", { style: { flex: 1 } }),
                React.createElement(PlaceholderBtn, { loading: true }),
                React.createElement("div", { style: { flex: 1 } })),
            React.createElement(Toolbar, { className: classes.toolbar },
                React.createElement("div", { style: { flex: 1 } }),
                React.createElement(PlaceholderBtn, { loading: true }),
                React.createElement("div", { style: { flex: 1 } }))),
        React.createElement("div", { className: classes.content },
            React.createElement(Toolbar, { className: classes.toolbar },
                React.createElement(PlaceholderBtn, { loading: true }),
                React.createElement(PlaceholderBtn, { loading: true }),
                React.createElement(PlaceholderBtn, { loading: true }),
                React.createElement("div", { style: { flex: 1 } }),
                React.createElement(PlaceholderBtn, { loading: true })),
            React.createElement(Divider, { className: classes.divider }),
            React.createElement(PlaceholderSpinner, null)),
        React.createElement("div", { className: classes.detail })));
};
export default PlaceholderViewport;
