import { Service } from "@warda/library-ui/contexts/AppWebSocketContext";
import { fetchCookieJwtWithRefreshToken } from "./fetchCookieJwt";
import { BASE_URL, PRODUCT_ID } from "../constants/keys";
import { getJSPref } from "./fetchesApiJsonStore";
import { JsonstoreContext } from "../constants/userpref";
export const apiUrls = {
    getUsers: {
        url: () => `${BASE_URL}/${Service.USERS}/user?app=${PRODUCT_ID}`,
        method: "GET",
    },
    getUserProfile: {
        url: () => `${BASE_URL}/${Service.USERS}/user/profile?app=${PRODUCT_ID}`,
        method: "GET",
    },
    profilePictureUpload: {
        url: () => `${BASE_URL}/${Service.USERS}/user/profile/picture/upload`,
        method: "POST",
    },
    profilePictureConfirm: {
        url: (fileId) => `${BASE_URL}/${Service.USERS}/user/profile/picture/confirm/${fileId}`,
        method: "PUT",
    },
    putUserProfile: {
        url: () => `${BASE_URL}/${Service.USERS}/user/profile`,
        method: "PUT",
    },
};
export const getUsers = async () => {
    const { url, method } = apiUrls.getUsers;
    const data = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
    });
    return data;
};
export const getCurrentUser = async () => {
    const { url, method } = apiUrls.getUserProfile;
    const u = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
    });
    return u;
};
export const getUserDatas = async () => {
    var _a, _b;
    const prefContext = JsonstoreContext.userPreferences;
    const [all, userProfile, jsMap] = await Promise.all([
        getUsers(),
        getCurrentUser(),
        getJSPref([prefContext]),
    ]);
    return {
        userProfile,
        preferencesJsId: (_a = jsMap[prefContext][0]) === null || _a === void 0 ? void 0 : _a.id,
        preferences: (_b = jsMap[prefContext][0]) === null || _b === void 0 ? void 0 : _b.payload,
        m2ms: all.m2m || [],
        users: all.users || [],
    };
};
