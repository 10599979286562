function isPathMinusOne() {
    // Otteniamo l'URL completo del browser
    const url = window.location.href;
    // Creiamo un oggetto URL dalla stringa dell'URL
    const urlObject = new URL(url);
    // Otteniamo il valore del parametro "path" dall'URL
    const pathValue = urlObject.searchParams.get("path");
    // Verifichiamo se il valore di "path" è uguale a -1
    return pathValue === "-1";
}
export default isPathMinusOne;
