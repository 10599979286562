import apiUrls from "./apiUrlsProducts";
import { fetchCookieJwtWithRefreshToken } from "./fetchCookieJwt";
const mapNotifDrc = (n) => ({
    id: n.uuid,
    creation: n.creation,
    type: n.notificationType,
    from: n.from,
    markedAsRead: !!n.markedAsRead,
    markedAsViewed: !!n.markedAsViewed,
    payload: n.payload,
});
export const getAllNotifDrc = async ({ size = 200, from = 0, filter, }) => {
    const { url, method } = apiUrls.getNotifications;
    try {
        const res = await fetchCookieJwtWithRefreshToken({
            url: url(size, from, filter),
            method,
        });
        return res.map(mapNotifDrc);
    }
    catch (err) {
        console.warn("getNotifications: ", err);
        return [];
    }
};
export const getCountNotifDrc = async (filter = "to_view") => {
    const { url, method } = apiUrls.getNotificationsCount;
    try {
        const res = await fetchCookieJwtWithRefreshToken({
            url: url(filter),
            method,
        });
        return res;
    }
    catch (err) {
        console.warn("getNotificationsCount: ", err);
        return 0;
    }
};
export const getOneNotifDrc = async (id) => {
    const { url, method } = apiUrls.getNotificationById;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(id),
        method,
    });
    return mapNotifDrc(res);
};
export const deleteNotifDrc = async (id) => {
    if (!!id) {
        const { url, method } = apiUrls.removeNotification;
        const res = await fetchCookieJwtWithRefreshToken({
            url: url(id),
            method,
        });
        return res;
    }
    else {
        const { url, method } = apiUrls.deleteNotifications;
        const res = await fetchCookieJwtWithRefreshToken({
            url: url(),
            method,
        });
        return res;
    }
};
export const markAsReadNotifDrc = async (mark = true, id) => {
    if (!!id) {
        const { url, method } = apiUrls.notificationMarkRead;
        const res = await fetchCookieJwtWithRefreshToken({
            url: url(id, mark),
            method,
        });
        return res;
    }
    else {
        const { url, method } = apiUrls.notificationMarkAllRead;
        const res = await fetchCookieJwtWithRefreshToken({
            url: url(mark),
            method,
        });
        return res;
    }
};
export const markAsViewNotifDrc = async (mark = true) => {
    const { url, method } = apiUrls.notificationMarkAllView;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(mark),
        method,
    });
    return res;
};
export const getAllNotifJob = async () => {
    const { url, method } = apiUrls.getJobs;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
    });
    return res;
};
export const getOneNotifJob = async (id) => {
    const { url, method } = apiUrls.getJobId;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(id),
        method,
    });
    return res;
};
export const deleteNotifJob = async (id) => {
    if (!!id) {
        const { url, method } = apiUrls.deleteJobId;
        await fetchCookieJwtWithRefreshToken({
            url: url(id),
            method,
        });
    }
    else {
        const { url, method } = apiUrls.deleteJobs;
        await fetchCookieJwtWithRefreshToken({
            url: url(),
            method,
        });
    }
};
