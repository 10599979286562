import { Service } from "@warda/library-ui/contexts/AppWebSocketContext";
import { TypeCell } from "@warda/library-ui/layout/StickyGrid";
import { KEY_ASSIGNMENTS, KEY_ATTRIBUTE_SETS, KEY_ENTITY_ID, KEY_ENTITY_STRUCTURE_ID, KEY_MEDIA, KEY_PUBLC_ATTRS, KEY_PUBLC_MEDIA, KEY_READY_ATTRS, KEY_READY_MEDIA, KEY_ROOT_DOCUMENT_ID, KEY_VIEW_CHECK, KEY_VIEW_DATA, KEY_VIEW_STATUS, } from "../constants";
import { INDEX_NAME, } from "../../../interfaces";
import { search } from "../../../api/fetchesApiProducts";
import { mergeProductDatas, getDataDictionaries } from "./";
import { fetchCookieJwtWithRefreshToken } from "../../../api/fetchCookieJwt";
import apiUrls from "../../../api/apiUrlsProducts";
import { getAttributeId } from "../getAttributeKey";
import { MAX_PAGINATION } from "../../../constants/keys";
const getMSearchBody = (index, ids, _source) => ({
    index,
    body: {
        track_total_hits: true,
        from: 0,
        size: MAX_PAGINATION,
        sort: [],
        query: { terms: { _id: ids } },
        _source,
    },
});
const getMSearchItems = (elasticSrc) => {
    if (!!elasticSrc.error)
        return [];
    return elasticSrc.hits.hits.map((o) => ({
        id: o._id,
        data: o._source,
    }));
};
const msearch = async ({ itemsId, rootsId, loadMedia, loadRoots, loadReadyAttrs, loadReadyMedia, loadPublcAttrs, loadPublcMedia, }) => {
    const res = {
        rootsTabular: [],
        itemsTabularRoot: [],
        itemsMedia: [],
        itemsReady: [],
        itemsPublc: [],
    };
    if (!loadRoots)
        return res;
    const jsonBody = [
        getMSearchBody(INDEX_NAME.TABULAR, rootsId, [KEY_ATTRIBUTE_SETS]),
        getMSearchBody(INDEX_NAME.TABULARROOT, rootsId, undefined),
    ];
    const keysReady = [
        loadReadyMedia ? KEY_READY_MEDIA : null,
        loadReadyAttrs ? KEY_READY_ATTRS : null,
    ].filter(Boolean);
    const keysPublc = [
        loadPublcMedia ? KEY_PUBLC_MEDIA : null,
        loadPublcAttrs ? KEY_PUBLC_ATTRS : null,
    ].filter(Boolean);
    if (keysReady.length > 0) {
        jsonBody.push(getMSearchBody(INDEX_NAME.READY, itemsId, keysReady));
    }
    if (keysPublc.length > 0) {
        jsonBody.push(getMSearchBody(INDEX_NAME.PUBLC, itemsId, keysPublc));
    }
    if (loadMedia) {
        jsonBody.push(getMSearchBody(INDEX_NAME.MEDIA, itemsId, [KEY_MEDIA]));
    }
    const { url, method } = apiUrls.msearch;
    const { responses } = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody,
    });
    res.rootsTabular = getMSearchItems(responses[0]);
    res.itemsTabularRoot = getMSearchItems(responses[1]);
    const indexReady = jsonBody.findIndex((i) => i.index === INDEX_NAME.READY);
    if (indexReady > 0) {
        res.itemsReady = getMSearchItems(responses[indexReady]);
    }
    const indexPublc = jsonBody.findIndex((i) => i.index === INDEX_NAME.PUBLC);
    if (indexPublc > 0) {
        res.itemsPublc = getMSearchItems(responses[indexPublc]);
    }
    const indexMedia = jsonBody.findIndex((i) => i.index === INDEX_NAME.MEDIA);
    if (indexMedia > 0) {
        res.itemsMedia = getMSearchItems(responses[indexMedia]);
    }
    return res;
};
const findData = (a, keyValue) => {
    var _a;
    const res = (_a = a.find((i) => i.id === keyValue)) === null || _a === void 0 ? void 0 : _a.data;
    return res || {};
};
const searchHasAggs = (searchEs) => {
    return !!searchEs && !!searchEs.aggs;
};
const searchHasSource = (searchEs) => {
    return !!searchEs && !!searchEs._source && !!searchEs._source.length;
};
const searchOnlyOutTabular = (searchEs) => {
    const set = new Set([
        KEY_READY_ATTRS,
        KEY_READY_MEDIA,
        KEY_PUBLC_ATTRS,
        KEY_PUBLC_MEDIA,
    ]);
    return searchEs._source.every((s) => set.has(s));
};
export const decorateSource = (searchEs) => {
    // caso di nessun source -> richiedo il documento di tabular completo
    if (!searchHasSource(searchEs))
        return [];
    // caso di specifici source fuori da tabular -> richiedo il documento di tabular completo
    if (searchOnlyOutTabular(searchEs))
        return [];
    // caso "grid" -> richiedo solo gli attributi visibili come colonne
    // in questo caso aggiungo alcuni attributi necessari per il render corretto
    const sourceNew = new Set(searchEs._source);
    sourceNew.add(KEY_ENTITY_ID);
    sourceNew.add(KEY_ROOT_DOCUMENT_ID);
    sourceNew.add(KEY_ATTRIBUTE_SETS);
    sourceNew.add(KEY_ENTITY_STRUCTURE_ID);
    // in questo caso aggiungo alcuni attributi necessari per il render della colonna MEDIA
    if (sourceNew.has(KEY_MEDIA)) {
        sourceNew.add(KEY_VIEW_DATA);
        sourceNew.add(KEY_VIEW_CHECK);
        sourceNew.add(KEY_VIEW_STATUS);
        sourceNew.add(KEY_ASSIGNMENTS);
    }
    return Array.from(sourceNew);
};
const isLoadDicts = (searchEs, columns) => {
    if (searchHasAggs(searchEs))
        return false;
    if (searchHasSource(searchEs)) {
        return !!searchEs._source.some((key) => {
            const { attrId } = getAttributeId(key);
            const column = columns.find((c) => c.id === attrId);
            if (!column)
                return undefined;
            const type = column.type;
            const dict = new Set([
                TypeCell.DictionaryEntry,
                TypeCell.DictionaryEntries,
            ]);
            return dict.has(type);
        });
    }
    return true;
};
const isLoadByKey = (searchEs, key) => {
    if (searchHasAggs(searchEs))
        return false;
    if (searchHasSource(searchEs)) {
        return searchEs._source.some((k) => k === key);
    }
    return true;
};
const isLoadRoots = (searchEs) => {
    if (searchHasAggs(searchEs))
        return false;
    if (searchHasSource(searchEs)) {
        return searchEs._source.join() !== [KEY_ENTITY_ID].join();
    }
    return true;
};
export const howLoad = (searchEs, columns) => {
    const loadMedia = isLoadByKey(searchEs, KEY_MEDIA);
    return {
        loadDicts: isLoadDicts(searchEs, columns),
        loadMedia,
        loadRoots: isLoadRoots(searchEs),
        loadReadyAttrs: isLoadByKey(searchEs, KEY_READY_ATTRS),
        loadReadyMedia: isLoadByKey(searchEs, KEY_READY_MEDIA) || loadMedia,
        loadPublcAttrs: isLoadByKey(searchEs, KEY_PUBLC_ATTRS),
        loadPublcMedia: isLoadByKey(searchEs, KEY_PUBLC_MEDIA) || loadMedia,
    };
};
const searchEsItems = async ({ searchEs, columns, categories, }) => {
    // NOTE qui non va nessun try catch
    // l'errore va gestito caso per caso
    const res = await search({
        searchEs: { ...searchEs, _source: decorateSource(searchEs) },
        getUrl: (url) => url(INDEX_NAME.TABULAR, Service.SC),
    });
    const { items: itemsTabular, itemsTotal, aggregations } = res;
    if (!itemsTabular.length)
        return res;
    const { loadDicts, loadMedia, loadRoots, loadReadyAttrs, loadReadyMedia, loadPublcAttrs, loadPublcMedia, } = howLoad(searchEs, columns);
    const itemsId = itemsTabular.map((x) => x.data[KEY_ENTITY_ID]);
    const rootsId = itemsTabular.map((x) => x.data[KEY_ROOT_DOCUMENT_ID]);
    const [{ rootsTabular, itemsTabularRoot, itemsReady, itemsMedia, itemsPublc }, dictionaries,] = await Promise.all([
        msearch({
            itemsId,
            rootsId,
            loadMedia,
            loadRoots,
            loadReadyAttrs,
            loadReadyMedia,
            loadPublcAttrs,
            loadPublcMedia,
        }),
        getDataDictionaries(itemsTabular, columns, !loadDicts),
    ]);
    const items = itemsTabular.map(({ id, data }) => {
        const rootId = data[KEY_ROOT_DOCUMENT_ID];
        const rootItem = findData(rootsTabular, rootId);
        const tabularroot = findData(itemsTabularRoot, rootId);
        const ready = findData(itemsReady, id);
        const media = findData(itemsMedia, id);
        const publications = findData(itemsPublc, id);
        return mergeProductDatas({
            tabular: data,
            tabularroot,
            ready,
            media,
            publications,
            dictionaries,
            attributeSets: rootItem[KEY_ATTRIBUTE_SETS] || [],
            columns,
            categories,
            businessIds: [],
        });
    });
    return {
        aggregations,
        itemsTotal,
        items,
    };
};
export default searchEsItems;
