import { Service } from "@warda/library-ui/contexts/AppWebSocketContext";
import { RENDITION } from "../interfaces";
import serializeObject from "../utils/serializeObject";
import { BASE_URL } from "../constants/keys";
// NOTE taccone se è true abilita la vecchia api prima dello sviluppo di Gabri
// W3IM-1029
// SEECOMM-7511
const taccone = true;
var SEECOMMERCE_UPLOAD_TYPE;
(function (SEECOMMERCE_UPLOAD_TYPE) {
    SEECOMMERCE_UPLOAD_TYPE["POST"] = "post";
    SEECOMMERCE_UPLOAD_TYPE["SHOOTING"] = "shooting";
    SEECOMMERCE_UPLOAD_TYPE["XLSX"] = "xlsx";
    SEECOMMERCE_UPLOAD_TYPE["XLSXPRO"] = "xlsxpro";
    SEECOMMERCE_UPLOAD_TYPE["VIDEO"] = "video";
    SEECOMMERCE_UPLOAD_TYPE["DOCUMENT"] = "document";
})(SEECOMMERCE_UPLOAD_TYPE || (SEECOMMERCE_UPLOAD_TYPE = {}));
const apiUrls = {
    newUploadSC: {
        url: (type) => `${BASE_URL}/${Service.SC}/products/upload${type ? `/${type}` : ""}/new`,
        method: "POST",
    },
    newUploadConfirm: {
        url: (fileId, service, type) => `${BASE_URL}/${service === Service.SC ? service + "/products" : service}/upload/confirm${type ? `/${type}` : ""}/${fileId}`,
        method: "PUT",
    },
    deleteSeecommerceMedia: {
        url: (fileId, type) => `${BASE_URL}/${Service.SC}/products/media-content${type ? `/${type}` : ""}/${fileId}`,
        method: "DELETE",
    },
    msearch: {
        url: () => `${BASE_URL}/${Service.SC}/msearch`,
        method: "POST",
    },
    search: {
        url: (index, service) => `${BASE_URL}/${service}/search/${index}`,
        method: "POST",
    },
    postMultiReady: {
        url: (type) => `${BASE_URL}/${Service.SC}/products/${type}/multiReady`,
        method: "",
    },
    postReady: {
        url: (type) => `${BASE_URL}/${Service.SC}/products/${type}/ready`,
        method: "POST",
    },
    deleteReady: {
        url: (type) => `${BASE_URL}/${Service.SC}/products/${type}/ready`,
        method: "DELETE",
    },
    postViewCheck: {
        url: () => `${BASE_URL}/${Service.SC}/products/viewCheck`,
        method: "POST",
    },
    postViews: {
        url: () => `${BASE_URL}/${Service.SC}/products/views`,
        method: "POST",
    },
    postProduction: {
        url: () => `${BASE_URL}/${Service.SC}/products/postProduction`,
        method: "POST",
    },
    postExportData: {
        url: () => `${BASE_URL}/${Service.SC}/export`,
        method: "POST",
    },
    downloadMassiveSC: {
        url: () => `${BASE_URL}/${Service.SC}/products/download-massive`,
        method: "POST",
    },
    downloadMediaSC: {
        url: (fileId, params) => {
            let base = `${BASE_URL}/${Service.SC}/media/download-single/${fileId}`;
            const obj = { ...params };
            Object.keys(obj).forEach((k) => {
                if (obj[k] === undefined || obj[k] === null)
                    delete obj[k];
                if (obj.rendition === RENDITION.original)
                    delete obj.rendition;
            });
            if (taccone) {
                base = `${BASE_URL}/mcr/media-content/${fileId}`;
                if (!!obj.rendition) {
                    base = base + "/" + obj.rendition;
                    delete obj.rendition;
                }
            }
            if (Object.keys(obj).length === 0)
                return base;
            return base + "?" + serializeObject(obj);
        },
        method: "GET",
    },
    zoomMediaSC: {
        url: (fileId) => {
            if (taccone) {
                return `${BASE_URL}/mcr/media-content/${fileId}/zoom-tiles/tiles.dzi?redirect=false`;
            }
            return `${BASE_URL}/${Service.SC}/media/zoom-tiles/${fileId}/tiles.dzi?redirect=false`;
        },
        method: "GET",
    },
    editProduct: {
        url: () => `${BASE_URL}/${Service.SC}/products`,
        method: "PUT",
    },
    editMultiProduct: {
        url: () => `${BASE_URL}/${Service.SC}/products/multiProduct`,
        method: "PUT",
    },
    assignment: {
        url: () => `${BASE_URL}/${Service.SC}/products/assignment`,
        method: "POST",
    },
    multiAssignment: {
        url: () => `${BASE_URL}/${Service.SC}/products/multiAssignment`,
        method: "POST",
    },
    distributions: {
        url: () => `${BASE_URL}/${Service.SC}/products/distributions`,
        method: "POST",
    },
    multiViewDataMedia: {
        url: () => `${BASE_URL}/${Service.SC}/products/multiViewData`,
        method: "POST",
    },
    multiViewDataDocuments: {
        url: () => `${BASE_URL}/${Service.SC}/products/document/multiViewData`,
        method: "POST",
    },
    multiResetViews: {
        url: () => `${BASE_URL}/${Service.SC}/products/multiResetViews`,
        method: "POST",
    },
    resetAttribute: {
        url: () => `${BASE_URL}/${Service.SC}/products/resetAttribute`,
        method: "POST",
    },
    canPublicFile: {
        url: (service, fileId) => `${BASE_URL}/${service}/publish/publishable/${fileId}`,
        method: "GET",
    },
    createNotification: {
        url: () => `${BASE_URL}/notifier/notifications/${Service.SC}`,
        method: "POST",
    },
    removeNotification: {
        url: (id) => `${BASE_URL}/notifier/notifications/${Service.SC}/${id}`,
        method: "DELETE",
    },
    notificationMarkRead: {
        url: (id, mark) => `${BASE_URL}/notifier/notifications/${Service.SC}/${id}/read?mark=${mark}`,
        method: "PUT",
    },
    notificationMarkAllRead: {
        url: (mark) => `${BASE_URL}/notifier/notifications/${Service.SC}/read?mark=${mark}`,
        method: "PUT",
    },
    notificationMarkView: {
        url: (id, mark) => `${BASE_URL}/notifier/notifications/${Service.SC}/${id}/view?mark=${mark}`,
        method: "PUT",
    },
    notificationMarkAllView: {
        url: (mark) => `${BASE_URL}/notifier/notifications/${Service.SC}/view?mark=${mark}`,
        method: "PUT",
    },
    deleteNotifications: {
        url: () => `${BASE_URL}/notifier/notifications/${Service.SC}`,
        method: "DELETE",
    },
    getNotifications: {
        url: (size, from, filter) => `${BASE_URL}/notifier/notifications/${Service.SC}?size=${size}&from=${from}${!filter ? "" : `&filter=${filter}`}`,
        method: "GET",
    },
    getNotificationsCount: {
        url: (filter) => `${BASE_URL}/notifier/notifications/${Service.SC}/count?${!filter ? "" : `&filter=${filter}`}`,
        method: "GET",
    },
    getNotificationById: {
        url: (id) => `${BASE_URL}/notifier/notifications/${Service.SC}/${id}`,
        method: "GET",
    },
    exportProductEvents: {
        url: () => `${BASE_URL}/${Service.SC}/products/events`,
        method: "POST",
    },
    exportXlsx: {
        url: () => `${BASE_URL}/${Service.SC}/export/v2`,
        method: "POST",
    },
    postEasyCatalog: {
        url: () => `${BASE_URL}/${Service.SC}/export/easyCatalog`,
        method: "POST",
    },
    getProduct: {
        url: (id) => `${BASE_URL}/${Service.SC}/products?${serializeObject({
            product: id,
        })}`,
        method: "GET",
    },
    getJobs: {
        url: () => `${BASE_URL}/${Service.NOTIFIER}/jobs/${Service.SC}?size=250&from=0`,
        method: "GET",
    },
    getJobId: {
        url: (id) => `${BASE_URL}/${Service.NOTIFIER}/jobs/${Service.SC}/${id}`,
        method: "GET",
    },
    deleteJobs: {
        url: () => `${BASE_URL}/${Service.NOTIFIER}/jobs/${Service.SC}`,
        method: "DELETE",
    },
    deleteJobId: {
        url: (id) => `${BASE_URL}/${Service.NOTIFIER}/jobs/${Service.SC}/${id}`,
        method: "DELETE",
    },
    getAttributeDicts: {
        url: () => `${BASE_URL}/${Service.SC}/config/dictionary`,
        method: "POST",
    },
    getMcrDocumentId: {
        url: (fileId) => `${BASE_URL}/${Service.SC}/media/download-document/${fileId}/documentId`,
        method: "GET",
    },
    getMcrDocumentToken: {
        url: (docId) => `${BASE_URL}/${Service.SC}/media/download-document/${docId}/access-token`,
        method: "GET",
    },
    completenessCount: {
        url: () => `${BASE_URL}/${Service.SC}/completeness-count`,
        method: "POST",
    },
    getEntityBussinessIds: {
        url: (entityId) => `${BASE_URL}/${Service.SC}/products/businessIds?${serializeObject({
            product: entityId,
        })}`,
        method: "GET",
    },
    deleteEntities: {
        url: (ids) => {
            if (ids.length === 1)
                return `${BASE_URL}/${Service.SC}/products/entity`;
            else
                return `${BASE_URL}/${Service.SC}/products/entities`;
        },
        method: "DELETE",
    },
};
export default apiUrls;
