import { Service } from "@warda/library-ui/contexts/AppWebSocketContext";
import { BASE_URL } from "../constants/keys";
import serializeObject from "../utils/serializeObject";
import { getAccessCode } from "../components/AreaSharepage/constants";
const apiUrls = {
    getLogoTenant: {
        url: (tenantId) => `${BASE_URL}/mcr/logo/${tenantId}`,
        method: "GET",
    },
    searchFiles: {
        url: () => `${BASE_URL}/${Service.SC}/library/public-page/public-files`,
        method: "POST",
    },
    downloadMassive: {
        url: () => {
            const accessCode = getAccessCode();
            return `${BASE_URL}/${Service.SC}/media/download-massive/${accessCode}`;
        },
        method: "POST",
    },
    mediaDownload: {
        url: (fileId, rendition, downloadAs, redirect) => {
            const accessCode = getAccessCode();
            const base = `${BASE_URL}/${Service.SC}/media/download-published/${fileId}`;
            const obj = { accessCode, rendition, downloadAs, redirect };
            Object.keys(obj).forEach((k) => {
                if (obj[k] === undefined || obj[k] === null)
                    delete obj[k];
            });
            return base + "?" + serializeObject(obj);
        },
        method: "GET",
    },
    mediaZoom: {
        url: (fileId) => {
            const accessCode = getAccessCode();
            return `${BASE_URL}/${Service.SC}/media/download-published/${fileId}/zoom-tiles/${accessCode}/tiles.dzi?redirect=false`;
        },
        method: "GET",
    },
    boxDocumentId: {
        url: (fileId) => {
            const accessCode = getAccessCode();
            return `${BASE_URL}/${Service.SC}/media/download-published/${fileId}/published-documents/${accessCode}/documentId`;
        },
        method: "GET",
    },
    boxDocumentToken: {
        url: (documentId) => {
            const accessCode = getAccessCode();
            return `${BASE_URL}/${Service.SC}/media/download-published/document/${documentId}/${accessCode}/access-token`;
        },
        method: "GET",
    },
};
export default apiUrls;
