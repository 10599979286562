import { Initialize } from "@warda/library-ui/interfaces";
import { getQuery, getSorts } from "./";
import { SEARCH_ES_ERROR } from "./ISearch";
import getCtlgId from "../getCtlgId";
import searchEs from "./searchEs";
const searchEsDatas = async ({ categories, catalogs, columns, filters, filtersCondition, paginationCurr, paginationSize, sorts, itemsIdSelected, slcCtlg: slcCtlgId, slcLang, source, }) => {
    try {
        const slcCtlg = getCtlgId(catalogs, slcCtlgId);
        const sort = getSorts({
            sorts,
            catalogs,
            columns,
            slcCtlg,
            slcLang,
        });
        const searchEsConf = !!(itemsIdSelected === null || itemsIdSelected === void 0 ? void 0 : itemsIdSelected.length)
            ? {
                sort,
                query: getQuery({
                    indexIds: itemsIdSelected,
                }),
                _source: source,
            }
            : {
                sort,
                size: paginationSize,
                from: paginationSize * (paginationCurr - 1),
                query: getQuery({
                    filtersCondition,
                    filters,
                    columns,
                    slcCtlg,
                    slcLang,
                }),
                _source: source,
            };
        const { items, itemsTotal } = await searchEs({
            searchEs: searchEsConf,
            columns,
            categories,
        });
        return {
            initSearch: Initialize.SUCC,
            items,
            itemsTotal,
        };
    }
    catch (err) {
        console.log("[AreaProducts] useSearch", err);
        return SEARCH_ES_ERROR;
    }
};
export default searchEsDatas;
