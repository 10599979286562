//////// ⚠️ key parameter
export const KEYPAR_ENTITY = "id";
export const KEYPAR_DETAIL = "dp";
export const KEYPAR_TAB = "tab";
export const KEYPAR_IMG = "img";
////////
export const KEY_VIEW_CHECK = "viewCheck";
export const KEY_VIEW_STATUS = "viewStatus";
export const KEY_VIEW_DATA = "viewData";
export const KEY_MEDIA = "media";
export const KEY_READY_MEDIA = "readyMedia";
export const KEY_READY_ATTRS = "readyAttributes";
export const KEY_PUBLC_MEDIA = "mediaPublications";
export const KEY_PUBLC_ATTRS = "attributePublications";
export const KEY_CATALOG = "catalogs";
export const KEY_ENTITY_STRUCTURE_ID = "entityStructureId";
export const KEY_ROOT_DOCUMENT_ID = "rootDocumentId";
export const KEY_ENTITY_ID = "entityId";
export const KEY_ENTITY_TYPE = "entityType";
export const KEY_CARRYOVER_PARENT = "carryOverParent";
export const KEY_ATTRIBUTE_SETS = "attributeSets";
export const KEY_ASSIGNMENTS = "assignments";
export const KEY_VIEWS_EDITING_MEDIA = "mediaViewsEditingPerformed";
export const KEY_VIEWS_EDITING_DOCUMENT = "documentViewsEditingPerformed";
export const KEY_EDITED_ATTRIBUTES = "editedAttributes";
export const KEY_BUSINESS_IDS = "businessIds";
export const FILTERS_STICKY = new Set([KEY_ENTITY_TYPE, KEY_CATALOG]);
export const MANAGER_GROUPS = [
    "ALL ATTRIBUTES",
    "CATEGORIES",
    "MEDIA",
    "MASTER",
    "PLANNING",
    "EDITORIAL",
    "ASSOCIATION",
    "MONITORING",
    "OTHERS",
    "DOCUMENTS",
    "GLOBAL",
    "METADATA",
].map((id) => ({ id, label: id }));
