import { PREF_KEY } from "../constants/userpref";
const getMngSetsSelected = (context, preferences, sets) => {
    var _a;
    if (!context || !preferences || !sets) {
        return { slcSetId: "", slcSet: undefined };
    }
    const slcIdPref = preferences[PREF_KEY.jsonstoreContext][context];
    const slcStPref = sets.find((s) => s.id === slcIdPref);
    if (!!slcStPref) {
        return { slcSetId: slcIdPref, slcSet: slcStPref };
    }
    // gestisco l'active salvato nei vecchi sets
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const slcIdActv = (_a = sets.find((s) => s.payload.active)) === null || _a === void 0 ? void 0 : _a.id;
    const slcStActv = sets.find((s) => s.id === slcIdActv);
    if (!!slcStActv) {
        return { slcSetId: slcIdActv, slcSet: slcStActv };
    }
    return { slcSetId: "", slcSet: undefined };
};
export default getMngSetsSelected;
