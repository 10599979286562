import { MISSING_KEY } from "@warda/library-ui/constants";
import { clearFiltersAttr, FilterTypeCategory, FilterTypeDictionary, FilterTypeUser, FilterTypeSingleSelection, FilterTypeMultiSelection, FilterTypeText, FilterTypeSimpleSelection, } from "../utils";
const typeSlct = new Set([
    FilterTypeDictionary,
    FilterTypeCategory,
    FilterTypeUser,
    FilterTypeSimpleSelection,
    FilterTypeSingleSelection,
    FilterTypeMultiSelection,
]);
const typeText = new Set([FilterTypeText]);
const getFilterChanged = (filter, chipId, chipValue) => {
    if (typeSlct.has(filter.type)) {
        const { value } = filter;
        const newValue = Array.from(value);
        // attenzione, il value non è uguale per tutti i typeSlct
        // alcuni sono string[] ed altri sono {id:string}[]
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const itemIndex = newValue.findIndex((v) => {
            return v === chipId || v.id === chipId;
        });
        newValue.splice(itemIndex, 1);
        return {
            ...filter,
            value: newValue,
        };
    }
    if (typeText.has(filter.type)) {
        const { value, multiline } = filter;
        if (!multiline) {
            return clearFiltersAttr([filter])[0];
        }
        if (chipId === MISSING_KEY) {
            return { ...filter, switchNoValue: false };
        }
        const newValue = Array.from(value);
        const itemIndex = newValue.findIndex((v) => v === chipValue);
        newValue.splice(itemIndex, 1);
        return {
            ...filter,
            value: newValue,
        };
    }
    return clearFiltersAttr([filter])[0];
};
export default getFilterChanged;
