import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { FilterBoolean, FilterDate, FilterPercentage, FilterWrapper, FilterText, } from "@warda/library-ui/core/Filter";
import Text from "@warda/library-ui/core/Text";
import { DATE_FORMAT } from "@warda/library-ui/constants";
import FilterSearchUsers from "./FilterSearchUsers";
import FilterSearchDictionaries from "./FilterSearchDictionaries";
import FilterSearchStrings from "./FilterSearchStrings";
import FilterSimpleSelection from "./FilterSimpleSelection";
import { getFilterAttrDisabled, getFilterAttrSubLabel, FilterTypeDictionary, FilterTypeUser, FilterTypeSingleSelection, FilterTypeMultiSelection, FilterTypeText, FilterTypeDate, FilterTypeBoolean, FilterTypePercentage, FilterTypeSimpleSelection, } from "./utils";
const PanelFiltersListitemRender = ({ catalogs, columns, onFilterValue, onFilterCount, searchEs, overrideMapId, overrideMapType, slcCtlg, slcLang, filter, }) => {
    const { id, type, label } = filter;
    const p = {
        ...filter,
        onChange: onFilterValue,
        dateFormat: DATE_FORMAT,
        searchEs,
        collapsed: getFilterAttrDisabled(filter, catalogs, slcCtlg, slcLang),
        labelSub: getFilterAttrSubLabel(filter, catalogs, slcCtlg, slcLang),
        slcCtlg,
        slcLang,
        column: columns.find((c) => c.id === id),
        onClickCount: onFilterCount,
    };
    if (overrideMapId[id]) {
        const CustomFacet = overrideMapId[id];
        return React.createElement(CustomFacet, { key: id, ...p });
    }
    if (overrideMapType[type]) {
        const CustomFacet = overrideMapType[type];
        return React.createElement(CustomFacet, { key: id, ...p });
    }
    switch (type) {
        case FilterTypeUser:
            return React.createElement(FilterSearchUsers, { key: id, ...p });
        case FilterTypeSimpleSelection:
            return (React.createElement(FilterSimpleSelection, { key: id, ...p }));
        case FilterTypeDictionary:
            return (React.createElement(FilterSearchDictionaries, { key: id, ...p }));
        case FilterTypeSingleSelection:
        case FilterTypeMultiSelection:
            return React.createElement(FilterSearchStrings, { key: id, ...p });
        case FilterTypeDate:
            return React.createElement(FilterDate, { key: id, ...p });
        case FilterTypeText:
            return React.createElement(FilterText, { key: id, ...p });
        case FilterTypeBoolean:
            return React.createElement(FilterBoolean, { key: id, ...p });
        case FilterTypePercentage:
            return React.createElement(FilterPercentage, { key: id, ...p });
        default:
            return (React.createElement(FilterWrapper, { key: id, label: label, color: getTheme().colors.theme1 },
                React.createElement(Text, { style: {
                        marginLeft: 15,
                        color: getTheme().colors.disable,
                    }, children: `Unknown type ${type}` })));
    }
};
export default PanelFiltersListitemRender;
