import * as React from "react";
import last from "@warda/library-ui/utils/last";
import { isEmpty } from "lodash-es";
import Btn from "@warda/library-ui/core/Btn";
import { isMobile } from "@warda/library-ui/utils/deviceUtils";
import Breadcrumb from "@warda/library-ui/core/Breadcrumb";
import { ACT_VPORT } from "../reducer";
import { CtxDispatchViewport } from "../contexts";
import SheetBottom from "../SheetBottom";
const HOME_ID = "home";
const BtnBreadcrumb = ({ className, style, pagePrntId, folderPath, }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const [modal, setModal] = React.useState(false);
    const cbOnModalOpen = React.useCallback(() => {
        setModal(true);
    }, []);
    const cbOnModalClose = React.useCallback(() => {
        setModal(false);
    }, []);
    const cbOnClickPathId = React.useCallback((pathId) => {
        if (pathId === HOME_ID) {
            location.reload();
        }
        else {
            dispatchViewport({ type: ACT_VPORT.BREADCRUMB, pathId });
            setModal(false);
        }
    }, [dispatchViewport]);
    const cbOnClickSheet = React.useCallback((event, pathId) => {
        cbOnClickPathId(pathId);
    }, [cbOnClickPathId]);
    const actions = [
        {
            id: HOME_ID,
            label: "Home",
            icon: "home_filled",
            onClick: cbOnClickSheet,
        },
    ];
    folderPath.forEach((c) => {
        const divider = {
            id: c.id + "_divider",
            label: "",
            icon: "keyboard_arrow_up",
            disabled: true,
            style: { maxHeight: 10, minHeight: 0 },
        };
        actions.push(divider);
        const data = {
            id: c.id,
            label: c.name,
            icon: c.id === pagePrntId ? "home_filled" : "folder",
            onClick: cbOnClickSheet,
        };
        actions.push(data);
    });
    if (isEmpty(folderPath))
        return null;
    if (isMobile()) {
        actions.reverse();
        return (React.createElement(React.Fragment, null,
            React.createElement(Btn, { className: className, style: style, label: last(folderPath).name, labelSize: 2, onClick: cbOnModalOpen }),
            React.createElement(SheetBottom, { open: modal, onClose: cbOnModalClose, titleText: "Breadcrumb", actions: actions })));
    }
    return (React.createElement(Breadcrumb, { maxItems: 4, items: actions
            .filter((a) => !a.disabled)
            .map((a) => ({ id: a.id, name: a.label })), onClick: cbOnClickPathId }));
};
export default BtnBreadcrumb;
