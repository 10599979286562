import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { createUseStyles } from "react-jss";
import Icon from "@warda/library-ui/core/Icon";
import Text from "@warda/library-ui/core/Text";
import Tooltip from "@warda/library-ui/core/Tooltip";
import CircularProgress from "@warda/library-ui/core/CircularProgress";
import Avatar from "@warda/library-ui/core/Avatar";
import { getUser } from "@warda/library-ui/utils/getUser";
import { enTimeAgoFormat, enDateTimeFormat, } from "@warda/library-ui/utils/dateTimeEnConverters";
import BtnDelete from "./BtnDelete";
import { CtxTenantM2ms, CtxTenantUsers } from "../../contexts";
import { RequestStatus, RequestType } from "../../../interfaces";
const getColor = (completed, hasErrors) => {
    if (hasErrors)
        return getTheme().colors.msgWarn;
    if (completed)
        return getTheme().colors.msgSucc;
    return getTheme().colors.theme1;
};
const getIcon = (completed, hasErrors) => {
    if (hasErrors) {
        return (React.createElement(Tooltip, { title: "Was there any problem" },
            React.createElement(Icon, { style: { color: getTheme().colors.msgWarn, fontSize: 18 }, children: "sync_problem" })));
    }
    if (completed) {
        return (React.createElement(Tooltip, { title: "Edit request complete successfully" },
            React.createElement(Icon, { style: { color: getTheme().colors.msgSucc, fontSize: 18 }, children: "check_circle" })));
    }
    return (React.createElement(Tooltip, { title: "Edit request in progress" },
        React.createElement(CircularProgress, { size: 18 })));
};
const mapLabel = {
    [RequestType.ATTRIBUTE_EDITING]: "Edit attribute",
    [RequestType.READY]: "Ready",
    [RequestType.READY_MEDIA]: "Ready view",
    [RequestType.READY_ATTRIBUTE]: "Ready attribute",
    [RequestType.ASSIGNMENT]: "Assign view",
    [RequestType.VIEW_EDITING]: "View editing",
    [RequestType.VIEW_RESET]: "View reset",
    [RequestType.PUBLISHED]: "Published",
    [RequestType.REMOVE_READY_MEDIA]: "Unready view",
};
const useStyles = createUseStyles({
    card: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "5px 15px",
        backgroundColor: getTheme().colors.background,
        "&:hover": {
            backgroundColor: getTheme().colors.mousehover,
        },
        "&:hover $btnDelete": {
            transform: "scale(1)",
        },
    },
    btnDelete: {
        transform: "scale(0)",
    },
});
const Card = ({ dispatch, data }) => {
    const { jobId, from, completed, creation, payload } = data;
    const classes = useStyles({});
    const users = React.useContext(CtxTenantUsers);
    const m2ms = React.useContext(CtxTenantM2ms);
    const user = getUser(from, users, m2ms);
    const requestType = payload.requestType;
    const progress = payload.progress || [];
    const countTotal = progress.length;
    const countError = progress
        .map((a) => a[1])
        .map((a) => (typeof a === "string" ? a : a.status))
        .filter((a) => a === RequestStatus.error).length;
    const countDone = progress
        .map((a) => a[1])
        .map((a) => (typeof a === "string" ? a : a.status))
        .filter((a) => a === RequestStatus.done).length;
    const color = getColor(!!completed, !!countError);
    return (React.createElement("div", { className: classes.card },
        getIcon(!!completed, !!countError),
        React.createElement(Avatar, { style: { margin: "0 10px" }, tooltip: user.name, src: user.avatar, text: user.avatarText, icon: user.avatarIcon, size: 28 }),
        React.createElement(Text, { ellipsis: true, style: { flex: 1 }, children: mapLabel[requestType] || requestType }),
        React.createElement(Tooltip, { title: enDateTimeFormat(creation) },
            React.createElement("div", { style: { flex: 1, margin: "0 10px" } },
                React.createElement(Text, { size: 0, children: enTimeAgoFormat(creation) }))),
        React.createElement(Text, { style: { width: 60, color, textAlign: "right", marginRight: 10 }, children: countTotal ? `${countDone}/${countTotal}` : "" }),
        React.createElement(BtnDelete, { dispatch: dispatch, id: jobId, className: classes.btnDelete })));
};
export default Card;
