import * as React from "react";
import Btn from "@warda/library-ui/core/Btn";
import { ACT_VPORT } from "../reducer";
import { CtxDispatchViewport } from "../contexts";
const BtnDetail = ({ className, style }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const cbOnClick = React.useCallback(() => {
        dispatchViewport({ type: ACT_VPORT.DIALOG_DETAIL });
    }, [dispatchViewport]);
    return (React.createElement(Btn, { className: className, style: style, icon: "chrome_reader_mode", tooltip: "Detail", onClick: cbOnClick }));
};
export default BtnDetail;
