import * as React from "react";
import mixColors from "@warda/library-ui/utils/mixColors";
import Text from "@warda/library-ui/core/Text";
import { createUseStyles } from "react-jss";
import Card from "@warda/library-ui/core/Card";
import Icon from "@warda/library-ui/core/Icon";
import { getTheme } from "@warda/library-ui/theme";
import classnames from "classnames";
const useStyles = createUseStyles({
    snackbar: {
        position: "fixed",
        zIndex: getTheme().zIndex.tooltip,
        width: "fit-content",
        top: 25,
        left: 0,
        right: 0,
        margin: "auto",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "5px 20px 5px 15px",
        transition: "all 250ms",
        backgroundColor: mixColors(0.9, getTheme().colors.msgWarn, getTheme().colors.background),
    },
    snackbarOnline: {
        top: "-100px",
    },
});
const SnackbarOffline = () => {
    const classes = useStyles({});
    const [online, setOnline] = React.useState(true);
    const checkOnline = React.useCallback(() => setOnline(navigator.onLine), []);
    React.useEffect(() => {
        window.addEventListener("online", checkOnline);
        window.addEventListener("offline", checkOnline);
        return () => {
            window.removeEventListener("online", checkOnline);
            window.removeEventListener("offline", checkOnline);
        };
    }, [checkOnline]);
    return (React.createElement(Card, { elevation: 1, className: classnames({
            [classes.snackbar]: true,
            [classes.snackbarOnline]: online,
        }) },
        React.createElement(Icon, { style: { color: getTheme().colors.msgWarn, marginRight: 15 }, children: "wifi_off" }),
        React.createElement("div", null,
            React.createElement(Text, { children: "It seems that you are offline" }),
            React.createElement(Text, { children: "Please check your connection" }))));
};
export default SnackbarOffline;
