import * as React from "react";
import ViewBlock, { cellTypeFooter, cellTypeHeader, } from "@warda/library-ui/layout/ViewBlock";
import { FileType } from "@warda/library-ui/interfaces";
import { isMobile } from "@warda/library-ui/utils/deviceUtils";
import Btn from "@warda/library-ui/core/Btn";
import ViewBlockAssetMedia, { blockHeightMedia } from "./ViewBlockAssetMedia";
import ViewBlockAssetFolder, { blockHeightFolder, } from "./ViewBlockAssetFolder";
import { itemsTypeSort, needHeader, needFooter } from "../utils";
import ViewBlockHeader from "./ViewBlockHeader";
import ViewBlockFooter from "./ViewBlockFooter";
const blockHeightUnknown = 35;
const blockItemWidth = isMobile() ? Math.min(window.innerWidth / 2, 180) : 240;
const itemHeight = (type) => {
    switch (type) {
        case cellTypeHeader:
        case cellTypeFooter:
            return 50;
        case FileType.FOLDER:
            return blockHeightFolder;
        case FileType.MEDIA:
            return blockHeightMedia;
        default:
            return blockHeightUnknown;
    }
};
const ViewBlockAssetUnknown = ({ id, color }) => {
    return React.createElement(Btn, { color: color, label: String(id) });
};
const View = ({ color, searchTime, files, filesTotal, filesSlcId, loading, onClick, placeholderIcon, placeholderLabel, moreLoading, }) => {
    const itemRender = React.useCallback((p) => {
        switch (p.type) {
            case FileType.FOLDER:
                return React.createElement(ViewBlockAssetFolder, { ...p, loading: loading });
            case FileType.MEDIA:
                return React.createElement(ViewBlockAssetMedia, { ...p, loading: loading });
            default:
                return React.createElement(ViewBlockAssetUnknown, { ...p });
        }
    }, [loading]);
    const itemHeader = React.useCallback(({ type, indexRow, indexCol, totalRows, totalCols }) => (React.createElement(ViewBlockHeader, { type: type, filesTotal: filesTotal, indexRow: indexRow, indexCol: indexCol, totalRows: totalRows, totalCols: totalCols })), [filesTotal]);
    const itemFooter = React.useCallback(({ type, indexRow, indexCol, totalRows, totalCols }) => (React.createElement(ViewBlockFooter, { type: type, files: files, filesTotal: filesTotal, indexRow: indexRow, indexCol: indexCol, totalRows: totalRows, totalCols: totalCols, moreLoading: moreLoading })), [files, filesTotal, moreLoading]);
    return (React.createElement(ViewBlock, { color: color, itemWidth: blockItemWidth, itemHeight: itemHeight, itemRender: itemRender, itemHeader: itemHeader, itemFooter: itemFooter, needHeader: needHeader, needFooter: needFooter(files, filesTotal), items: files, itemsSelectedId: filesSlcId, itemsTypeSort: itemsTypeSort, loading: loading, onClick: onClick, placeholderIcon: placeholderIcon, placeholderLabel: placeholderLabel, resetScrollbar: searchTime }));
};
export default View;
