import * as React from "react";
import { SegmentedViewType } from "@warda/library-ui/core/SegmentedView";
import PlaceholderError from "@warda/library-ui/core/PlaceholderError";
import Placeholder from "@warda/library-ui/core/Placeholder";
import { ACT_VPORT } from "../reducer";
import ViewList from "./ViewList";
import ViewBlock from "./ViewBlock";
import { CtxDispatchViewport } from "../contexts";
import { ViewMode } from "../constants";
const PlaceholderCase = ({ text }) => {
    switch (text) {
        case "deleted":
            return (React.createElement(Placeholder, { icon: "folder_delete", label: "This folder has been deleted" }));
        default:
            return React.createElement(PlaceholderError, { title: text });
    }
};
const View = ({ searchTime, error, files, filesSlcId, filesTotal, loading, sortKey, sortOrd, viewMode, viewType, moreLoading, }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const placeholderIcon = viewMode === ViewMode.SEARCH ? "search" : "folder_open";
    const placeholderLabel = viewMode === ViewMode.SEARCH
        ? "REFINE YOUR SEARCH"
        : "THIS FOLDER IS EMPTY";
    const cbOnViewClick = React.useCallback(() => {
        dispatchViewport({ type: ACT_VPORT.DESELECT_FILES });
    }, [dispatchViewport]);
    return (React.createElement("div", { style: {
            flex: 1,
            position: "relative",
            display: "flex",
            flexDirection: "column",
        } },
        !error ? null : React.createElement(PlaceholderCase, { text: error }),
        error || viewType !== SegmentedViewType.BLOCK ? null : (React.createElement(ViewBlock, { searchTime: searchTime, loading: loading, files: files, filesTotal: filesTotal, filesSlcId: filesSlcId, onClick: cbOnViewClick, placeholderIcon: placeholderIcon, placeholderLabel: placeholderLabel, moreLoading: moreLoading })),
        error || viewType !== SegmentedViewType.LIST ? null : (React.createElement(ViewList, { searchTime: searchTime, loading: loading, files: files, filesTotal: filesTotal, filesSlcId: filesSlcId, onClick: cbOnViewClick, sortKey: sortKey, sortOrd: sortOrd, placeholderIcon: placeholderIcon, placeholderLabel: placeholderLabel, moreLoading: moreLoading }))));
};
export default View;
