import * as React from "react";
import Text from "@warda/library-ui/core/Text";
import Tooltip from "@warda/library-ui/core/Tooltip";
import { enDateTimeFormat, enTimeAgoFormat, } from "@warda/library-ui/utils/dateTimeEnConverters";
import BtnMarkRead from "./BtnMarkRead";
import BtnDelete from "./BtnDelete";
import CardAvatar from "./CardAvatar";
import BtnCopy from "./BtnCopy";
const CardTitle = ({ classes, dispatch, data, title, entityIds = [], avatarIcon, avatarColor, }) => {
    const { id, from, markedAsRead, creation } = data;
    return (React.createElement("div", { className: classes.cardTitle },
        React.createElement(BtnMarkRead, { dispatch: dispatch, id: id, markedAsRead: markedAsRead }),
        React.createElement(CardAvatar, { userId: avatarIcon ? undefined : from, icon: avatarIcon, color: avatarColor }),
        React.createElement(Text, { className: classes.textTitle, children: title }),
        React.createElement(Tooltip, { title: enDateTimeFormat(creation) },
            React.createElement("div", null,
                React.createElement(Text, { className: classes.textDate, size: 0, children: enTimeAgoFormat(creation) }))),
        React.createElement(BtnCopy, { classes: classes, entityIds: entityIds }),
        React.createElement(BtnDelete, { classes: classes, dispatch: dispatch, id: id })));
};
export default CardTitle;
