import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import * as moment from "moment";
import { createUseStyles } from "react-jss";
import { DATE_FORMAT } from "@warda/library-ui/constants";
import Text from "@warda/library-ui/core/Text";
import LabelMandatory from "@warda/library-ui/core/Field/LabelMandatory";
import { getUser } from "@warda/library-ui/utils/getUser";
import { FieldMentions } from "@warda/library-ui/core/Field";
import CardTitle from "../CardTitle";
import CardContent from "../CardContent";
import { CtxTenantM2ms, CtxTenantUsers } from "../../../contexts";
import apiUrls from "../../../../api/apiUrlsProducts";
import { RENDITION, } from "../../../../interfaces";
const useStyles = createUseStyles({
    previewMedia: {
        width: 26,
        height: 26,
        "background-size": "cover",
        "margin-right": 4,
        border: `1px solid ${getTheme().colors.grayBorder}`,
        "border-radius": getTheme().borderRadius,
        overflow: "hidden",
    },
    tooltipNote: {
        flex: 1,
        padding: "2px 5px",
        "box-sizing": "border-box",
        "max-height": 250,
        "overflow-y": "hidden",
        "background-color": "transparent !important",
        "border-color": "transparent !important",
        "& *": {
            color: "#fff !important",
        },
    },
});
const CardMentionImgSc = ({ cardClasses, dispatch, data }) => {
    const classes = useStyles({});
    const users = React.useContext(CtxTenantUsers);
    const m2ms = React.useContext(CtxTenantM2ms);
    const { payload } = data;
    const { entityId, annotation, mediaFileId, mediaUploaded, mediaView, mediaViewRequired, } = payload;
    const mediaUrl = apiUrls.downloadMediaSC.url(mediaFileId, {
        rendition: RENDITION.image_xs,
    });
    const mediaUploadedDate = moment(Number(mediaUploaded)).format(DATE_FORMAT);
    return (React.createElement(React.Fragment, null,
        React.createElement(CardTitle, { classes: cardClasses, dispatch: dispatch, title: "Mentioned you on a media", data: data, entityIds: [entityId] }),
        React.createElement(CardContent, { tooltip: React.createElement(FieldMentions, { readOnly: true, className: classes.tooltipNote, value: annotation, users: users.map((u) => {
                    return getUser(u.userId, users, m2ms);
                }) }), classes: cardClasses },
            React.createElement("div", { className: classes.previewMedia, style: { backgroundImage: `url(${mediaUrl})` } }),
            React.createElement(Text, { style: { flex: 1 }, size: 0, children: React.createElement(React.Fragment, null,
                    React.createElement("span", { children: `Uploaded on ${mediaUploadedDate} in View ` }),
                    React.createElement("span", { style: { fontWeight: "bold" }, children: React.createElement(LabelMandatory, { label: mediaView, mandatory: mediaViewRequired }) })) }))));
};
export default CardMentionImgSc;
