import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import ListItem from "@warda/library-ui/core/ListItem";
import Text from "@warda/library-ui/core/Text";
import { Initialize } from "@warda/library-ui/interfaces";
import UploadItemStatusIcon from "../../uploadItemStatus/UploadItemStatusIcon";
var ACTION;
(function (ACTION) {
    ACTION["INIT_SUCC"] = "INIT_SUCC";
    ACTION["INIT_FAIL"] = "INIT_FAIL";
})(ACTION || (ACTION = {}));
const reducerInitState = {
    initialize: Initialize.START,
    imgW: 0,
    imgH: 0,
};
const reducer = (state, action) => {
    const newState = { ...state };
    switch (action.type) {
        case ACTION.INIT_FAIL:
            newState.initialize = Initialize.FAIL;
            return newState;
        case ACTION.INIT_SUCC:
            newState.initialize = Initialize.SUCC;
            newState.imgW = action.size[0];
            newState.imgH = action.size[1];
            return newState;
        default:
            return newState;
    }
};
const UploadListItem = ({ id, file, fileStatus, tooltip, uploadProgress, style, }) => {
    const [state, dispatch] = React.useReducer(reducer, reducerInitState);
    const { initialize, imgW, imgH } = state;
    if (initialize === Initialize.START) {
        const _URL = window.URL || window.webkitURL;
        const img = new Image();
        const objectUrl = _URL.createObjectURL(file);
        img.onload = function () {
            dispatch({ type: ACTION.INIT_SUCC, size: [img.width, img.height] });
            _URL.revokeObjectURL(objectUrl);
        };
        img.onerror = function () {
            dispatch({ type: ACTION.INIT_FAIL });
            _URL.revokeObjectURL(objectUrl);
        };
        img.src = objectUrl;
    }
    return (React.createElement(ListItem, { style: {
            ...style,
            height: 40,
            padding: "3px 10px 3px 20px",
            width: "-webkit-fill-available",
        }, id: id, label: file.name },
        initialize !== Initialize.SUCC ? null : (React.createElement(Text, { size: 0, style: {
                whiteSpace: "nowrap",
                backgroundColor: getTheme().colors.grayDrawer,
                borderRadius: 5,
                padding: "0 5px",
            }, children: `${imgW} x ${imgH} px` })),
        React.createElement(UploadItemStatusIcon, { fileStatus: fileStatus, tooltip: tooltip, spinnerProgress: uploadProgress })));
};
export default React.memo(UploadListItem);
