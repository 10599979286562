import * as React from "react";
import Btn from "@warda/library-ui/core/Btn";
import TextLoading from "@warda/library-ui/core/TextLoading";
import { createUseStyles } from "react-jss";
import Toolbar from "@warda/library-ui/core/Toolbar";
import Text from "@warda/library-ui/core/Text";
import Portal from "@warda/library-ui/core/Portal";
import classnames from "classnames";
import { getTheme } from "@warda/library-ui/theme";
import BorderShadow from "@warda/library-ui/core/BorderShadow";
import { DRAWER_WIDTH, HTML_ID_DRAWERS } from "../constants";
const useStyles = createUseStyles({
    "@keyframes enter": {
        "0%": {
            transform: `translateX(${DRAWER_WIDTH}px) translateY(0px)`,
        },
        "100%": {
            transform: "translateX(0px) translateY(0px)",
        },
    },
    drawer: {
        position: "relative",
        marginLeft: 10,
        background: getTheme().colors.grayDrawer,
        height: "inherit",
        width: DRAWER_WIDTH,
        display: "flex",
        flexDirection: "column",
        transform: "translateX(0px) translateY(0px)",
        animation: "$enter 250ms cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards",
    },
    drawerToolbar: {
        padding: 0,
    },
    drawerTitle: {
        flex: 1,
        marginLeft: 10,
    },
    drawerContent: {
        position: "relative",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowY: "auto",
    },
    drawerShadow: {
        top: "50px !important",
    },
});
const DialogDrawer = ({ className, style, open, loading, title, buttons, onClose, children, }) => {
    const scrollbarRef = React.useRef(null);
    const [scrolled, setScrolled] = React.useState(false);
    const classes = useStyles({});
    const cbOnScroll = React.useCallback((e) => {
        setScrolled(e.target.scrollTop !== 0);
    }, []);
    React.useEffect(() => {
        if (!!children && !!scrollbarRef.current) {
            setScrolled(false);
            scrollbarRef.current.scrollLeft = 0;
        }
    }, [children]);
    if (!open)
        return null;
    return (React.createElement(Portal, { node: document.getElementById(HTML_ID_DRAWERS) },
        React.createElement("div", { className: classes.drawer },
            React.createElement(Toolbar, { className: classes.drawerToolbar },
                React.createElement(Btn, { icon: "close", onClick: onClose }),
                React.createElement(Text, { weight: "bolder", className: classes.drawerTitle, ellipsis: true, children: title }),
                loading ? (React.createElement(TextLoading, null)) : ((buttons || []).map((b, i) => (React.createElement(Btn, { key: `${i}_button`, ...b, label: undefined }))))),
            React.createElement(BorderShadow, { open: scrolled, position: "top", className: classes.drawerShadow }),
            React.createElement("div", { ref: scrollbarRef, onScroll: cbOnScroll, style: style, className: classnames({
                    [classes.drawerContent]: true,
                    [className]: !!className,
                }), children: children }))));
};
export default DialogDrawer;
