import * as React from "react";
import { createUseStyles } from "react-jss";
import { isMobile } from "@warda/library-ui/utils/deviceUtils";
import FieldSearch from "@warda/library-ui/core/InputSearch";
import { ACT_VPORT } from "../reducer";
import { CtxDispatchViewport } from "../contexts";
const useStyles = createUseStyles({
    fieldSearch: {
        border: 0,
        borderRadius: 0,
        flex: 1,
        width: 0,
        height: "inherit",
        marginRight: "-4px",
        "& > input": {
            // dalla linee guida iOS l'input deve essere di 16px
            // altrimenti il dispositivo effettua uno zoom non desiderato
            fontSize: 16,
        },
    },
});
const FilterFileNameMobile = ({ value = "", showClose = true, autofocus = true, }) => {
    const classes = useStyles({});
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const cbOnCloseSearch = React.useCallback(() => {
        dispatchViewport({ type: ACT_VPORT.VIEW_MODE_NAVIGA });
    }, [dispatchViewport]);
    const cbOnFocusSearch = React.useCallback(() => {
        dispatchViewport({ type: ACT_VPORT.VIEW_MODE_SEARCH });
    }, [dispatchViewport]);
    const cbOnChangeFilter = React.useCallback((value) => {
        dispatchViewport({
            type: ACT_VPORT.FILTER,
            clearAll: false,
            index: 0,
            value,
        });
    }, [dispatchViewport]);
    if (!isMobile())
        return null;
    return (React.createElement(FieldSearch, { className: classes.fieldSearch, buttonInput: showClose
            ? {
                disabled: false,
                icon: "arrow_back",
                onClick: cbOnCloseSearch,
            }
            : {
                disabled: false,
                icon: "",
                onClick: cbOnFocusSearch,
            }, buttonClear: { small: false }, autofocus: autofocus, placeholder: "Search in Sharepage...", value: value, onChange: cbOnChangeFilter, onFocus: cbOnFocusSearch }));
};
export default FilterFileNameMobile;
