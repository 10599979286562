import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import Text from "@warda/library-ui/core/Text";
import Toolbar from "@warda/library-ui/core/Toolbar";
import Switch from "@warda/library-ui/core/Switch";
import InputSearch from "@warda/library-ui/core/InputSearch";
import MenuFiltersValued from "./MenuFiltersValued";
import BtnManager from "./BtnManager";
import SelectCond from "./SelectCond";
import { FILTERS_STICKY } from "../AreaProducts/constants";
import { getActiveFilters, getFilterSetData, FilterTypeCategory, setSlcSaveValues, } from "./utils";
import { CtxUserPref } from "../contexts";
import { onUpdateSet } from "../../api/fetchesApiJsonStore";
import getMngSetsSelected from "../../utils/getMngSetsSelected";
import { updateMngLevels } from "../AreaProducts/getMngLevels";
import { PREF_KEY } from "../../constants/userpref";
const PanelFiltersTitle = ({ filters, onFiltersChange, menuValuedOpen, onMenuAppliedOpen, mngSets, mngSetsOpen, mngSetsContext, mngSetsOnError, mngSetsOnChange, mngLevels, slcCondVisible, slcCond, onSlcCondChange, slcSave, slcSaveVisible, enableShared, enableLevels, enableSearch, onSearchInput, searchInput, }) => {
    const userPref = React.useContext(CtxUserPref);
    const filtersAttribute = filters
        .filter((f) => !FILTERS_STICKY.has(f.id))
        .filter((f) => f.type !== FilterTypeCategory);
    const filtersAttributeSet = new Set(filtersAttribute.map((f) => f.id));
    const filtersOthers = filters.filter((f) => !filtersAttributeSet.has(f.id));
    const onFilterClean = React.useCallback((filtersClean) => {
        onFiltersChange([].concat(filtersOthers, filtersClean));
    }, [filtersOthers, onFiltersChange]);
    const mngLevelsFilters = userPref[PREF_KEY.productsFiltersSetLevels];
    const levels = updateMngLevels(mngLevels, mngLevelsFilters);
    const filtersRendered = getActiveFilters({
        preferences: userPref,
        context: mngSetsContext,
        mngSets,
        filters,
    });
    const onSlcSaveChange = React.useCallback((b) => {
        setSlcSaveValues(b);
        const { slcSetId, slcSet } = getMngSetsSelected(mngSetsContext, userPref, mngSets);
        if (!!slcSetId) {
            const newSet = {
                ...slcSet,
                payload: {
                    ...slcSet.payload,
                    items: slcSet.payload.items.map((s) => {
                        const filter = filters.find((f) => f.id === s.id);
                        return getFilterSetData(filter, b);
                    }),
                },
            };
            const newSets = mngSets.map((s) => {
                if (s.id !== slcSetId)
                    return s;
                return newSet;
            });
            onUpdateSet(mngSetsContext, newSet);
            mngSetsOnChange({
                context: mngSetsContext,
                slcId: slcSetId,
                newId: false,
                sets: newSets,
                keepValue: b,
                updateFilters: false,
            });
        }
    }, [filters, mngSets, mngSetsContext, mngSetsOnChange, userPref]);
    if (!filters.length)
        return null;
    return (React.createElement(Toolbar, null,
        React.createElement(Text, { weight: "bolder", style: { marginRight: 5 }, children: "Filters" }),
        React.createElement(MenuFiltersValued, { filters: filtersAttribute, onFiltersChange: onFilterClean, open: menuValuedOpen, setOpen: onMenuAppliedOpen }),
        React.createElement("div", { style: { flex: 1 } }),
        !slcSaveVisible || !filtersRendered.length || !mngSets ? null : (React.createElement(Switch, { style: { marginRight: 5 }, checked: slcSave, color: getTheme().colors.theme1, tooltip: "Apply current filters' value on next page access", icon: "save", onChange: onSlcSaveChange })),
        !filtersRendered.length || !slcCondVisible ? null : (React.createElement(SelectCond, { style: { marginRight: 10 }, value: slcCond, onChange: onSlcCondChange })),
        !mngSets ? null : (React.createElement(BtnManager, { open: mngSetsOpen, filters: filtersAttribute, mngSets: mngSets, context: mngSetsContext, onError: mngSetsOnError, onChange: mngSetsOnChange, levels: levels, enableShared: enableShared, enableLevels: enableLevels })),
        !enableSearch ? null : (React.createElement(InputSearch, { placeholder: "Search filter...", style: { width: 150 }, value: searchInput, onChange: onSearchInput }))));
};
export default PanelFiltersTitle;
