import { PointSelector } from "@warda/library-ui/core/ImageAnnotation";
import { SegmentedViewType } from "@warda/library-ui/core/SegmentedView";
import { GridMedia } from "../interfaces";
import { ThemeId } from "./colors";
export var ZOOM_COLORS_KEY;
(function (ZOOM_COLORS_KEY) {
    ZOOM_COLORS_KEY["BLACK"] = "BLACK";
    ZOOM_COLORS_KEY["GRAY1"] = "GRAY1";
    ZOOM_COLORS_KEY["GRAY2"] = "GRAY2";
    ZOOM_COLORS_KEY["WHITE"] = "WHITE";
})(ZOOM_COLORS_KEY || (ZOOM_COLORS_KEY = {}));
export const ZOOM_COLORS = {
    [ZOOM_COLORS_KEY.BLACK]: {
        label: "Total Black",
        blackIcons: true,
        background: "#000000",
    },
    [ZOOM_COLORS_KEY.GRAY1]: {
        label: "Dark Gray",
        blackIcons: true,
        background: "#303030",
    },
    [ZOOM_COLORS_KEY.GRAY2]: {
        label: "Light Gray",
        blackIcons: false,
        background: "#d8d8d8",
    },
    [ZOOM_COLORS_KEY.WHITE]: {
        label: "Total White",
        blackIcons: false,
        background: "#ffffff",
    },
};
export var PREF_KEY;
(function (PREF_KEY) {
    PREF_KEY["filterType"] = "filterType";
    PREF_KEY["filterCtlg"] = "filterCtlg";
    PREF_KEY["filterLang"] = "filterLang";
    PREF_KEY["detailAnnotationsShow"] = "detailAnnotationsShow";
    PREF_KEY["detailAnnotationsType"] = "detailAnnotationsType";
    PREF_KEY["detailBackgroundColor"] = "detailBackgroundColor";
    PREF_KEY["detailEditingAttrCtlgs"] = "detailEditingAttrCtlgs";
    PREF_KEY["detailEditingAttrLangs"] = "detailEditingAttrLangs";
    PREF_KEY["categoryDrawerExpanded"] = "categoryDrawerExpanded";
    PREF_KEY["categoryFilterSelected"] = "categoryFilterSelected";
    PREF_KEY["gridPreviewMedias"] = "gridPreviewMedias";
    PREF_KEY["productsFiltersSetLevels"] = "productsFiltersSetLevels";
    PREF_KEY["productsColumnsSetLevels"] = "productsColumnsSetLevels";
    PREF_KEY["productsLayout"] = "productsLayout";
    PREF_KEY["medialibLayout"] = "medialibLayout";
    PREF_KEY["jsonstoreContext"] = "jsonstoreContext";
    PREF_KEY["themeId"] = "themeId";
})(PREF_KEY || (PREF_KEY = {}));
export var ASSIGNEES_SORTS_KEY;
(function (ASSIGNEES_SORTS_KEY) {
    ASSIGNEES_SORTS_KEY["fullname"] = "fullname";
    ASSIGNEES_SORTS_KEY["frequency"] = "frequency";
})(ASSIGNEES_SORTS_KEY || (ASSIGNEES_SORTS_KEY = {}));
export var JsonstoreContext;
(function (JsonstoreContext) {
    JsonstoreContext["userPreferences"] = "user-preferences";
    JsonstoreContext["productsSetsColumns"] = "catalog_grid_columns";
    JsonstoreContext["productsSetsFilters"] = "catalog_grid_filters";
    JsonstoreContext["productsSetsAttribs"] = "catalog_grid_fields_panel_details";
    JsonstoreContext["medialibSetsFilters"] = "medialibrary_filters";
    JsonstoreContext["stagingSetsColumns"] = "stagingarea_grid_columns";
    JsonstoreContext["stagingSetsFilters"] = "stagingarea_grid_filters";
    JsonstoreContext["productsReadyAttrs"] = "bulk_ready_attributes";
    JsonstoreContext["productsExportXlsx"] = "export_xlsx";
})(JsonstoreContext || (JsonstoreContext = {}));
export var JsonstoreAction;
(function (JsonstoreAction) {
    JsonstoreAction["GET"] = "GET";
    JsonstoreAction["CREATE"] = "CREATE";
    JsonstoreAction["DELETE"] = "DELETE";
    JsonstoreAction["UPDATE"] = "UPDATE";
})(JsonstoreAction || (JsonstoreAction = {}));
export const USER_PREF_DEFAULT = {
    [PREF_KEY.filterType]: "",
    [PREF_KEY.filterCtlg]: "",
    [PREF_KEY.filterLang]: "",
    [PREF_KEY.detailAnnotationsShow]: false,
    [PREF_KEY.detailAnnotationsType]: PointSelector.TYPE,
    [PREF_KEY.detailBackgroundColor]: ZOOM_COLORS_KEY.BLACK,
    [PREF_KEY.detailEditingAttrCtlgs]: [],
    [PREF_KEY.detailEditingAttrLangs]: [],
    [PREF_KEY.categoryDrawerExpanded]: false,
    [PREF_KEY.categoryFilterSelected]: "",
    [PREF_KEY.gridPreviewMedias]: GridMedia.LAST_UPLOAD,
    [PREF_KEY.productsFiltersSetLevels]: [],
    [PREF_KEY.productsColumnsSetLevels]: [],
    [PREF_KEY.productsLayout]: SegmentedViewType.GRID,
    [PREF_KEY.medialibLayout]: SegmentedViewType.LIST,
    [PREF_KEY.jsonstoreContext]: {},
    [PREF_KEY.themeId]: ThemeId.SOFT_PLUM,
};
