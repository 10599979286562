import { THEME_DEFAULT, THEME_COLORS_SOFT, THEME_COLORS_DARK, getTheme, } from "@warda/library-ui/theme";
export var ThemeId;
(function (ThemeId) {
    ThemeId["SOFT_PLUM"] = "SOFT_PLUM";
    ThemeId["SOFT_BLUE"] = "SOFT_BLUE";
    ThemeId["SOFT_PINK"] = "SOFT_PINK";
    ThemeId["SOFT_AQUA"] = "SOFT_AQUA";
    ThemeId["DARK_PLUM"] = "DARK_PLUM";
    ThemeId["DARK_BLUE"] = "DARK_BLUE";
    ThemeId["DARK_PINK"] = "DARK_PINK";
    ThemeId["DARK_AQUA"] = "DARK_AQUA";
})(ThemeId || (ThemeId = {}));
export var ThemeIdMode;
(function (ThemeIdMode) {
    ThemeIdMode["SOFT"] = "SOFT";
    ThemeIdMode["DARK"] = "DARK";
})(ThemeIdMode || (ThemeIdMode = {}));
export var ThemeIdPalette;
(function (ThemeIdPalette) {
    ThemeIdPalette["PLUM"] = "PLUM";
    ThemeIdPalette["BLUE"] = "BLUE";
    ThemeIdPalette["PINK"] = "PINK";
    ThemeIdPalette["AQUA"] = "AQUA";
})(ThemeIdPalette || (ThemeIdPalette = {}));
export const THEME_LABEL_MODE = {
    [ThemeIdMode.SOFT]: "LIGHT",
    [ThemeIdMode.DARK]: "DARK",
};
export const THEME_LABEL_PALETTE = {
    [ThemeIdPalette.PLUM]: "Royal Velvet",
    [ThemeIdPalette.BLUE]: "Electric Denim",
    [ThemeIdPalette.PINK]: "Vibrant Silk",
    [ThemeIdPalette.AQUA]: "Dream Cashmere",
};
export const THEME_COLORS_PALETTE = {
    [ThemeIdPalette.PLUM]: ["#C07CF1", "#002BFF"],
    [ThemeIdPalette.BLUE]: ["#2962ff", "#febc0c"],
    [ThemeIdPalette.PINK]: ["#ff3b7f", "#00ce4f"],
    [ThemeIdPalette.AQUA]: ["#00BFA5", "#b43bc9"],
};
export const THEMES = Object.values(ThemeId).map((id) => {
    const [themeMode, themePalette] = id.split("_");
    const colorsMode = themeMode === ThemeIdMode.DARK ? THEME_COLORS_DARK : THEME_COLORS_SOFT;
    const [theme1, theme2] = THEME_COLORS_PALETTE[themePalette];
    const colors = { ...colorsMode, theme1, theme2 };
    return {
        ...THEME_DEFAULT,
        id,
        version: 4,
        fonts: [
            {
                family: "Roboto",
                weight: [300, 400, 700],
                source: "https://fonts.gstatic.com/s/roboto/v32/KFOiCnqEu92Fr1Mu51QrEz0dL-vwnYh2eg.woff2",
            },
        ],
        colors,
    };
});
export const createThemeId = (m, p) => {
    return (m + "_" + p);
};
export const getThemeTuple = (id) => {
    try {
        return id.split("_");
    }
    catch {
        return [undefined, undefined];
    }
};
export const getThemeTupleCurrent = () => {
    return getThemeTuple(getTheme().id);
};
export const COLORS = [
    "#d50000",
    "#33b679",
    "#039be5",
    "#f6bf26",
    "#f4511e",
    "#e67c73",
    "#7986cb",
    "#0b8043",
    "#3f51b5",
    "#8e24aa", // vinaccia    -viola scuro
];
