import * as extensions from "@warda/library-ui/utils/extensions.json";
import * as MIMEType from "whatwg-mimetype";
const getFileExtension = (mimeType) => {
    if (!mimeType)
        return "Unknown";
    const keyExt = Object.keys(extensions).find((k) => {
        return extensions[k].mime === mimeType;
    });
    return !!keyExt ? keyExt.toLocaleUpperCase() : new MIMEType(mimeType).type;
};
export default getFileExtension;
