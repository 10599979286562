import * as React from "react";
import Btn from "@warda/library-ui/core/Btn";
import { getTheme } from "@warda/library-ui/theme";
import UploadItemStatus from "./UploadItemStatus";
import Spinner from "../ModalUpload/parts/Spinner";
const IconStatusFile = ({ fileStatus, tooltip, spinnerProgress = 0, }) => {
    const i = {
        small: true,
        variant: "bold",
        tooltip,
        style: { margin: "0 4px 0 6px" },
    };
    switch (fileStatus) {
        case UploadItemStatus.Waiting:
        case UploadItemStatus.ReadyToUpload:
            return React.createElement(Spinner, null);
        case UploadItemStatus.S3Uploading:
            return React.createElement(Spinner, { value: spinnerProgress });
        case UploadItemStatus.AwaitingConfirmation:
            return React.createElement(Spinner, { value: 100 });
        case UploadItemStatus.Completed:
            i.color = getTheme().colors.msgSucc;
            return React.createElement(Btn, { ...i, icon: "check" });
        case UploadItemStatus.Error:
            i.color = getTheme().colors.msgFail;
            return React.createElement(Btn, { ...i, icon: "warning" });
        case UploadItemStatus.Abort:
            return React.createElement(Btn, { ...i, icon: "cancel" });
        default:
            return null;
    }
};
export default IconStatusFile;
