import PERMS from "./permissions";
import { AreaType } from "../interfaces";
import { AREA_PRODUCTS, AREA_COMPLETENESS, AREA_MEDIA_LIBRARY, AREA_MEDIA_METADATA, AREA_PRODUCTS_CONFIG, AREA_USAGESTATS, AREA_USERMNG_M2M, AREA_USERMNG_PORTAL, AREA_USERMNG_SC, } from "./keys";
export const SECTIONS = [
    {
        perm: [PERMS.access_product_area],
        view: AREA_PRODUCTS,
        type: AreaType.AREA,
        icon: "article",
        text: "Products",
    },
    {
        perm: [PERMS.access_completeness],
        view: AREA_COMPLETENESS,
        type: AreaType.AREA,
        icon: "view_timeline",
        text: "Completeness",
    },
    {
        perm: [PERMS.access_consumption],
        view: AREA_USAGESTATS,
        type: AreaType.AREA,
        icon: "insert_chart",
        text: "Usage Stats",
    },
    {
        perm: [PERMS.access_medialibrary_area, PERMS.access_staging_area],
        view: AREA_MEDIA_LIBRARY,
        type: AreaType.AREA,
        icon: "folder",
        text: "Media Library",
    },
    {
        perm: [
            PERMS.access_users_config,
            PERMS.access_roles_area,
            PERMS.access_permissions,
        ],
        view: AREA_USERMNG_SC,
        type: AreaType.CONF,
        icon: "people_alt",
        text: "SeeCommerce User Management",
    },
    {
        perm: [PERMS.access_portals_admin_area],
        view: AREA_USERMNG_PORTAL,
        type: AreaType.CONF,
        icon: "supervised_user_circle",
        text: "Portal User Management",
    },
    {
        perm: [PERMS.access_m2m, PERMS.access_permissions],
        view: AREA_USERMNG_M2M,
        type: AreaType.CONF,
        icon: "keyboard_command_key",
        text: "M2M User Management",
    },
    {
        perm: [PERMS.access_tenant_config],
        view: AREA_PRODUCTS_CONFIG,
        type: AreaType.CONF,
        icon: "my_library_books",
        text: "Products Configuration",
    },
    {
        perm: [PERMS.access_media_attrs_config],
        view: AREA_MEDIA_METADATA,
        type: AreaType.CONF,
        icon: "photo_library",
        text: "Media Metadata",
    },
];
