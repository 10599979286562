import * as React from "react";
import isEmpty from "lodash-es/isEmpty";
import { createUseStyles } from "react-jss";
import { Service } from "@warda/library-ui/contexts/AppWebSocketContext";
import { ACTIONS, reducer, reducerInitState } from "./reducer";
import ModalUploads from "../ModalUpload/ModalUploads";
import { getSignedUrl, mcrConfirm } from "../../../api/fetchesApiProducts";
import apiUrls from "../../../api/apiUrlsProducts";
import areAnyNonFinal from "../uploadItemStatus/areAnyNonFinal";
const useStyles = createUseStyles({
    multiUploads: {
        bottom: 75,
    },
});
const UploadsSC = ({ uploads }) => {
    const classes = useStyles({});
    const [state, dispatch] = React.useReducer(reducer, reducerInitState);
    React.useEffect(() => {
        if (!isEmpty(uploads)) {
            dispatch({
                type: ACTIONS.ADD_ITEMS,
                uploads,
            });
        }
    }, [uploads]);
    const { expanded, items, msgStopUpload } = state;
    const toggleExpanded = React.useCallback(() => {
        dispatch({ type: ACTIONS.EXPANDED });
    }, []);
    const onCloseUploads = React.useCallback(() => {
        if (areAnyNonFinal(items)) {
            dispatch({ type: ACTIONS.MSG_OPEN });
        }
        else {
            dispatch({ type: ACTIONS.RESET });
        }
    }, [items]);
    const onContinue = React.useCallback(() => {
        dispatch({ type: ACTIONS.MSG_CLOSE });
    }, []);
    const onStopUploads = React.useCallback(() => {
        dispatch({ type: ACTIONS.RESET });
    }, []);
    const onPutS3File = React.useCallback(async (item, fileId) => {
        await mcrConfirm(fileId, Service.SC, item);
    }, []);
    const onUpdateFileStatus = React.useCallback((item) => {
        dispatch({
            type: ACTIONS.SET_ITEMS,
            item,
        });
    }, []);
    if (isEmpty(items))
        return null;
    return (React.createElement(ModalUploads, { className: classes.multiUploads, items: items, getSignedUrl: getSignedUrl(apiUrls.newUploadSC), onPutS3File: onPutS3File, uploadRequestHeaders: {}, expanded: expanded, onCloseToolbar: onCloseUploads, onExpand: toggleExpanded, onUpdateFileStatus: onUpdateFileStatus, msgStopUpload: msgStopUpload, onContinue: onContinue, onStopUploads: onStopUploads }));
};
export default UploadsSC;
