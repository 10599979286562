import { FileType } from "@warda/library-ui/interfaces";
import getFileIcon from "@warda/library-ui/utils/getFileIcon";
import { FileAccess, SheetLayout, } from "../../../../interfaces";
import { getActions, getSelectAction } from "../getActions";
import { getQueryParams, updateSearchString } from "../../searchString";
import { AREA } from "../../constants";
import { ACT_VPORT } from "../reducer";
import getFileAccess from "../utils/getFileAccess";
export const itemsTypeSort = [FileType.FOLDER, FileType.MEDIA];
export const getFileTypeText = (type) => {
    switch (type) {
        case FileType.PORTAL:
            return "Portals";
        case FileType.FOLDER:
            return "Folders";
        case FileType.MEDIA:
            return "Media";
        default:
            return "Undefined";
    }
};
export const needHeader = {
    [FileType.FOLDER]: 1,
    [FileType.MEDIA]: 1,
    [FileType.PORTAL]: 1,
};
export const needFooter = (files, filesTotal) => {
    return {
        [FileType.FOLDER]: needPagination({
            type: FileType.FOLDER,
            files,
            filesTotal,
        }).pagination
            ? 2
            : 1,
        [FileType.MEDIA]: needPagination({
            type: FileType.MEDIA,
            files,
            filesTotal,
        }).pagination
            ? 2
            : 1,
        [FileType.PORTAL]: needPagination({
            type: FileType.PORTAL,
            files,
            filesTotal,
        }).pagination
            ? 2
            : 1,
    };
};
export const needPagination = ({ type, files, filesTotal, }) => {
    const countTotal = filesTotal[type];
    const countFiles = files.filter((f) => f.type === type).length;
    return {
        countTotal,
        countFiles,
        pagination: countFiles < countTotal,
    };
};
export const getAssetPropsFolder = ({ dispatchViewport, color, id, data, type, selected, selectedIds, selectedTypes, loading, onContextMenu, contextmenuOriginAnchor, contextmenuOriginTransf, contextmenuPosizionZone, permissions, queryHistory, querySearch, accessInherited, userId, }) => {
    const { area } = getQueryParams();
    const { name, owner, creator, access, deleteRequested } = data;
    const onClick = (e, { keyDownCtrl, keyDownMeta }) => {
        dispatchViewport({
            type: ACT_VPORT.SELECT_FILE_CLICK,
            id,
            keyDownCtrl,
            keyDownMeta,
        });
    };
    const onCheck = (e, { keyDownMeta }) => {
        dispatchViewport({
            type: ACT_VPORT.SELECT_FILE_CHECK,
            id,
            keyDownCtrl: true,
            keyDownMeta,
        });
    };
    const onNavigate = () => {
        dispatchViewport({ type: ACT_VPORT.NAVIGATION });
        updateSearchString({
            history: queryHistory,
            search: querySearch,
            path: id,
        });
    };
    const contextmenu = getActions({
        dispatchViewport,
        area,
        select: getSelectAction(selectedTypes, selected, type),
        access: getFileAccess({
            area,
            userId,
            owner,
            creator,
            access,
            accessInherited,
        }),
        disabled: loading,
        permissions,
    });
    return {
        contextmenu,
        contextmenuOriginAnchor,
        contextmenuOriginTransf,
        contextmenuPosizionZone,
        onContextMenu,
        onDoubleClick: onNavigate,
        onClick,
        onCheck,
        color,
        selected,
        icon: deleteRequested ? "folder_off" : "folder",
        label: name,
        checkbox: !!selectedIds.length ? "mousehover" : "none",
        disabled: deleteRequested,
    };
};
export const getAssetPropsMedia = ({ dispatchViewport, color, id, data, type, selected, selectedIds, selectedTypes, loading, onContextMenu, contextmenuOriginAnchor, contextmenuOriginTransf, contextmenuPosizionZone, permissions, accessInherited, userId, getMediaKind, }) => {
    const { area } = getQueryParams();
    const { name, owner, creator, access, deleteRequested, mimeType } = data;
    const icon = getFileIcon(mimeType, getMediaKind);
    const onClick = (e, { keyDownCtrl, keyDownMeta }) => {
        dispatchViewport({
            type: ACT_VPORT.SELECT_FILE_CLICK,
            id,
            keyDownCtrl,
            keyDownMeta,
        });
    };
    const onCheck = (e, { keyDownMeta }) => {
        dispatchViewport({
            type: ACT_VPORT.SELECT_FILE_CHECK,
            id,
            keyDownCtrl: true,
            keyDownMeta,
        });
    };
    const onDetail = () => {
        dispatchViewport({
            type: ACT_VPORT.SELECT_FILE_CLICK,
            id,
            keyDownCtrl: false,
            keyDownMeta: false,
            detailLayout: SheetLayout.MEDIA_ZOOM,
        });
    };
    const contextmenu = getActions({
        dispatchViewport,
        area,
        select: getSelectAction(selectedTypes, selected, type),
        access: getFileAccess({
            area,
            userId,
            owner,
            creator,
            access,
            accessInherited,
        }),
        disabled: loading,
        permissions,
    });
    return {
        contextmenu,
        contextmenuOriginAnchor,
        contextmenuOriginTransf,
        contextmenuPosizionZone,
        onContextMenu,
        onDoubleClick: onDetail,
        onClick,
        onCheck,
        color,
        selected,
        icon,
        label: name,
        checkbox: !!selectedIds.length ? "mousehover" : "none",
        disabled: deleteRequested,
    };
};
export const getAssetPropsPortal = ({ dispatchViewport, color, id, data, type, selected, selectedIds, selectedTypes, loading, onContextMenu, contextmenuOriginAnchor, contextmenuOriginTransf, contextmenuPosizionZone, permissions, queryHistory, querySearch, }) => {
    const { area } = getQueryParams();
    const { name } = data;
    const onClick = (e, { keyDownCtrl, keyDownMeta }) => {
        dispatchViewport({
            type: ACT_VPORT.SELECT_FILE_CLICK,
            id,
            keyDownCtrl,
            keyDownMeta,
        });
    };
    const onCheck = (e, { keyDownMeta }) => {
        dispatchViewport({
            type: ACT_VPORT.SELECT_FILE_CHECK,
            id,
            keyDownCtrl: true,
            keyDownMeta,
        });
    };
    const onNavigate = () => {
        dispatchViewport({ type: ACT_VPORT.NAVIGATION });
        updateSearchString({
            history: queryHistory,
            search: querySearch,
            area: AREA.PORTAL,
            path: id + "_0",
        });
    };
    const contextmenu = getActions({
        dispatchViewport,
        area,
        select: getSelectAction(selectedTypes, selected, type),
        access: FileAccess.ADMIN,
        disabled: loading,
        permissions,
    });
    return {
        contextmenu,
        contextmenuOriginAnchor,
        contextmenuOriginTransf,
        contextmenuPosizionZone,
        onContextMenu,
        onDoubleClick: onNavigate,
        onClick,
        onCheck,
        color,
        selected,
        icon: "cast_connected",
        label: name,
        checkbox: !!selectedIds.length ? "mousehover" : "none",
        disabled: false,
    };
};
