import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { NotificationType } from "@warda/library-ui/contexts/AppWebSocketContext";
import { createUseStyles } from "react-jss";
import CardAssignment from "./CardAssignment";
import CardMentionImgSc from "./CardMentionImgSc";
import CardImport from "./CardImport";
import CardNoType from "./CardNoType";
import CardTheme from "./CardTheme";
import CardPortalSignup from "./CardPortalSignup";
const useStyles = createUseStyles({
    card: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        position: "relative",
        backgroundColor: getTheme().colors.background,
        "&:hover": {
            backgroundColor: getTheme().colors.mousehover,
        },
        "&:hover $btnDelete": {
            transform: "scale(1)",
        },
        "&:hover $btnCopy": {
            transform: "scale(1)",
        },
    },
    cardTitle: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "5px 15px",
    },
    cardContent: {
        margin: "0 38px 5px 78px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        minHeight: 20,
        position: "relative",
        opacity: ({ read }) => (!read ? 1 : 0.5),
    },
    textTitle: {
        color: ({ read }) => !read ? getTheme().colors.typography : getTheme().colors.disable,
        flex: 1,
    },
    textDate: {
        color: ({ read }) => !read ? getTheme().colors.typography : getTheme().colors.disable,
        marginRight: 5,
    },
    btnDelete: {
        transform: "scale(0)",
    },
    btnCopy: {
        transform: "scale(0)",
    },
});
const Card = (p) => {
    const { type, markedAsRead } = p.data;
    const c = useStyles({ read: markedAsRead });
    return (React.createElement("div", { className: c.card }, (() => {
        switch (type) {
            case NotificationType.PORTAL_USER_SIGN_UP_NOTIFICATION_REQUEST:
                return React.createElement(CardPortalSignup, { cardClasses: c, ...p });
            case NotificationType.THEME:
                return React.createElement(CardTheme, { cardClasses: c, ...p });
            case NotificationType.MENTION_IMG_SC:
                return React.createElement(CardMentionImgSc, { cardClasses: c, ...p });
            case NotificationType.ASSIGNMENT:
                return React.createElement(CardAssignment, { cardClasses: c, ...p });
            case NotificationType.IMPORT:
                return React.createElement(CardImport, { cardClasses: c, ...p });
            default:
                return React.createElement(CardNoType, { cardClasses: c, ...p });
        }
    })()));
};
export default Card;
