import * as React from "react";
import { createUseStyles } from "react-jss";
import Btn from "@warda/library-ui/core/Btn";
import Text from "@warda/library-ui/core/Text";
import { getTheme } from "@warda/library-ui/theme";
import getFilterChips from "./getFilterChips";
import FilterChip from "./FilterChip";
const useStyle = createUseStyles({
    menuitem: {
        marginBottom: 10,
    },
    menuitemTitle: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "-webkit-fill-available",
        borderRadius: getTheme().borderRadius,
        color: ({ color }) => color,
    },
    menuitemTitleLabel: {
        margin: "10px 5px",
    },
    menuitemTitleExpand: {
        margin: 0,
    },
});
const MenuFiltersValuedItem = ({ color, filter, onClickChips, onClickClear, }) => {
    const classes = useStyle({ color });
    const onClear = React.useCallback(() => {
        onClickClear([filter]);
    }, [filter, onClickClear]);
    const { id, label } = filter;
    return (React.createElement("div", { className: classes.menuitem },
        React.createElement("div", { className: classes.menuitemTitle },
            React.createElement(Text, { ellipsis: true, weight: "bolder", className: classes.menuitemTitleLabel, children: label }),
            React.createElement("div", { style: { flex: 1 } }),
            React.createElement(Btn, { selected: true, variant: "bold", label: "Clear", onClick: onClear, small: true, color: color })),
        getFilterChips(filter).map((c) => (React.createElement(FilterChip, { key: c.id, filterId: id, onClick: onClickChips, color: color, ...c })))));
};
export default MenuFiltersValuedItem;
