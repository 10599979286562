import * as React from "react";
import { useAsyncAbortable } from "react-async-hook";
import useConstant from "use-constant";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { FileType } from "@warda/library-ui/interfaces";
import { SearchMode } from "../../interfaces";
import { SEARCH_FILES_RESULT_DEFAULT, } from "../../api/fetchesApiMediaLibrary";
import { AREA } from "../AreaMediaLibrary/constants";
import { anonymousSearchFiles, } from "../../api/fetchesApiAnonymous";
import { areFiltersUseful } from "../PanelFilters";
import { getQuery } from "../AreaProducts/useSearchEs";
const getSize = (type) => {
    switch (type) {
        case FileType.PORTAL:
        case FileType.FOLDER:
            return 50;
        case FileType.MEDIA:
            return 1000;
        default:
            return 10;
    }
};
export const searchFiles = async ({ type, hrch, prnt, sortKey, sortOrd, filters, filtersCondition, from = 0, }) => {
    const createSearchEs = (fileType) => {
        const isExcludedType = !(!type || type === fileType);
        return {
            from: isExcludedType ? 0 : from,
            size: isExcludedType ? 0 : getSize(fileType),
            sort: [{ [sortKey]: { order: sortOrd, unmapped_type: "keyword" } }],
            query: getQuery({
                filters,
                filtersCondition,
                conditionsAdditional: [
                    { bool: { must: { term: { deleted: false } } } },
                ],
            }),
            track_total_hits: true,
        };
    };
    const searchEsFolder = createSearchEs(FileType.FOLDER);
    const searchEsMedia = createSearchEs(FileType.MEDIA);
    const conf = {
        area: AREA.SHAREPAGE,
        hrch,
        prnt,
        searchMode: areFiltersUseful(filters)
            ? SearchMode.HIERARCHY
            : SearchMode.DEFAULT,
        searchEsFolder,
        searchEsMedia,
    };
    const res = await anonymousSearchFiles(conf);
    return res;
};
const useSearchEs = (a) => {
    const [searchTime, setSearchTime] = React.useState(null);
    const debounceSearchDocs = useConstant(() => AwesomeDebouncePromise(searchFiles, 300));
    const searchRes = useAsyncAbortable(async (abortSignal, argDocs) => {
        if (argDocs === null)
            return SEARCH_FILES_RESULT_DEFAULT;
        return debounceSearchDocs(a);
    }, [searchTime]);
    return {
        searchTime,
        setSearchTime,
        searchRes,
    };
};
export default useSearchEs;
