import bytesFormat from "@warda/library-ui/utils/bytesFormat";
import UploadItemStatus from "../uploadItemStatus/UploadItemStatus";
import isFinalState from "../uploadItemStatus/isFinalState";
const getWorkingIds = (items) => {
    return new Set(items
        .filter((item) => !isFinalState(item.status))
        .map((item) => item.id));
};
const itemsAdd = ({ items, filesToAdd, maxSize, }) => {
    const newItems = [].concat(items);
    const workingIds = getWorkingIds(newItems);
    filesToAdd.forEach((fileToAdd) => {
        const { id, file } = fileToAdd;
        const b1 = bytesFormat(file.size);
        const b2 = bytesFormat(maxSize);
        const exceedSize = file.size > maxSize;
        if (maxSize !== -1 && exceedSize) {
            fileToAdd.status = UploadItemStatus.Error;
            fileToAdd.tooltip = `The ${b1} size of the file is greater than the maximum allowed (${b2})`;
        }
        if (workingIds.has(id)) {
            fileToAdd.status = UploadItemStatus.Error;
            fileToAdd.tooltip = `The same file is still being uploaded`;
        }
        else {
            workingIds.add(id);
        }
        newItems.push(fileToAdd);
    });
    return newItems;
};
export default itemsAdd;
