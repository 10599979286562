const checkFiles = (files, mimeTypes) => {
    return files
        .filter((f) => {
        return !!f.name && !f.name.startsWith(".");
    })
        .filter((f) => {
        if (mimeTypes.length === 0)
            return true;
        return !!f.type && mimeTypes.some((t) => t === f.type);
    });
};
export default checkFiles;
