import { NotificationType, Service, } from "@warda/library-ui/contexts/AppWebSocketContext";
import { ACTION } from "./reducer";
const jobType = new Set([
    NotificationType.MULTI_PRODUCT_EDITING,
    NotificationType.MEDIA_CONF_UPDATE,
    NotificationType.MEDIA_ATTR_UPDATE,
]);
export const getNotificationsId = (itemsWs) => {
    const idsDrc = [];
    const idsJob = [];
    itemsWs
        .filter((c) => !c.isError)
        .filter((c) => c.service === Service.NOTIFIER)
        .forEach((c) => {
        const id = c.payload.uuid || c.payload.key; // qui non c'è allineamento lato BE
        if (jobType.has(c.notificationType)) {
            idsJob.push(id);
        }
        else {
            idsDrc.push(id);
        }
    });
    return {
        idsDrc,
        idsJob,
    };
};
const websocketUpdateDrc = async (itemsWs, dispatch) => {
    const { idsDrc: dir } = getNotificationsId(itemsWs);
    if (!!dir.length)
        dispatch({ type: ACTION.DRC_NEWITEMS_TO_GET, ids: dir });
};
const wsCallbacks = [
    {
        id: "notificationsDrc",
        callback: websocketUpdateDrc,
    },
];
export default wsCallbacks;
