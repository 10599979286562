import * as React from "react";
import isEmpty from "lodash-es/isEmpty";
import { FileType } from "@warda/library-ui/interfaces";
import { ACT_VPORT } from "./reducer";
import InputML from "./InputML";
import PERMS from "../../../constants/permissions";
import hasPerms from "../../../utils/hasPerms";
import { FileAccess } from "../../../interfaces";
import { AREA } from "../constants";
import isPathMinusOne from "./utils/isPathMinusOne";
export const getSelectAction = (selectedTypes, selected, itemType) => {
    if (!selected)
        return [itemType];
    return selectedTypes;
};
var SlcMode;
(function (SlcMode) {
    SlcMode["LEAST_ONE"] = "LEAST_ONE";
    SlcMode["UNSELECT"] = "UNSELECT";
    SlcMode["SINGLE"] = "SINGLE";
    SlcMode["MASSIVE"] = "MASSIVE";
})(SlcMode || (SlcMode = {}));
export var ActionId;
(function (ActionId) {
    ActionId["ACCESS_PORTAL"] = "ACCESS_PORTAL";
    ActionId["CREATE_PORTAL"] = "CREATE_PORTAL";
    ActionId["CREATE_FOLDER"] = "CREATE_FOLDER";
    ActionId["UPLOAD_MEDIA"] = "UPLOAD_MEDIA";
    ActionId["UPLOAD_FOLDER"] = "UPLOAD_FOLDER";
    ActionId["RENAME_FOLDER"] = "RENAME_FOLDER";
    ActionId["RENAME_MEDIA"] = "RENAME_MEDIA";
    ActionId["MOVE"] = "MOVE";
    ActionId["ACCESSES"] = "ACCESSES";
    ActionId["SHAREPAGE"] = "SHAREPAGE";
    ActionId["CDN_LINKS"] = "CDN_LINKS";
    ActionId["DELETE"] = "DELETE";
    ActionId["DOWNLOAD"] = "DOWNLOAD";
    ActionId["APPLY_ATTRIBUTE_SET"] = "APPLY_ATTRIBUTE_SET";
})(ActionId || (ActionId = {}));
const ACTIONS = [
    {
        id: ActionId.ACCESS_PORTAL,
        area: [AREA.PORTALS_LIST],
        icon: "group_add",
        label: "Portal Access",
        slcMode: SlcMode.SINGLE,
        slcType: [FileType.PORTAL],
        access: [FileAccess.ADMIN],
        permis: PERMS.medialibrary_touchpoint_manager,
        action: ACT_VPORT.MODAL_ACCESS,
    },
    {
        id: ActionId.CREATE_PORTAL,
        area: [AREA.PORTALS_LIST],
        icon: "cast_connected",
        label: "Create portal",
        slcMode: SlcMode.UNSELECT,
        slcType: [],
        access: [FileAccess.ADMIN],
        permis: PERMS.medialibrary_touchpoint_manager,
        action: ACT_VPORT.MODAL_CREATE_PORTAL,
    },
    {
        id: ActionId.CREATE_FOLDER,
        area: [AREA.MYFILES, AREA.CORPORATE, AREA.PORTAL, AREA.SHARED_WITH_ME],
        icon: "create_new_folder",
        label: "Create folder",
        slcMode: SlcMode.UNSELECT,
        slcType: [],
        access: [FileAccess.ADMIN, FileAccess.WRITE],
        permis: PERMS.medialibrary_manage_content,
        action: ACT_VPORT.MODAL_CREATE_FOLDER,
    },
    {
        id: ActionId.UPLOAD_MEDIA,
        area: [AREA.MYFILES, AREA.CORPORATE, AREA.PORTAL, AREA.SHARED_WITH_ME],
        icon: "upload_file",
        label: "Upload file",
        slcMode: SlcMode.UNSELECT,
        slcType: [],
        access: [FileAccess.ADMIN, FileAccess.WRITE],
        permis: PERMS.medialibrary_manage_content,
        action: null,
    },
    {
        id: ActionId.UPLOAD_FOLDER,
        area: [AREA.MYFILES, AREA.CORPORATE, AREA.PORTAL, AREA.SHARED_WITH_ME],
        icon: "drive_folder_upload",
        label: "Upload folder",
        slcMode: SlcMode.UNSELECT,
        slcType: [],
        access: [FileAccess.ADMIN, FileAccess.WRITE],
        permis: PERMS.medialibrary_manage_content,
        action: null,
    },
    {
        id: ActionId.APPLY_ATTRIBUTE_SET,
        area: [AREA.MYFILES, AREA.CORPORATE, AREA.PORTAL, AREA.SHARED_WITH_ME],
        icon: "playlist_add_check",
        iconStyle: { fontSize: 22 },
        label: "Apply metadata sets",
        slcMode: SlcMode.LEAST_ONE,
        slcType: [FileType.MEDIA],
        access: [FileAccess.ADMIN],
        permis: PERMS.medialibrary_manage_media_attrs_association,
        action: ACT_VPORT.MODAL_ATTRIBUTE_SET,
    },
    {
        id: ActionId.RENAME_FOLDER,
        area: [AREA.MYFILES, AREA.CORPORATE, AREA.PORTAL, AREA.SHARED_WITH_ME],
        icon: "edit",
        label: "Rename",
        slcMode: SlcMode.SINGLE,
        slcType: [FileType.FOLDER],
        access: [FileAccess.ADMIN, FileAccess.WRITE],
        permis: PERMS.medialibrary_manage_content,
        action: ACT_VPORT.MODAL_RENAME,
    },
    {
        id: ActionId.RENAME_MEDIA,
        area: [AREA.MYFILES, AREA.CORPORATE, AREA.PORTAL, AREA.SHARED_WITH_ME],
        icon: "edit",
        label: "Rename",
        slcMode: SlcMode.SINGLE,
        slcType: [FileType.MEDIA],
        access: [FileAccess.ADMIN, FileAccess.WRITE],
        permis: PERMS.medialibrary_manage_content,
        action: ACT_VPORT.MODAL_RENAME,
    },
    {
        id: ActionId.DOWNLOAD,
        area: [AREA.MYFILES, AREA.CORPORATE, AREA.PORTAL, AREA.SHARED_WITH_ME],
        icon: "file_download",
        label: "Download",
        slcMode: SlcMode.LEAST_ONE,
        slcType: [FileType.MEDIA, FileType.FOLDER],
        access: [],
        permis: PERMS.seecommerce_download,
        action: ACT_VPORT.MODAL_DOWNLOAD,
    },
    {
        id: ActionId.CDN_LINKS,
        area: [AREA.MYFILES, AREA.CORPORATE, AREA.PORTAL, AREA.SHARED_WITH_ME],
        icon: "link",
        iconStyle: { transform: "rotate(-45deg)" },
        label: "Publication",
        slcMode: SlcMode.SINGLE,
        slcType: [FileType.MEDIA],
        access: [FileAccess.ADMIN],
        permis: PERMS.medialibrary_public_cdn,
        action: ACT_VPORT.MODAL_PUBLICATION,
    },
    {
        id: ActionId.SHAREPAGE,
        area: [AREA.MYFILES, AREA.CORPORATE, AREA.PORTAL, AREA.SHARED_WITH_ME],
        icon: "link",
        iconStyle: { transform: "rotate(-45deg)" },
        label: "Get link Share page",
        slcMode: SlcMode.SINGLE,
        slcType: [FileType.FOLDER],
        access: [FileAccess.ADMIN],
        permis: PERMS.medialibrary_share_page,
        action: ACT_VPORT.MODAL_SHAREPAGE,
    },
    {
        id: ActionId.ACCESSES,
        area: [AREA.MYFILES, AREA.SHARED_WITH_ME],
        icon: "share",
        label: "Share",
        slcMode: SlcMode.LEAST_ONE,
        slcType: [FileType.MEDIA, FileType.FOLDER],
        access: [],
        permis: null,
        action: ACT_VPORT.MODAL_ACCESS,
    },
    {
        id: ActionId.MOVE,
        area: [AREA.MYFILES, AREA.CORPORATE, AREA.PORTAL, AREA.SHARED_WITH_ME],
        icon: "drive_file_move_outline",
        label: "Move",
        slcMode: SlcMode.LEAST_ONE,
        slcType: [FileType.MEDIA, FileType.FOLDER],
        access: [FileAccess.ADMIN],
        permis: PERMS.medialibrary_manage_content,
        action: ACT_VPORT.MODAL_MOVE,
    },
    {
        id: ActionId.DELETE,
        area: [AREA.MYFILES, AREA.CORPORATE, AREA.PORTAL, AREA.SHARED_WITH_ME],
        icon: "delete",
        label: "Delete",
        slcMode: SlcMode.LEAST_ONE,
        slcType: [FileType.MEDIA, FileType.FOLDER],
        access: [FileAccess.ADMIN],
        permis: PERMS.medialibrary_manage_content,
        action: ACT_VPORT.MODAL_DELETE,
    },
];
const hideIfPathMinusOne = new Set([
    ActionId.CREATE_FOLDER,
    ActionId.UPLOAD_FOLDER,
    ActionId.UPLOAD_MEDIA,
    ActionId.MOVE,
]);
export const getActions = ({ dispatchViewport, area, access, select, disabled, permissions, }) => {
    return ACTIONS.filter((t) => {
        switch (t.slcMode) {
            case SlcMode.UNSELECT:
                return select.length === 0;
            case SlcMode.LEAST_ONE:
                return select.length >= 1;
            case SlcMode.SINGLE:
                return select.length === 1;
            case SlcMode.MASSIVE:
                return select.length > 1;
            default:
                return false;
        }
    })
        .filter((t) => {
        return !select.some((s) => !new Set(t.slcType).has(s));
    })
        .filter((t) => {
        if (isEmpty(t.permis))
            return true;
        return hasPerms([t.permis], permissions);
    })
        .filter((t) => {
        if (isEmpty(t.access))
            return true;
        return new Set(t.access).has(access);
    })
        .filter((t) => {
        if (isEmpty(t.area))
            return true;
        return new Set(t.area).has(area);
    })
        .filter((t) => {
        if (hideIfPathMinusOne.has(t.id))
            return !isPathMinusOne();
        return true;
    })
        .map((t) => {
        return { ...t, disabled };
    })
        .map((t) => {
        switch (t.id) {
            case ActionId.UPLOAD_MEDIA:
                return {
                    ...t,
                    getAdditionalChildren: ({ onClose }) => (React.createElement(InputML, { onClose: onClose, directory: false, disabled: t.disabled })),
                };
            case ActionId.UPLOAD_FOLDER:
                return {
                    ...t,
                    getAdditionalChildren: ({ onClose }) => (React.createElement(InputML, { onClose: onClose, directory: true, disabled: t.disabled })),
                };
            default:
                return {
                    ...t,
                    onClick: () => {
                        dispatchViewport({ type: t.action });
                    },
                };
        }
    });
};
