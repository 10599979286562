import { v4 as uuidv4 } from "uuid";
import { getExtension } from "@warda/library-ui/utils/getMimeType";
import getFileId from "../../../Uploads/utils/getFileId";
import UploadItemStatus from "../../../Uploads/uploadItemStatus/UploadItemStatus";
const buildIUploadFile = (sessionUploadId, file, hrch, prnt) => {
    const id = getFileId(file);
    const noExtension = !getExtension(file.name);
    return {
        id,
        hrch,
        prnt,
        file,
        sessionUploadId,
        status: noExtension ? UploadItemStatus.Error : UploadItemStatus.Waiting,
        tooltip: noExtension
            ? "Unable to upload a file without extension"
            : undefined,
    };
};
export const buildIUploadFiles = (hrch, prnt, files, mapFolder) => {
    const sessionUploadId = uuidv4();
    if (mapFolder) {
        return files
            .map((file) => {
            const pathFoldername = file.webkitRelativePath.substring(0, file.webkitRelativePath.lastIndexOf("/"));
            return buildIUploadFile(sessionUploadId, file, hrch, mapFolder.has(pathFoldername) ? mapFolder.get(pathFoldername) : -1);
        })
            .filter((o) => o.prnt > 0);
    }
    else {
        return files.map((file) => buildIUploadFile(sessionUploadId, file, hrch, prnt));
    }
};
export const getFolderPaths = (files) => {
    const folderPaths = files
        .map((el) => el.webkitRelativePath)
        .map((str) => str.substring(0, str.lastIndexOf("/")));
    return [...new Set(folderPaths)];
};
export const getTreesFromFolderPaths = (folderPaths) => {
    const trees = folderPaths.reduce((r, p) => {
        const values = p.split("/");
        values.reduce((q, value) => {
            let temp = q.find((o) => o.value === value);
            if (!temp)
                q.push((temp = { value, children: [] }));
            return temp.children;
        }, r);
        return r;
    }, []);
    return trees;
};
export const getPrntFromFolderTree = (folderPath, tree) => {
    const pathElements = folderPath.split("/");
    if (pathElements.length === 0 || pathElements[0] !== tree.value.folderName) {
        return -1;
    }
    else if (pathElements.length === 1 &&
        pathElements[0] === tree.value.folderName) {
        return tree.value.folderId;
    }
    else {
        let node = tree;
        pathElements.slice(1).forEach((pathElement) => {
            if (typeof node === "undefined")
                return -1;
            node = node.children.find((o) => o.value.folderName === pathElement);
        });
        return typeof node === "undefined" ? -1 : node.value.folderId;
    }
};
export const getMapFolder = (folderPaths, folderTree) => {
    const mapFolder = new Map();
    folderPaths.forEach((folderPath) => {
        mapFolder.set(folderPath, getPrntFromFolderTree(folderPath, folderTree));
    });
    return mapFolder;
};
