import { KEY_ENTITY_TYPE } from "./constants";
import { defaultId } from "./AreaLayoutGrid/PanelOptions/SelectLevel/options";
export const updateMngLevels = (levelsInit, levelsPref) => {
    return levelsInit.map((level) => {
        const pref = levelsPref.find((p) => p.id === level.id);
        return {
            ...level,
            setId: (pref === null || pref === void 0 ? void 0 : pref.setId) || "",
        };
    });
};
const getMngLevels = (filters) => {
    try {
        const f = filters.find((f) => f.id === KEY_ENTITY_TYPE);
        const options = f.options;
        const levels = [
            {
                id: defaultId,
                label: "All Levels",
                setId: "",
            },
        ];
        options
            .sort((a, b) => {
            if (a.level > b.level)
                return 1;
            if (a.level < b.level)
                return -1;
            return 0;
        })
            .forEach(({ id, label, level }) => {
            const l = {
                id,
                label: `${level} Level - ${label.toLocaleUpperCase()}`,
                setId: "",
            };
            levels.push(l);
        });
        return levels;
    }
    catch {
        return [];
    }
};
export default getMngLevels;
