import { NotificationType, Service, } from "@warda/library-ui/contexts/AppWebSocketContext";
import { ACTION_MAIN } from "./reducer";
import { ArchiverStatus } from "../interfaces";
const downloadSet = new Set([
    NotificationType.EXPORT,
    NotificationType.ARCHIVING,
]);
const wsCallbackDownloads = (itemsWs, dispatch) => {
    itemsWs
        .filter((c) => downloadSet.has(c.notificationType))
        .map(({ isError, payload }) => {
        if (isError || !!payload.error) {
            dispatch({ type: ACTION_MAIN.DOWNLOADS_ERROR, payload });
            return null;
        }
        if (payload.status === ArchiverStatus.STARTED) {
            dispatch({ type: ACTION_MAIN.DOWNLOADS_UPDATE, payload });
            return null;
        }
        if (payload.status === ArchiverStatus.COMPLETED) {
            dispatch({ type: ACTION_MAIN.DOWNLOADS_FINISH, payload });
            return null;
        }
    });
};
const wsCallbackConfiguration = (itemsWs, dispatch) => {
    const items = itemsWs.filter(({ isError, service, notificationType }) => !isError &&
        service === Service.SC &&
        notificationType === NotificationType.CONFIGURATION);
    if (!!items.length)
        dispatch({ type: ACTION_MAIN.PROPAGATION_UPDATE });
};
const websocketThemeUpdate = async (itemsWs) => {
    const themeUpdated = itemsWs
        .filter((c) => !c.isError)
        .filter((c) => c.service === Service.NOTIFIER)
        .some((c) => c.notificationType === NotificationType.THEME);
    if (themeUpdated) {
        // La notifica per l'aggiornamento del tema tramite websocket è necessaria
        // per gestire tutte le UI nei vari i tab aperti del browser.
        // Quindi all'arrivo di tale notifica devo refreshare la pagina
        location.reload();
    }
};
const wsCallbacks = [
    {
        id: "MAIN_DOWNLOADS",
        callback: wsCallbackDownloads,
    },
    {
        id: "MAIN_CONFIGURATION",
        callback: wsCallbackConfiguration,
    },
    {
        id: "MAIN_THEME_UPDATE",
        callback: websocketThemeUpdate,
    },
];
export default wsCallbacks;
