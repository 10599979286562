import { Service } from "@warda/library-ui/contexts/AppWebSocketContext";
import { AREA } from "../components/AreaMediaLibrary/constants";
import { BASE_URL } from "../constants/keys";
const apiUrls = {
    createMyMediaLibrary: {
        url: () => `${BASE_URL}/${Service.SC}/library/user`,
        method: "POST",
    },
    getFiles: {
        url: (area) => {
            switch (area) {
                case AREA.MYFILES:
                    return `${BASE_URL}/${Service.SC}/library/my-files`;
                case AREA.CORPORATE:
                    return `${BASE_URL}/${Service.SC}/library/corporate-files`;
                case AREA.PORTALS_LIST:
                    return `${BASE_URL}/${Service.SC}/library/touchpoint-list`;
                case AREA.PORTAL:
                    return `${BASE_URL}/${Service.SC}/library/portal-files`;
                case AREA.SHARED_WITH_ME:
                    return `${BASE_URL}/${Service.SC}/library/shared-with-me-files`;
                default:
                    return "";
            }
        },
        method: "POST",
    },
    createFolder: {
        url: () => `${BASE_URL}/${Service.SC}/library/folders`,
        method: "POST",
    },
    deleteMedia: {
        url: (fileId) => `${BASE_URL}/${Service.SC}/library/${fileId}`,
        method: "DELETE",
    },
    deleteFiles: {
        url: () => `${BASE_URL}/${Service.SC}/library/massive-delete`,
        method: "POST",
    },
    uploadNew: {
        url: () => `${BASE_URL}/${Service.SC}/library/upload/new`,
        method: "POST",
    },
    uploadConfirm: {
        url: (fileId) => `${BASE_URL}/${Service.SC}/library/upload/confirm/${fileId}`,
        method: "PUT",
    },
    createPublication: {
        url: (fileId) => `${BASE_URL}/${Service.SC}/library/publications/media/${fileId}`,
        method: "POST",
    },
    removePublication: {
        url: (fileId) => `${BASE_URL}/${Service.SC}/library/publications/media/${fileId}`,
        method: "DELETE",
    },
    publicShare: {
        url: () => `${BASE_URL}/${Service.SC}/library/publicShare`,
        method: "POST",
    },
    getMediaInfo: {
        url: (fileId) => `${BASE_URL}/${Service.SC}/media-info/${fileId}`,
        method: "GET",
    },
    downloadMassive: {
        url: () => `${BASE_URL}/${Service.SC}/library/download-massive`,
        method: "POST",
    },
    moveMedia: {
        url: () => `${BASE_URL}/${Service.SC}/library/media/move`,
        method: "POST",
    },
    updateMediaMetedataSet: {
        url: (id) => `${BASE_URL}/${Service.SC}/library/media/attributes-sets/${id}`,
        method: "POST",
    },
    updateMediaMetedataSetMassive: {
        url: () => `${BASE_URL}/${Service.SC}/library/media/massive-attributes`,
        method: "POST",
    },
    updateMediaMetedata: {
        url: (id) => `${BASE_URL}/${Service.SC}/library/media/attributes/${id}`,
        method: "PUT",
    },
    accessFiles: {
        url: () => `${BASE_URL}/${Service.SC}/library/media/access`,
        method: "POST",
    },
    accessTouch: {
        url: () => `${BASE_URL}/${Service.SC}/library/media/access/touchpoint`,
        method: "POST",
    },
    createTouchpoint: {
        url: () => `${BASE_URL}/${Service.SC}/library/touchpoint`,
        method: "POST",
    },
    renameMedia: {
        url: () => `${BASE_URL}/${Service.SC}/library/rename/media`,
        method: "POST",
    },
    renameFolder: {
        url: () => `${BASE_URL}/${Service.SC}/library/rename/folder`,
        method: "POST",
    },
};
export default apiUrls;
