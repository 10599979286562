import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import Text from "@warda/library-ui/core/Text";
import CardTitle from "../CardTitle";
import CardContent from "../CardContent";
const CardNoType = ({ cardClasses, dispatch, data }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(CardTitle, { classes: cardClasses, dispatch: dispatch, title: "Notification type unknow", data: data, avatarIcon: "close", avatarColor: getTheme().colors.msgFail }),
        React.createElement(CardContent, { classes: cardClasses },
            React.createElement(Text, { size: 0, children: "Please delete this notification" }))));
};
export default CardNoType;
