import { Initialize } from "@warda/library-ui/interfaces";
export var ACTION;
(function (ACTION) {
    ACTION["INIT_LOADING"] = "INIT_LOADING";
    ACTION["INIT_SUCC"] = "INIT_SUCC";
    ACTION["SET_INPUT"] = "SET_INPUT";
})(ACTION || (ACTION = {}));
export const reducerInitState = {
    initialize: Initialize.START,
    options: [],
    optionsMore: 0,
    inputValue: "",
};
const reducer = (state, action) => {
    const newState = { ...state };
    switch (action.type) {
        case ACTION.INIT_LOADING:
            newState.initialize = Initialize.WAIT;
            return newState;
        case ACTION.SET_INPUT:
            newState.initialize = Initialize.START;
            newState.inputValue = action.inputValue;
            return newState;
        case ACTION.INIT_SUCC:
            newState.initialize = Initialize.SUCC;
            newState.optionsMore = action.optionsMore || reducerInitState.optionsMore;
            newState.options = action.options || reducerInitState.options;
            return newState;
        default:
            return state;
    }
};
export default reducer;
