// costanti salvate nel local storage
// preferenze utente
export const listAssigneesSortId = "listAssigneesSortId";
export const listAssigneesSortOrder = "listAssigneesSortOrder";
export const listAssigneesShowDisabled = "listAssigneesShowDisabled";
export const downloadsSlcName = "downloadsSlcName";
export const downloadsSlcType = "downloadsSlcType";
export const downloadsChkPost = "downloadsChkPost";
export const downloadsChkLast = "downloadsChkLast";
export const KEEP_FILTERS_VALUES = "keepFiltersValues";
