import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import Text from "@warda/library-ui/core/Text";
import BtnBase from "@warda/library-ui/core/BtnBase";
import { createUseStyles } from "react-jss";
import classnames from "classnames";
import hexToRgbA from "@warda/library-ui/utils/hexToRgbA";
import { THEME_COLORS_PALETTE, THEME_LABEL_PALETTE, } from "../../constants/colors";
const useStyles = createUseStyles({
    select: {
        height: 40,
        width: 200,
        borderRadius: getTheme().borderRadius,
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "center",
        margin: 2,
        "&:hover": {
            backgroundColor: getTheme().colors.mousehover,
        },
    },
    selected: {
        backgroundColor: ({ theme1 }) => hexToRgbA(theme1, 0.2) + " !important",
    },
    circle: {
        height: 25,
        width: 25,
        borderRadius: 100,
        margin: "0 10px",
        background: ({ theme1, theme2 }) => `linear-gradient(45deg, ${theme1} 0%, ${theme2} 100%)`,
    },
});
const SelectThemePalette = ({ value, draft, onClick }) => {
    const [theme1, theme2] = THEME_COLORS_PALETTE[value];
    const classes = useStyles({ theme1, theme2 });
    const selected = value === draft;
    const cbOnClick = React.useCallback(() => {
        onClick(value);
    }, [onClick, value]);
    return (React.createElement(BtnBase, { color: theme1, className: classnames({
            [classes.select]: true,
            [classes.selected]: selected,
        }), onClick: cbOnClick },
        React.createElement("div", { className: classes.circle }),
        React.createElement(Text, { weight: "bolder", style: selected ? { color: theme1 } : undefined, children: THEME_LABEL_PALETTE[value] }),
        React.createElement("div", { style: { flex: 1 } })));
};
export default SelectThemePalette;
