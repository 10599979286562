import * as React from "react";
import { createUseStyles } from "react-jss";
import { Initialize, Severity } from "@warda/library-ui/interfaces";
import { useHistory } from "react-router-dom";
import { SnackbarProvider, useSnackbar } from "notistack";
import { getTheme, setTheme } from "@warda/library-ui/theme";
import { useAppUserDataCtx } from "@warda/library-ui/contexts/AppUserDataContext";
import { useAppWebSocketCtx, useWebSocketListeners, NotificationType, } from "@warda/library-ui/contexts/AppWebSocketContext";
import { useAppUsersCtx } from "@warda/library-ui/contexts/AppUsersContext";
import { goToBaseUrl } from "@warda/library-ui/layout/Login";
import useKeepAliveAuthToken from "@warda/library-ui/contexts/AppCookiesContext/utils/useKeepAliveAuthToken";
import { useAppHelperCtx } from "@warda/library-ui/contexts/AppHelperContext";
import MainContext from "./MainContext";
import SnackbarMessage from "./SnackbarMessage";
import SnackbarOffline from "./SnackbarOffline";
import MainToolbar from "./MainToolbar";
import { ERROR_SAVE_PREF, PRODUCT_ID } from "../constants/keys";
import webSocketCallbacks from "./websocketCallbacks";
import { ACTION_MAIN, reducer, reducerInitState } from "./reducer";
import getSections from "../utils/getSections";
import WindowDownloads from "./WindowDownloads";
import UploadsSC from "./Uploads/UploadsSC";
import UploadsML from "./Uploads/UploadsML";
import { USER_PREF_DEFAULT, JsonstoreContext, PREF_KEY, } from "../constants/userpref";
import { getUserDatas } from "../api/fetchesApiProfileData";
import { requestTokenWithRefreshToken } from "../api/fetchCookieJwt";
import { addJSPref, putJSPref } from "../api/fetchesApiJsonStore";
import PlaceholderMain from "./PlaceholderMain";
import MainSection from "./MainSection";
import { THEMES } from "../constants/colors";
import updatePreferences from "../utils/updatePreferences";
import { createNotification } from "../api/fetchesApiProducts";
const setPageTitle = (title, { tenantId, tenants }) => {
    const tenant = tenants.find((t) => t.tenantId === tenantId);
    document.title = `${title} ${tenant.label}`;
};
const useStyles = createUseStyles({
    main: {
        backgroundColor: getTheme().colors.background,
        position: "relative",
        flex: 1,
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: "stretch",
    },
    mainContent: {
        position: "relative",
        flex: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        minHeight: 0, // fix sidepanels height
    },
});
const Main = ({ title, viewId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const classes = useStyles({});
    const [state, dispatch] = React.useReducer(reducer, reducerInitState);
    const { propagationStatus, initialize, preferencesInit, preferencesJsId, preferences, downloads, uploadsSC, uploadsML, permissions, userProfile, users, m2ms, } = state;
    const appWebSocketCtx = useAppWebSocketCtx();
    const appUsersCtx = useAppUsersCtx();
    const appHelperCtx = useAppHelperCtx(false);
    const appUserDataCtx = useAppUserDataCtx();
    const onLogout = React.useCallback(async () => {
        const onBoth = () => {
            appUserDataCtx.removeUserData();
            appWebSocketCtx.closeWebSocket();
            console.log("[logout]");
            goToBaseUrl();
            appHelperCtx === null || appHelperCtx === void 0 ? void 0 : appHelperCtx.regenerateCacheBust();
        };
        const groupId = appUserDataCtx.getGroupId();
        await appUsersCtx.logout({ groupId, onSuccess: onBoth, onFailure: onBoth });
    }, [appWebSocketCtx, appUsersCtx, appHelperCtx, appUserDataCtx]);
    React.useEffect(() => {
        appWebSocketCtx.openWebSocket({
            anonymous: false,
        });
    }, [appWebSocketCtx]);
    useWebSocketListeners(webSocketCallbacks, dispatch);
    React.useEffect(() => {
        (async () => {
            if (initialize === Initialize.START) {
                dispatch({ type: ACTION_MAIN.INITIALIZE_LOADING });
                try {
                    const { users, m2ms, userProfile, preferences, preferencesJsId, } = await getUserDatas();
                    let myPrefs = preferences;
                    let myPrefsJsId = preferencesJsId;
                    if (!myPrefsJsId) {
                        // new user without preferences
                        const mngItemPref = await addJSPref(JsonstoreContext.userPreferences, USER_PREF_DEFAULT);
                        myPrefs = mngItemPref.payload;
                        myPrefsJsId = mngItemPref.id;
                    }
                    myPrefs = updatePreferences(myPrefs); // clean unused prefs
                    // setto il tema salvato nelle preferenze
                    const myPrefsThemeId = myPrefs[PREF_KEY.themeId];
                    const currentThemeId = getTheme().id;
                    const myPrefsTheme = THEMES.find((t) => t.id === myPrefsThemeId);
                    if (myPrefsThemeId !== currentThemeId && !!myPrefsTheme) {
                        setTheme(myPrefsTheme);
                        location.reload();
                    }
                    // aggiorno il tema se quello nel local storage è vecchio
                    const myPrefsThemeVersion = myPrefsTheme.version;
                    const currentThemeVersion = getTheme().version;
                    if (myPrefsThemeVersion !== currentThemeVersion) {
                        setTheme(myPrefsTheme);
                        location.reload();
                    }
                    const permissions = userProfile.permissions
                        .find((p) => {
                        return p.applicationId === PRODUCT_ID;
                    })
                        .permissions.map((p) => {
                        return { id: p, label: "" };
                    });
                    const sections = getSections(permissions);
                    if (!users.length) {
                        throw "no users found";
                    }
                    const mySectionIds = sections.map((s) => s.view);
                    if (!mySectionIds.length) {
                        throw "no sections to access";
                    }
                    const viewIdExist = new Set(mySectionIds).has(viewId);
                    if (!viewIdExist) {
                        history.push(`/${mySectionIds[0]}/`);
                    }
                    setPageTitle(title, userProfile);
                    appUserDataCtx.saveUserData({
                        userId: userProfile.sub,
                        groupId: userProfile.groupId,
                        tenantId: userProfile.tenantId,
                    });
                    dispatch({
                        type: ACTION_MAIN.INITIALIZE_SUCC,
                        users,
                        m2ms,
                        permissions,
                        userProfile,
                        preferences: myPrefs,
                        preferencesJsId: myPrefsJsId,
                    });
                }
                catch (err) {
                    console.log("[error] initialize", { err });
                    onLogout();
                }
            }
        })();
    }, [
        onLogout,
        appWebSocketCtx,
        appUserDataCtx,
        history,
        initialize,
        title,
        viewId,
    ]);
    React.useEffect(() => {
        const interval = setInterval(() => {
            if (userProfile &&
                appUserDataCtx.getTenantId() &&
                userProfile.tenantId !== appUserDataCtx.getTenantId()) {
                // CASISTICA: effettuo lo switchTenant mentre ho più tabs del browser aperti.
                // In questo caso devo richiedere il refresh della pagina a tutti i tabs, in modo che
                // tutte le pagine siano allineate allo stesso tema colori selezionato.
                // Ricordo che tendenzialmente nel gruppo CALZEDONIA gli utenti lavorano
                // con temi colori differenti per vedere a colpo d'occhio in quale tenant stanno lavorando.
                // Questo useEffect serve per refreshare i tab non attivi del browser.
                location.reload();
            }
        }, 2000);
        return () => clearInterval(interval);
    }, [userProfile, appUserDataCtx]);
    const openWebSocketIfDisconnected = React.useMemo(() => {
        return async () => {
            if (!appWebSocketCtx.isConnected()) {
                appWebSocketCtx.openWebSocket({
                    anonymous: false,
                });
            }
        };
    }, [appWebSocketCtx]);
    useKeepAliveAuthToken({
        requestTokenWithRefreshToken,
        onSuccessRefresh: openWebSocketIfDisconnected,
        onFailedRefresh: onLogout,
        checkPeriod: 500,
    });
    // save preferences
    React.useEffect(() => {
        (async () => {
            if (userProfile && preferencesInit === Initialize.START) {
                dispatch({ type: ACTION_MAIN.PREFERENCES_INIT_LOADING });
                try {
                    await putJSPref(JsonstoreContext.userPreferences, preferencesJsId, preferences);
                    const themeId = preferences[PREF_KEY.themeId];
                    if (getTheme().id !== themeId) {
                        await createNotification({
                            toUsers: [userProfile.userId],
                            type: NotificationType.THEME,
                            payload: { themeId },
                        });
                        setTheme(THEMES.find((t) => t.id === themeId));
                    }
                }
                catch {
                    enqueueSnackbar(ERROR_SAVE_PREF, { variant: Severity.FAIL });
                }
                dispatch({ type: ACTION_MAIN.PREFERENCES_INIT_STOPPED });
            }
        })();
    }, [
        enqueueSnackbar,
        preferences,
        preferencesInit,
        preferencesJsId,
        userProfile,
    ]);
    if (initialize !== Initialize.SUCC) {
        return React.createElement(PlaceholderMain, null);
    }
    return (React.createElement(SnackbarProvider, { preventDuplicate: true, maxSnack: 3, Components: {
            default: SnackbarMessage,
            success: SnackbarMessage,
            error: SnackbarMessage,
            warning: SnackbarMessage,
            info: SnackbarMessage,
        } },
        React.createElement(MainContext, { dispatchMain: dispatch, permissions: permissions, users: users, m2ms: m2ms, userData: userProfile, userPref: preferences },
            React.createElement("div", { className: classes.main },
                React.createElement(MainToolbar, { title: title, viewId: viewId, onLogout: onLogout, propagationStatus: propagationStatus }),
                React.createElement("div", { className: classes.mainContent },
                    React.createElement(MainSection, { viewId: viewId }))),
            React.createElement(UploadsSC, { uploads: uploadsSC }),
            React.createElement(UploadsML, { uploads: uploadsML }),
            React.createElement(WindowDownloads, { downloads: downloads }),
            React.createElement(SnackbarOffline, null))));
};
export default Main;
