import { AREA_MEDIA_LIBRARY } from "../../constants/keys";
import hasPerms from "../../utils/hasPerms";
import { AREA, AREA_PERMS } from "./constants";
export const NO_VALUE = "-1";
export const KEYPAR_AREA = "area";
export const KEYPAR_PATH = "path";
export const decomposePath = (path) => {
    const [hrch, prnt] = path.split("_");
    if (!hrch || hrch === NO_VALUE || !prnt || prnt === NO_VALUE) {
        return [undefined, undefined];
    }
    return [String(hrch), Number(prnt)];
};
export const composePath = (hrch, prnt) => {
    if (hrch === "" || hrch === undefined || prnt === undefined)
        return "";
    return hrch + "_" + prnt;
};
export const getQueryParams = () => {
    const query = new URLSearchParams(window.location.search);
    const path = query.get(KEYPAR_PATH) || "";
    return {
        area: query.get(KEYPAR_AREA),
        hrch: decomposePath(path)[0],
        prnt: decomposePath(path)[1],
    };
};
export const checkQueryArea = ({ history, search, permissions, }) => {
    const { area } = getQueryParams();
    const myAccess = Object.values(AREA).filter((a) => {
        return hasPerms([AREA_PERMS[a]], permissions);
    });
    const canEnterInArea = new Set(myAccess).has(area);
    if (!area || !canEnterInArea) {
        updateSearchString({
            history,
            search,
            area: myAccess[0] || null,
        });
    }
};
export const updateSearchString = ({ history, search, area, path, }) => {
    const query = new URLSearchParams(search);
    const setQuery = (key, value, fallback) => {
        const newValue = value !== undefined
            ? value !== null
                ? value
                : fallback
            : query.get(key) || fallback;
        query.set(key, newValue);
    };
    setQuery(KEYPAR_AREA, area, AREA.MYFILES);
    setQuery(KEYPAR_PATH, path, NO_VALUE);
    const newSearchString = `/${AREA_MEDIA_LIBRARY}/?${query.toString()}`;
    history.push(newSearchString);
};
