import * as React from "react";
import { createUseStyles } from "react-jss";
import Popover from "@warda/library-ui/core/Popover";
import ListItem from "@warda/library-ui/core/ListItem";
import List from "@warda/library-ui/core/List";
import { SelectType } from "@warda/library-ui/core/Checkbox";
import { useAppUsersCtx } from "@warda/library-ui/contexts/AppUsersContext";
import { useAppUserDataCtx } from "@warda/library-ui/contexts/AppUserDataContext";
import { CtxUserData, CtxDispatchMain } from "../contexts";
const useStyles = createUseStyles({
    menu: {
        minWidth: 230,
    },
    list: {
        margin: 0,
        padding: 0,
    },
});
const WindowTenants = ({ anchorEl, open, onClose }) => {
    const { tenants, tenantId } = React.useContext(CtxUserData);
    const dispatchMain = React.useContext(CtxDispatchMain);
    const classes = useStyles({});
    const [clicked, setClicked] = React.useState("");
    const onClick = React.useCallback((event, id) => {
        setClicked(id);
    }, []);
    const appUserDataCtx = useAppUserDataCtx();
    const appUsersCtx = useAppUsersCtx();
    React.useEffect(() => {
        (async () => {
            if (!!clicked) {
                const tenantId = clicked;
                const onSuccess = () => {
                    appUserDataCtx.setTenantId(tenantId);
                    location.reload();
                };
                await appUsersCtx.switchTenant({ tenantId, onSuccess });
            }
        })();
    }, [clicked, appUsersCtx, dispatchMain, appUserDataCtx]);
    return (React.createElement(Popover, { anchorEl: anchorEl, open: open, onClose: onClose, originAnchor: {
            vertical: "bottom",
            horizontal: "center",
        }, originTransf: {
            vertical: "top",
            horizontal: "center",
        }, className: classes.menu },
        React.createElement(List, { className: classes.list }, tenants.map((t) => (React.createElement(ListItem, { key: t.tenantId, id: t.tenantId, label: t.label, selectType: SelectType.RADIO, selected: t.tenantId === tenantId, onClick: onClick }))))));
};
export default WindowTenants;
