import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route } from "react-router-dom";
import { getTheme, loadFonts, setTheme } from "@warda/library-ui/theme";
import Login from "@warda/library-ui/layout/Login";
import Text from "@warda/library-ui/core/Text";
import { AppCookiesProvider } from "@warda/library-ui/contexts/AppCookiesContext";
import { AppWebSocketProvider, Service, } from "@warda/library-ui/contexts/AppWebSocketContext";
import { AppHelperProvider, } from "@warda/library-ui/contexts/AppHelperContext";
import { AppUsersProvider, useAppUsersCtx, } from "@warda/library-ui/contexts/AppUsersContext";
import { AppMediaTypeProvider } from "@warda/library-ui/contexts/AppMediaTypeContext";
import { AppUserDataProvider } from "@warda/library-ui/contexts/AppUserDataContext";
import { AREA_SHAREPAGE, WEBSOCKET_URL, BASE_URL } from "./constants/keys";
import appHelper from "./constants/appHelper";
import authCookiesHelper from "./constants/authCookiesHelper";
import BackgroundWithIcons from "./components/BackgroundWithIcons";
import Main from "./components/Main";
import AreaSharepage from "./components/AreaSharepage";
import { getPasswordPolicy, onChoosePassword, onDemandPassword, } from "./api/fetchesApiAnonymous";
import { THEMES } from "./constants/colors";
import { getAccessCode } from "./components/AreaSharepage/constants";
const title = "SeeCommerce";
const getMediaTypeHelperProp = ({ sharePage, appHelper, }) => {
    if (sharePage) {
        const accessCode = getAccessCode();
        return {
            mediaTypeHelper: {
                mediaApiUrl: {
                    url: () => `/${Service.SC}/public-media/fileTypes/synoptic/${accessCode}`,
                    method: "GET",
                },
                baseUrl: BASE_URL,
            },
        };
    }
    else {
        return {
            mediaTypeHelper: {
                mediaApiUrl: {
                    url: () => `/${Service.SC}/fileTypes/synoptic`,
                    method: "GET",
                },
                authCookiesHelper: appHelper.authCookiesHelper,
                baseUrl: BASE_URL,
            },
        };
    }
};
const AuthenticatableArea = ({ appHelper, viewId, }) => {
    const appUsersCtx = useAppUsersCtx();
    const handleLogin = React.useCallback(async ({ tenantId, username, password, }) => {
        try {
            const onSuccess = () => {
                location.reload();
            };
            const outcome = await appUsersCtx.login({
                tenantId,
                username,
                password,
                onSuccess,
            });
            return outcome;
        }
        catch (err) {
            console.log(JSON.stringify(err));
            return {
                success: err.status < 400,
                message: err.error || err.message || err.code,
            };
        }
    }, [appUsersCtx]);
    if (!appHelper.authCookiesHelper.isRefreshTokenPresent()) {
        const themeDefault = THEMES[0];
        if (getTheme().id !== themeDefault.id) {
            setTheme(themeDefault);
            location.reload();
            return null;
        }
        return (React.createElement(Login, { header: React.createElement(Text, { weight: "lighter", children: title, style: {
                    color: getTheme().colors.theme1,
                    fontSize: 40,
                    marginBottom: 60,
                } }), background: React.createElement(BackgroundWithIcons, null), onLogin: handleLogin, onDemandPassword: onDemandPassword, onChoosePassword: onChoosePassword, getPasswordPolicy: getPasswordPolicy, hiddenRegistration: true, position: "modal" }));
    }
    return (React.createElement(AppMediaTypeProvider, { ...getMediaTypeHelperProp({ sharePage: false, appHelper }) },
        React.createElement(AppWebSocketProvider, { productId: appHelper.productId, webSocketUrls: appHelper.webSocketUrls, authCookiesHelper: appHelper.authCookiesHelper, baseUrl: WEBSOCKET_URL },
            React.createElement(Main, { title: title, viewId: viewId }))));
};
const AppInitTheme = () => {
    localStorage.setItem("lang", "en");
    loadFonts(); // possono caricare i font all'avvio in quanto SC non possiede font differenti per tema
    return (React.createElement(AppHelperProvider, { appHelper: appHelper },
        React.createElement(BrowserRouter, null,
            React.createElement(Route, { path: "/:viewId?", 
                // eslint-disable-next-line react/jsx-no-bind
                render: (routeConf) => {
                    const { viewId } = routeConf.match.params;
                    if (viewId === AREA_SHAREPAGE) {
                        return (React.createElement(AppMediaTypeProvider, { ...getMediaTypeHelperProp({ sharePage: true, appHelper }) },
                            React.createElement(AppWebSocketProvider, { productId: appHelper.productId, webSocketUrls: appHelper.webSocketUrls, authCookiesHelper: appHelper.authCookiesHelper, baseUrl: WEBSOCKET_URL },
                                React.createElement(AreaSharepage, null))));
                    }
                    return (React.createElement(AppUserDataProvider, null,
                        React.createElement(AppCookiesProvider, { authCookiesHelper: authCookiesHelper },
                            React.createElement(AppUsersProvider, { usersHelper: appHelper },
                                React.createElement(AuthenticatableArea, { appHelper: appHelper, viewId: viewId })))));
                } }))));
};
const container = document.getElementById("app");
const root = createRoot(container);
root.render(React.createElement(AppInitTheme, null));
