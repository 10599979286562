import * as React from "react";
import ViewBlockAsset from "@warda/library-ui/layout/ViewBlockAsset";
import AssetExtension from "@warda/library-ui/core/AssetExtension";
import { isMobile } from "@warda/library-ui/utils/deviceUtils";
import { useAppMediaTypeCtx } from "@warda/library-ui/contexts/AppMediaTypeContext";
import apiUrls from "../../../../api/apiUrlsAnonymous";
import getThumbRendition from "../../../../utils/getThumbRendition";
import { CtxDispatchViewport } from "../../contexts";
import { getAssetPropsMedia } from "../utils";
const hTitle = 50;
const margin = 10;
const sPreview = isMobile() ? 140 : 200;
export const blockHeightMedia = hTitle + margin * 3 + sPreview;
const ViewBlockAssetMedia = ({ id, color, size, data, selected, selectedIds, contextmenuOriginAnchor, contextmenuOriginTransf, contextmenuPosizionZone, onContextMenu, }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const appMediaTypeCtx = useAppMediaTypeCtx();
    const { fileId, mimeType } = data;
    const props = getAssetPropsMedia({
        dispatchViewport,
        color,
        id,
        data,
        selected,
        selectedIds,
        onContextMenu,
        contextmenuOriginAnchor,
        contextmenuOriginTransf,
        contextmenuPosizionZone,
        getMediaKind: appMediaTypeCtx.mediaKind,
    });
    return (React.createElement(ViewBlockAsset, { ...props, width: size[0] - margin * 2, preview: {
            previewWidth: sPreview,
            previewHeight: sPreview,
            srcUrl: apiUrls.mediaDownload.url(fileId, getThumbRendition(2, mimeType, appMediaTypeCtx.mediaKind)),
            mimeType,
            placeholderIcon: props.icon,
        } },
        React.createElement(AssetExtension, { mimeType: mimeType, style: { position: "absolute", bottom: 10, right: 10 } })));
};
export default ViewBlockAssetMedia;
