import { ISortOrder, Initialize, Condition, FileType, } from "@warda/library-ui/interfaces";
import { SheetLayout, } from "../../../interfaces";
import updateFilesSlcId from "./utils/updateFilesSlcId";
import { areFiltersUseful, clearFiltersAttr, } from "../../PanelFilters";
import { getQueryParams } from "../searchString";
import { AREA } from "../constants";
export var ACT_VPORT;
(function (ACT_VPORT) {
    ACT_VPORT["INIT_SEARCH_STARTED"] = "INIT_SEARCH_STARTED";
    ACT_VPORT["INIT_SEARCH_LOADING"] = "INIT_SEARCH_LOADING";
    ACT_VPORT["INIT_SEARCH_RESULT"] = "INIT_SEARCH_RESULT";
    ACT_VPORT["INIT_MORE_STARTED"] = "INIT_MORE_STARTED";
    ACT_VPORT["INIT_MORE_LOADING"] = "INIT_MORE_LOADING";
    ACT_VPORT["INIT_MORE_RESULT"] = "INIT_MORE_RESULT";
    ACT_VPORT["NAVIGATION"] = "NAVIGATION";
    ACT_VPORT["MODAL_CREATE_PORTAL"] = "MODAL_CREATE_PORTAL";
    ACT_VPORT["MODAL_CREATE_FOLDER"] = "MODAL_CREATE_FOLDER";
    ACT_VPORT["MODAL_DELETE"] = "MODAL_DELETE";
    ACT_VPORT["MODAL_PUBLICATION"] = "MODAL_PUBLICATION";
    ACT_VPORT["MODAL_MOVE"] = "MODAL_MOVE";
    ACT_VPORT["MODAL_RENAME"] = "MODAL_RENAME";
    ACT_VPORT["MODAL_ACCESS"] = "MODAL_ACCESS";
    ACT_VPORT["MODAL_ATTRIBUTE_SET"] = "MODAL_ATTRIBUTE_SET";
    ACT_VPORT["MODAL_SHAREPAGE"] = "MODAL_SHAREPAGE";
    ACT_VPORT["MODAL_DOWNLOAD"] = "MODAL_DOWNLOAD";
    ACT_VPORT["WS_IDS_INIT_START_UPDATE"] = "WS_IDS_INIT_START_UPDATE";
    ACT_VPORT["WS_IDS_INIT_START_DELETE"] = "WS_IDS_INIT_START_DELETE";
    ACT_VPORT["WS_IDS_INIT_START_MOVE"] = "WS_IDS_INIT_START_MOVE";
    ACT_VPORT["WS_IDS_INIT_LOADING"] = "WS_IDS_INIT_LOADING";
    ACT_VPORT["WS_IDS_INIT_SUCC"] = "WS_IDS_INIT_SUCC";
    ACT_VPORT["WS_IDS_INIT_FAIL"] = "WS_IDS_INIT_FAIL";
    ACT_VPORT["SELECT_FILE_CLICK"] = "SELECT_FILE_CLICK";
    ACT_VPORT["SELECT_FILE_CHECK"] = "SELECT_FILE_CHECK";
    ACT_VPORT["DESELECT_FILES"] = "DESELECT_FILES";
    ACT_VPORT["SORT"] = "SORT";
    ACT_VPORT["DETAIL"] = "DETAIL";
    ACT_VPORT["PORTAL_REFRESH"] = "PORTAL_REFRESH";
    ACT_VPORT["UPDATE_FILES_DATA"] = "UPDATE_FILES_DATA";
    ACT_VPORT["FILTERS_CHANGE"] = "FILTERS_CHANGE";
    ACT_VPORT["FILTERS_CONDITION"] = "FILTERS_CONDITION";
    ACT_VPORT["GET_INHERIT"] = "GET_INHERIT";
})(ACT_VPORT || (ACT_VPORT = {}));
export const reducerInitState = {
    initSearch: Initialize.START,
    initMore: Initialize.NONE,
    moreType: undefined,
    initMsgErr: "",
    getInherit: Initialize.NONE,
    folderData: undefined,
    folderPath: [],
    sortKey: "name",
    sortOrd: ISortOrder.ASC,
    filters: [],
    filtersCondition: Condition.AND,
    files: [],
    filesArea: getQueryParams().area,
    filesTotal: {
        [FileType.FOLDER]: 0,
        [FileType.MEDIA]: 0,
        [FileType.PORTAL]: 0,
    },
    filesSlcId: [],
    idsWs: [],
    idsWsInit: Initialize.NONE,
    detailLayout: SheetLayout.HIDE,
    modalCreatePortal: false,
    modalCreateFolder: false,
    modalDelete: false,
    modalPublication: false,
    modalMove: false,
    modalRename: false,
    modalAccess: false,
    modalAttributeSet: false,
    modalSharepage: false,
    modalDownload: false,
    accessInherited: [],
};
const reducer = (state, action) => {
    const newState = { ...state };
    switch (action.type) {
        case ACT_VPORT.INIT_SEARCH_STARTED: {
            newState.initSearch = Initialize.START;
            newState.initMsgErr = reducerInitState.initMsgErr;
            newState.filters = clearFiltersAttr(newState.filters);
            return newState;
        }
        case ACT_VPORT.INIT_SEARCH_LOADING: {
            newState.initSearch = Initialize.WAIT;
            newState.initMsgErr = reducerInitState.initMsgErr;
            newState.filesSlcId = reducerInitState.filesSlcId;
            return newState;
        }
        case ACT_VPORT.INIT_SEARCH_RESULT: {
            const result = action.result;
            newState.initSearch = result.initSearch;
            newState.initMsgErr = result.error;
            newState.folderData = result.folderData;
            newState.folderPath = result.folderPath;
            newState.files = result.files;
            newState.filesArea = getQueryParams().area;
            newState.filesTotal = result.filesTotal;
            newState.accessInherited = result.accessInherited;
            return newState;
        }
        case ACT_VPORT.INIT_MORE_STARTED:
            newState.initMore = Initialize.START;
            newState.moreType = action.moreType;
            return newState;
        case ACT_VPORT.INIT_MORE_LOADING:
            newState.initMore = Initialize.WAIT;
            return newState;
        case ACT_VPORT.INIT_MORE_RESULT:
            newState.initMore = reducerInitState.initMore;
            newState.moreType = reducerInitState.moreType;
            newState.files = newState.files.concat(action.moreFiles);
            newState.filesTotal = action.filesTotal;
            return newState;
        case ACT_VPORT.MODAL_CREATE_PORTAL: {
            if (action.open === undefined) {
                newState.modalCreatePortal = !newState.modalCreatePortal;
            }
            else {
                newState.modalCreatePortal = action.open;
            }
            return newState;
        }
        case ACT_VPORT.MODAL_CREATE_FOLDER: {
            if (action.open === undefined) {
                newState.modalCreateFolder = !newState.modalCreateFolder;
            }
            else {
                newState.modalCreateFolder = action.open;
            }
            return newState;
        }
        case ACT_VPORT.MODAL_DELETE: {
            if (action.open === undefined) {
                newState.modalDelete = !newState.modalDelete;
            }
            else {
                newState.modalDelete = action.open;
            }
            return newState;
        }
        case ACT_VPORT.MODAL_PUBLICATION: {
            if (action.open === undefined) {
                newState.modalPublication = !newState.modalPublication;
            }
            else {
                newState.modalPublication = action.open;
            }
            if (action.ids)
                newState.filesSlcId = action.ids;
            return newState;
        }
        case ACT_VPORT.MODAL_ACCESS: {
            if (action.open === undefined) {
                newState.modalAccess = !newState.modalAccess;
            }
            else {
                newState.modalAccess = action.open;
            }
            if (action.ids)
                newState.filesSlcId = action.ids;
            return newState;
        }
        case ACT_VPORT.MODAL_ATTRIBUTE_SET: {
            if (action.open === undefined) {
                newState.modalAttributeSet = !newState.modalAttributeSet;
            }
            else {
                newState.modalAttributeSet = action.open;
            }
            if (action.ids)
                newState.filesSlcId = action.ids;
            return newState;
        }
        case ACT_VPORT.MODAL_SHAREPAGE: {
            if (action.open === undefined) {
                newState.modalSharepage = !newState.modalSharepage;
            }
            else {
                newState.modalSharepage = action.open;
            }
            if (action.ids)
                newState.filesSlcId = action.ids;
            return newState;
        }
        case ACT_VPORT.MODAL_MOVE: {
            if (action.open === undefined) {
                newState.modalMove = !newState.modalMove;
            }
            else {
                newState.modalMove = action.open;
            }
            return newState;
        }
        case ACT_VPORT.MODAL_RENAME: {
            if (action.open === undefined) {
                newState.modalRename = !newState.modalRename;
            }
            else {
                newState.modalRename = action.open;
            }
            return newState;
        }
        case ACT_VPORT.WS_IDS_INIT_START_UPDATE: {
            const newArray = Array.from(newState.idsWs);
            const newFiles = action.files;
            newFiles.forEach((f) => newArray.push(f));
            if (!newArray.length)
                return state;
            newState.idsWs = newArray;
            newState.idsWsInit = Initialize.START;
            return newState;
        }
        case ACT_VPORT.WS_IDS_INIT_START_MOVE: {
            const newFiles = action.files;
            /// CREATE file if move TO current folder
            const newIdsWs = Array.from(newState.idsWs);
            newFiles.forEach((f) => newIdsWs.push(f));
            newState.idsWs = newIdsWs;
            newState.idsWsInit = Initialize.START;
            // DELETE file if move FROM current folder
            const newFilesTotal = { ...newState.filesTotal };
            const deleteIds = new Set(newFiles
                .filter((f) => {
                return newState.files.some((a) => a.id === f.id);
            })
                .map((f) => {
                newFilesTotal[f.type] = newFilesTotal[f.type] - 1;
                return f.id;
            }));
            newState.files = newState.files.filter((f) => {
                return !deleteIds.has(f.id);
            });
            newState.filesSlcId = newState.filesSlcId.filter((f) => {
                return !deleteIds.has(f);
            });
            newState.filesTotal = newFilesTotal;
            return newState;
        }
        case ACT_VPORT.WS_IDS_INIT_START_DELETE: {
            const newFiles = action.files;
            const newFilesTotal = { ...newState.filesTotal };
            const deleteIds = new Set(newFiles
                .filter((f) => {
                return newState.files.some((a) => a.id === f.id);
            })
                .map((f) => {
                newFilesTotal[f.type] = newFilesTotal[f.type] - 1;
                return f.id;
            }));
            newState.files = newState.files.filter((f) => {
                return !deleteIds.has(f.id);
            });
            newState.filesSlcId = newState.filesSlcId.filter((f) => {
                return !deleteIds.has(f);
            });
            newState.filesTotal = newFilesTotal;
            return newState;
        }
        case ACT_VPORT.WS_IDS_INIT_LOADING:
            newState.idsWsInit = Initialize.WAIT;
            return newState;
        case ACT_VPORT.WS_IDS_INIT_SUCC: {
            const idsWs = action.idsWs;
            const filesUp = action.filesUp;
            const newFilesTotal = { ...newState.filesTotal };
            const newFiles = filesUp
                .filter((f) => !f.data.deleted)
                .reduce((acc, f) => {
                const index = acc.findIndex((i) => i.id === f.id);
                if (index === -1) {
                    // create folder or upload media
                    acc.push(f);
                    newFilesTotal[f.type] = newFilesTotal[f.type] + 1;
                }
                else {
                    // update file
                    acc.splice(index, 1, f);
                }
                return acc;
            }, Array.from(newState.files));
            newState.files = newFiles;
            newState.filesTotal = newFilesTotal;
            const idsToRemove = new Set(idsWs.map((f) => f.id));
            newState.idsWs = newState.idsWs.filter((f) => {
                return !idsToRemove.has(f.id);
            });
            newState.idsWsInit = !!newState.idsWs.length
                ? Initialize.START
                : Initialize.NONE;
            return newState;
        }
        case ACT_VPORT.WS_IDS_INIT_FAIL:
            newState.idsWs = reducerInitState.idsWs;
            newState.idsWsInit = reducerInitState.idsWsInit;
            return newState;
        case ACT_VPORT.NAVIGATION:
            newState.files = reducerInitState.files;
            newState.filesTotal = reducerInitState.filesTotal;
            newState.filesSlcId = reducerInitState.filesSlcId;
            newState.filters = clearFiltersAttr(newState.filters);
            newState.initSearch = Initialize.START;
            return newState;
        case ACT_VPORT.SELECT_FILE_CLICK: {
            const { files, filesSlcId } = newState;
            const { id, keyDownCtrl, keyDownMeta, detailLayout } = action;
            newState.filesSlcId = updateFilesSlcId({
                files,
                filesSlcId,
                id,
                keyDownCtrl,
                keyDownMeta,
            });
            if (!!detailLayout) {
                newState.detailLayout = detailLayout;
            }
            if (newState.filesSlcId.length === 1 &&
                areFiltersUseful(newState.filters)) {
                newState.getInherit = Initialize.START;
                newState.folderPath = reducerInitState.folderPath;
                newState.accessInherited = reducerInitState.accessInherited;
            }
            return newState;
        }
        case ACT_VPORT.SELECT_FILE_CHECK: {
            const { files, filesSlcId } = newState;
            const { id, keyDownCtrl, keyDownMeta } = action;
            newState.filesSlcId = updateFilesSlcId({
                files,
                filesSlcId,
                id,
                keyDownCtrl,
                keyDownMeta,
            });
            if (newState.filesSlcId.length === 1 &&
                areFiltersUseful(newState.filters)) {
                newState.getInherit = Initialize.START;
                newState.folderPath = reducerInitState.folderPath;
                newState.accessInherited = reducerInitState.accessInherited;
            }
            return newState;
        }
        case ACT_VPORT.DESELECT_FILES:
            newState.filesSlcId = reducerInitState.filesSlcId;
            return newState;
        case ACT_VPORT.SORT:
            newState.sortKey = action.id;
            newState.sortOrd = action.order;
            newState.initSearch = Initialize.START;
            return newState;
        case ACT_VPORT.DETAIL:
            if (newState.detailLayout === action.layout) {
                newState.detailLayout = SheetLayout.OPEN;
            }
            else {
                newState.detailLayout = action.layout;
            }
            return newState;
        case ACT_VPORT.MODAL_DOWNLOAD:
            newState.modalDownload = !newState.modalDownload;
            return newState;
        case ACT_VPORT.PORTAL_REFRESH:
            if (newState.filesArea === AREA.PORTALS_LIST) {
                newState.initSearch = Initialize.START;
                return newState;
            }
            return state;
        case ACT_VPORT.UPDATE_FILES_DATA:
            newState.files = newState.files.map((f) => {
                const slc = newState.filesSlcId.some((slcId) => slcId === f.id);
                if (!slc)
                    return f;
                return {
                    ...f,
                    data: {
                        ...f.data,
                        ...action.data,
                    },
                };
            });
            return newState;
        case ACT_VPORT.FILTERS_CHANGE:
            newState.filters = action.filters;
            newState.filesSlcId = reducerInitState.filesSlcId;
            newState.initSearch = Initialize.START;
            return newState;
        case ACT_VPORT.FILTERS_CONDITION:
            newState.filtersCondition = action.value;
            newState.filesSlcId = reducerInitState.filesSlcId;
            newState.initSearch = Initialize.START;
            return newState;
        case ACT_VPORT.GET_INHERIT:
            newState.getInherit = Initialize.NONE;
            newState.folderPath = action.folderPath || reducerInitState.folderPath;
            newState.accessInherited =
                action.accessInherited || reducerInitState.accessInherited;
            return newState;
        default:
            console.error("no action defined");
            return state;
    }
};
export default reducer;
