import * as React from "react";
import { createUseStyles } from "react-jss";
import { Initialize } from "@warda/library-ui/interfaces";
import Placeholder from "@warda/library-ui/core/Placeholder";
import { last } from "lodash-es";
import { SnackbarProvider } from "notistack";
import { getTheme } from "@warda/library-ui/theme";
import Toolbar from "@warda/library-ui/core/Toolbar";
import { isMobile } from "@warda/library-ui/utils/deviceUtils";
import { useAppWebSocketCtx, useWebSocketListeners, } from "@warda/library-ui/contexts/AppWebSocketContext";
import AreaContext from "./AreaContext";
import reducer, { reducerInitState, ACT_VPORT } from "./reducer";
import useSearchEs, { searchFiles } from "./useSearchEs";
import View from "./View";
import ToolbarActions from "./ToolbarActions";
import SnackbarMessage from "../SnackbarMessage";
import DialogDetail from "./DialogDetail";
import DialogViewer from "./DialogViewer";
import DialogConfirmDownload from "./DialogConfirmDownload";
import { getTenantId, HTML_ID_DRAWERS } from "./constants";
import setThemeDefault from "../../utils/setThemeDefault";
import webSocketCallbacks from "./websocketCallbacks";
import BtnRefresh from "./Buttons/BtnRefresh";
import TenantLogo from "./TenantLogo";
import BtnDetail from "./Buttons/BtnDetail";
import BtnDownloads from "./Buttons/BtnDownloads";
import FiltersDesktop from "./Filters/FiltersDesktop";
import inLoading from "../../utils/inLoading";
const getPrnt = (folderPath) => {
    try {
        const elasticId = last(folderPath).id;
        const folderId = parseInt(elasticId.split("_")[1]);
        return folderId;
    }
    catch {
        return undefined;
    }
};
const useStyles = createUseStyles({
    main: {
        height: "100vh",
        width: "100vw",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        padding: isMobile() ? 0 : "0 15px 15px 15px",
        boxSizing: "border-box",
        backgroundColor: getTheme().colors.grayDrawer,
    },
    area: {
        flex: 1,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
    },
    areaContent: {
        position: "relative",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        borderRadius: isMobile() ? undefined : getTheme().borderRadius,
        border: isMobile()
            ? undefined
            : `1px solid ${getTheme().colors.grayBorder}`,
        backgroundColor: getTheme().colors.background,
        overflow: "hidden",
    },
});
const Area = () => {
    const classes = useStyles({});
    const [state, dispatch] = React.useReducer(reducer, reducerInitState);
    const { pageHrchId, pagePrntId, initSearch, initMsgErr, folderPath, files, filesSlcId, filesTotal, filters, filtersCondition, sortKey, sortOrd, viewType, viewMode, downloads, dialogDownload, dialogDetail, dialogViewer, initMore, moreType, } = state;
    const tenantId = getTenantId();
    const prnt = getPrnt(folderPath);
    const { searchTime, setSearchTime, searchRes } = useSearchEs({
        hrch: pageHrchId,
        prnt,
        sortKey,
        sortOrd,
        filters,
        filtersCondition,
    });
    const searchLoading = inLoading(initSearch);
    const moreLoading = inLoading(initMore);
    useWebSocketListeners(webSocketCallbacks, dispatch);
    React.useEffect(() => {
        setThemeDefault();
    }, []);
    React.useEffect(() => {
        if (initSearch === Initialize.START) {
            dispatch({ type: ACT_VPORT.INIT_SEARCH_LOADING });
            setSearchTime(new Date().getTime());
        }
    }, [initSearch, setSearchTime]);
    // pass search res to reducer
    React.useEffect(() => {
        if (!!searchRes.result) {
            document.title = "SharePage";
            dispatch({
                type: ACT_VPORT.INIT_SEARCH_RESULT,
                result: searchRes.result,
            });
        }
    }, [searchRes.result]);
    // Avvio websocket
    const appWebSocketCtx = useAppWebSocketCtx();
    React.useEffect(() => {
        appWebSocketCtx.openWebSocket({
            anonymous: true,
            tenantId,
        });
    }, [appWebSocketCtx, tenantId]);
    React.useEffect(() => {
        (async () => {
            if (initMore === Initialize.START) {
                dispatch({ type: ACT_VPORT.INIT_MORE_LOADING });
                const from = files.filter((file) => file.type == moreType).length;
                const { files: moreFiles, filesTotal } = await searchFiles({
                    hrch: pageHrchId,
                    prnt,
                    sortKey,
                    sortOrd,
                    filters,
                    filtersCondition,
                    type: moreType,
                    from,
                });
                // NOTE [DEBITO TECNICO] Possono esserci elementi ripetuti.
                // Questa casistica si presenta solo se l'utente del portale sta
                // navigando mentre un utente SC sta caricando/spostando elementi del portale
                // in quando qui basiamo il FROM sul conteggio elementi attuali.
                // Andrebbe verificato che non si stanno aggiungendo elementi già presenti.
                // Se così fosse vuol dire che sono stati aggiunti elementi prima da recuperare
                // e quelli già caricati non vanno duplicati.
                dispatch({ type: ACT_VPORT.INIT_MORE_RESULT, moreFiles, filesTotal });
            }
        })();
    }, [
        files,
        filters,
        filtersCondition,
        initMore,
        moreType,
        pageHrchId,
        prnt,
        sortKey,
        sortOrd,
    ]);
    return (React.createElement(SnackbarProvider, { preventDuplicate: true, maxSnack: 3, Components: {
            default: SnackbarMessage,
            success: SnackbarMessage,
            error: SnackbarMessage,
            warning: SnackbarMessage,
            info: SnackbarMessage,
        } },
        React.createElement(AreaContext, { dispatch: dispatch },
            React.createElement("div", { className: classes.main, id: HTML_ID_DRAWERS },
                React.createElement("div", { className: classes.area },
                    React.createElement(Toolbar, null,
                        React.createElement(TenantLogo, { style: { margin: "0px 10px" } }),
                        React.createElement(FiltersDesktop, { hidden: isMobile() || initSearch === Initialize.FAIL, filters: filters }),
                        React.createElement("div", { style: { flex: 1 } }),
                        React.createElement(BtnDownloads, { downloads: downloads }),
                        React.createElement(BtnRefresh, null),
                        dialogDetail || isMobile() ? null : React.createElement(BtnDetail, null)),
                    React.createElement("div", { className: classes.areaContent }, initSearch === Initialize.FAIL ? (React.createElement(Placeholder, { icon: "live_help", label: "You can't access this page" })) : (React.createElement(React.Fragment, null,
                        React.createElement(ToolbarActions, { pagePrntId: pagePrntId, files: files, filesSlcId: filesSlcId, folderPath: folderPath, sortKey: sortKey, sortOrd: sortOrd, viewMode: viewMode, viewType: viewType, filters: filters }),
                        React.createElement(View, { searchTime: searchTime, error: initMsgErr, viewMode: viewMode, viewType: viewType, files: files, filesSlcId: filesSlcId, filesTotal: filesTotal, loading: searchLoading, sortKey: sortKey, sortOrd: sortOrd, moreLoading: moreLoading })))))),
            React.createElement(DialogConfirmDownload, { open: dialogDownload, files: files, filesSlcId: filesSlcId, pageHrchId: pageHrchId }),
            React.createElement(DialogDetail, { open: dialogDetail, file: files.find((f) => f.id === last(filesSlcId)) }),
            React.createElement(DialogViewer, { open: dialogViewer, file: files.find((f) => f.id === last(filesSlcId)) }))));
};
export default Area;
