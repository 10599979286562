import * as React from "react";
import { createUseStyles } from "react-jss";
import Divider from "@warda/library-ui/core/Divider";
import List from "@warda/library-ui/core/List";
import Popover from "@warda/library-ui/core/Popover";
import Card from "./Card";
import { ACTION } from "./reducer";
import WindowPlaceholder from "../WindowNotifJob/WindowPlaceholder";
import WindowToolbar from "./WindowToolbar";
const originAnchor = {
    vertical: "bottom",
    horizontal: "right",
};
const originTransf = {
    vertical: "top",
    horizontal: "right",
};
const width = 450;
const useStyles = createUseStyles({
    window: {
        maxHeight: "90vh",
        width,
        minWidth: width,
        maxWidth: width,
        minHeight: 168,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    windowList: {
        flex: 1,
        overflowY: "overlay",
    },
});
const Window = ({ dispatch, open, anchorEl, title, items }) => {
    const classes = useStyles({});
    const onClose = React.useCallback(() => {
        dispatch({ type: ACTION.WINDOW_OPEN, open: false });
    }, [dispatch]);
    return (React.createElement(Popover, { open: open, onClose: onClose, anchorEl: anchorEl, originAnchor: originAnchor, originTransf: originTransf, className: classes.window },
        React.createElement(WindowToolbar, { dispatch: dispatch, title: title, actions: !!items.length }),
        React.createElement(Divider, null),
        items.length === 0 ? (React.createElement(WindowPlaceholder, null)) : (React.createElement(List, { className: classes.windowList }, items.map((n) => (React.createElement(Card, { key: n.id, dispatch: dispatch, data: n })))))));
};
export default Window;
