import { KEY_MEDIA } from "../constants";
import { AnnotationsRes } from "../../../interfaces";
const getListItem = (fileId, list) => {
    let listitem = undefined;
    list.some((c1) => {
        return c1.some((c2) => {
            const is = c2.fileId === fileId;
            if (is)
                listitem = c2;
            return is;
        });
    });
    return listitem;
};
export const decorateMediaAnnotations = (media, annotations) => {
    if (!annotations || !media || !media.length)
        return media;
    return media.map((m) => {
        const { fileId } = m;
        const annResY = getListItem(fileId, annotations.resolved);
        const annResN = getListItem(fileId, annotations.unresolved);
        const annotationsTot = ((annResY === null || annResY === void 0 ? void 0 : annResY.count) || 0) + ((annResN === null || annResN === void 0 ? void 0 : annResN.count) || 0);
        let annotationsRes = AnnotationsRes.NONE;
        if (!!annResY)
            annotationsRes = AnnotationsRes.RESOLVED;
        if (!!annResN)
            annotationsRes = AnnotationsRes.UNRESOLVED;
        return {
            ...m,
            annotationsRes,
            annotationsTot,
        };
    });
};
export const inheritsMediaAnnotations = (newData, oldData) => {
    if (!oldData)
        return newData;
    if (!newData)
        return oldData;
    return {
        ...newData,
        [KEY_MEDIA]: (newData[KEY_MEDIA] || []).map((m) => {
            const oldMedia = oldData[KEY_MEDIA].find((m2) => m.fileId === m2.fileId);
            if (!oldMedia)
                return m;
            return {
                ...m,
                annotationsRes: oldMedia.annotationsRes,
                annotationsTot: oldMedia.annotationsTot,
            };
        }),
    };
};
