import isEmpty from "lodash-es/isEmpty";
import { EditFieldType } from "../../../interfaces";
import { getAttributeId } from "../getAttributeKey";
export const getValuedAttributeKeysDictionary = (item, columns) => {
    const attributeKeys = Object.keys(item)
        .filter((attrKey) => {
        const { attrId } = getAttributeId(attrKey);
        const column = columns.find((c) => c.id === attrId);
        return (!!column &&
            !!column.editField &&
            column.editField.type === EditFieldType.SelectField);
    })
        .filter((attributeKey) => {
        const attributeValue = item[attributeKey];
        return !isEmpty(attributeValue);
    });
    return attributeKeys;
};
