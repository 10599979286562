import * as React from "react";
import Btn from "@warda/library-ui/core/Btn";
import TextLoading from "@warda/library-ui/core/TextLoading";
import { getTheme } from "@warda/library-ui/theme";
import { CtxDispatchViewport } from "../contexts";
import { ACT_VPORT } from "../reducer";
import { MAX_PRODUCTS_SELECTABLE } from "../../../constants/keys";
import { needPagination } from "./utils";
const ViewLoadMore = ({ color = getTheme().colors.theme1, className, style, type, files, filesTotal, moreLoading, }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const { countFiles } = needPagination({
        type,
        files,
        filesTotal,
    });
    const disabled = countFiles >= MAX_PRODUCTS_SELECTABLE;
    const onLoadClik = React.useCallback(() => {
        dispatchViewport({ type: ACT_VPORT.INIT_MORE_STARTED, moreType: type });
    }, [dispatchViewport, type]);
    return (React.createElement("div", { className: className, style: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: `1px solid ${color}`,
            boxSizing: "border-box",
            height: 32,
            width: 120,
            borderRadius: getTheme().borderRadius,
            backgroundColor: getTheme().colors.background,
            ...style,
        } }, moreLoading ? (React.createElement(TextLoading, { color: color })) : (React.createElement(Btn, { style: { margin: 0, flex: 1, textAlign: "center" }, color: color, label: "LOAD MORE", labelStyle: { color }, tooltip: disabled
            ? [
                "You're browsing a large selection!",
                "Narrow your search to find the media you need.",
            ]
            : "Click to load more results", onClick: onLoadClik, disabled: disabled }))));
};
export default ViewLoadMore;
