import parseIdToken from "@warda/library-ui/contexts/AppCookiesContext/utils/parseIdToken";
import { retrieveUserData } from "@warda/library-ui/contexts/AppUserDataContext";
import { BASE_URL } from "../constants/keys";
import authCookiesHelper from "../constants/authCookiesHelper";
export const requestTokenWithRefreshToken = async () => {
    const userData = retrieveUserData();
    const body = JSON.stringify({
        refreshToken: authCookiesHelper.getRefreshToken(),
        applicationId: "seecommerce",
        ...userData,
    });
    const response = await fetch(`${BASE_URL}/users/token/refresh`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body,
    });
    const { status, statusText } = response;
    if (status >= 400)
        return { status, statusText, response };
    const res = await response.json();
    authCookiesHelper.setCookies(res);
    return { status, statusText, response };
};
export const silentTestAndRefresh = async () => {
    const isRefreshRequired = () => {
        const jwt = parseIdToken(authCookiesHelper.getIdToken());
        if (!!jwt) {
            const dateExpiresJwt = Number(`${jwt.exp}000`);
            const dateNow = new Date().getTime();
            return dateNow + 1000 > dateExpiresJwt;
        }
        else {
            return true;
        }
    };
    if (isRefreshRequired()) {
        try {
            await requestTokenWithRefreshToken();
        }
        catch (error) {
            console.error("Refresh failed");
            return false;
        }
    }
    return true;
};
const getOptions = ({ method, jsonBody }) => {
    const options = authCookiesHelper.isApiGatewayAccessWithAuthorizationHeader()
        ? {
            method,
            headers: {
                "Content-Type": "application/json",
                Authorization: authCookiesHelper.getAuthorization(),
            },
        }
        : {
            credentials: "include",
            method,
            headers: { "Content-Type": "application/json" },
        };
    if (method !== "GET" && method !== "HEAD") {
        options.body = JSON.stringify(jsonBody);
    }
    return options;
};
export const fetchCookieJwtWithRefreshToken = async ({ url, method = "GET", jsonBody = {}, }) => {
    try {
        const response = await fetch(url, getOptions({ method, jsonBody }));
        const { status } = response;
        if (status === 401) {
            const refToken = await requestTokenWithRefreshToken();
            if (refToken.status >= 400)
                throw refToken;
            const response = await fetch(url, getOptions({ method, jsonBody }));
            const { status } = response;
            if (status >= 400)
                throw { status, response };
            return await response.json();
        }
        if (status >= 400)
            throw { status, response };
        return await response.json();
    }
    catch (err) {
        throw err;
    }
};
