import { silentTestAndRefresh } from "../api/fetchCookieJwt";
import authCookiesHelper from "../constants/authCookiesHelper";
export const directDownload = (srcUrl) => {
    const anchor = document.createElement("a");
    document.body.appendChild(anchor);
    anchor.href = srcUrl;
    anchor.click();
    document.body.removeChild(anchor);
};
const directDownloadMedia = async (srcUrl) => {
    const isAuthValid = await silentTestAndRefresh();
    if (isAuthValid) {
        const url = await authCookiesHelper.signUrl(srcUrl);
        directDownload(url);
    }
    else {
        console.error("direct download request skipped");
    }
};
export default directDownloadMedia;
