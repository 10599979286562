import * as React from "react";
import Btn from "@warda/library-ui/core/Btn";
import { ISortOrder } from "@warda/library-ui/interfaces";
import { getTheme } from "@warda/library-ui/theme";
import hexToRgbA from "@warda/library-ui/utils/hexToRgbA";
import { isMobile } from "@warda/library-ui/utils/deviceUtils";
import { SelectType } from "@warda/library-ui/core/Checkbox";
import SheetBottom from "../SheetBottom";
import { ATTRIBUTES } from "../constants";
import { CtxDispatchViewport } from "../contexts";
import { ACT_VPORT } from "../reducer";
const optionsKey = ATTRIBUTES.filter((c) => c.sortable);
const optionsOrd = [
    {
        id: ISortOrder.ASC,
        icon: "arrow_upward",
    },
    {
        id: ISortOrder.DESC,
        icon: "arrow_downward",
    },
];
const BtnSort = ({ sortKey, sortOrd }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const [dialog, setDialog] = React.useState(false);
    const icon = optionsOrd.find((o) => o.id === sortOrd).icon;
    const label = optionsKey.find((o) => o.id === sortKey).label;
    const cbOnDialogShow = React.useCallback(() => {
        setDialog(true);
    }, []);
    const cbOnDialogHide = React.useCallback(() => {
        setDialog(false);
    }, []);
    const cbOnChange = React.useCallback((event, newKey) => {
        const newOrd = newKey !== sortKey
            ? sortOrd
            : sortOrd === ISortOrder.ASC
                ? ISortOrder.DESC
                : ISortOrder.ASC;
        dispatchViewport({ type: ACT_VPORT.SORT, key: newKey, ord: newOrd });
        setDialog(false);
    }, [dispatchViewport, sortKey, sortOrd]);
    const cbOnClickOrd = React.useCallback(() => {
        const newOrd = sortOrd === ISortOrder.ASC ? ISortOrder.DESC : ISortOrder.ASC;
        dispatchViewport({ type: ACT_VPORT.SORT, ord: newOrd });
    }, [dispatchViewport, sortOrd]);
    const cbOnClickKey = React.useCallback((e, newKey) => {
        dispatchViewport({ type: ACT_VPORT.SORT, key: newKey });
    }, [dispatchViewport]);
    if (isMobile()) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Btn, { icon: icon, label: label, labelPosition: "left", style: { marginLeft: "-5px" }, onClick: cbOnDialogShow }),
            React.createElement(SheetBottom, { open: dialog, onClose: cbOnDialogHide, titleText: "Sorting by", actions: optionsKey.map((c) => {
                    const selected = c.id === sortKey;
                    const data = {
                        id: c.id,
                        label: c.label,
                        icon,
                        iconStyle: selected ? undefined : { transform: "scale(0)" },
                        style: selected
                            ? { backgroundColor: hexToRgbA(getTheme().colors.theme1, 0.15) }
                            : undefined,
                        onClick: cbOnChange,
                        selected,
                        selectType: SelectType.RADIO,
                        selectPosition: "end",
                    };
                    return data;
                }) })));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Btn, { tooltip: "Reverse sorting direction", icon: icon, onClick: cbOnClickOrd }),
        React.createElement(Btn, { tooltip: "Change sorting", label: label, menu: {
                title: "Sorting by",
                items: optionsKey.map((o) => ({
                    id: o.id,
                    label: o.label,
                    selected: o.id === sortKey,
                    selectType: SelectType.RADIO,
                    onClick: cbOnClickKey,
                })),
            } })));
};
export default BtnSort;
