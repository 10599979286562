import * as React from "react";
import ViewListAsset from "@warda/library-ui/layout/ViewListAsset";
import { useAppMediaTypeCtx } from "@warda/library-ui/contexts/AppMediaTypeContext";
import { CtxDispatchViewport } from "../../contexts";
import apiUrls from "../../../../api/apiUrlsAnonymous";
import getThumbRendition from "../../../../utils/getThumbRendition";
import ViewListAssetChildren from "./ViewListAssetChildren";
import { getAssetPropsMedia } from "../utils";
const previewWidth = 40;
const previewHeight = 40;
const ViewListAssetMedia = ({ id, color, size, data, columns, columnsShow, selected, selectedIds, contextmenuOriginAnchor, contextmenuOriginTransf, contextmenuPosizionZone, onContextMenu, }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const appMediaTypeCtx = useAppMediaTypeCtx();
    const { fileId, mimeType, sizeInBytes, created } = data;
    const props = getAssetPropsMedia({
        dispatchViewport,
        color,
        id,
        data,
        selected,
        selectedIds,
        onContextMenu,
        contextmenuOriginAnchor,
        contextmenuOriginTransf,
        contextmenuPosizionZone,
        getMediaKind: appMediaTypeCtx.mediaKind,
    });
    return (React.createElement(ViewListAsset, { ...props, style: { paddingRight: 15 }, width: size[0], height: size[1], preview: {
            previewWidth,
            previewHeight,
            srcUrl: apiUrls.mediaDownload.url(fileId, getThumbRendition(0, mimeType, appMediaTypeCtx.mediaKind)),
            mimeType,
            placeholderIcon: props.icon,
            placeholderIconStyle: { fontSize: 18 },
        }, children: React.createElement(ViewListAssetChildren, { columns: columns, columnsShow: columnsShow, mimeType: mimeType, sizeInBytes: sizeInBytes, created: created }) }));
};
export default ViewListAssetMedia;
