import * as React from "react";
import { createUseStyles } from "react-jss";
import Popover from "@warda/library-ui/core/Popover";
import { getExtension } from "@warda/library-ui/core/AssetExtension";
import Link from "@warda/library-ui/core/Link";
import Text from "@warda/library-ui/core/Text";
import List from "@warda/library-ui/core/List";
import ListItem from "@warda/library-ui/core/ListItem";
import Divider from "@warda/library-ui/core/Divider";
import { getTheme } from "@warda/library-ui/theme";
import hexToRgbA from "@warda/library-ui/utils/hexToRgbA";
import classnames from "classnames";
import { FileType } from "@warda/library-ui/interfaces";
import getIdsFile from "./utils/getIdsFile";
const useStyles = createUseStyles({
    text: {
        borderRadius: getTheme().borderRadius,
        padding: "2px 7px",
        maxWidth: 190,
        color: ({ color }) => color,
        backgroundColor: "transparent",
        transition: "all 250ms",
    },
    textSelected: {
        backgroundColor: ({ color }) => hexToRgbA(color, 0.2),
    },
    list: {
        "max-height": 300,
        "min-width": 165,
        overflow: "auto",
    },
});
const originAnchor = {
    vertical: "center",
    horizontal: "right",
};
const originTransf = {
    vertical: "auto",
    horizontal: "left",
};
const getLinkText = (files) => {
    const { idsFolder, idsMedia } = getIdsFile(files);
    const countF = idsFolder.length;
    const countM = idsMedia.length;
    return ((!countF ? "" : `${countF} folder${countF > 1 ? "s" : ""}`) +
        (!countF || !countM ? "" : " and ") +
        (!countM ? "" : `${countM} media`));
};
const getListItems = (files) => {
    const listitemsF = [];
    const listitemsM = [];
    files.forEach((f) => {
        if (f.type === FileType.FOLDER) {
            const data = f.data;
            listitemsF.push({
                id: f.id,
                label: data.name,
            });
        }
        if (f.type === FileType.MEDIA) {
            const data = f.data;
            listitemsM.push({
                id: f.id,
                label: data.name,
                extension: getExtension(data.mimeType),
            });
        }
    });
    if (!!listitemsF.length)
        listitemsF.unshift({ id: "_f", title: true, label: "Folders" });
    if (!!listitemsM.length)
        listitemsM.unshift({ id: "_m", title: true, label: "Media" });
    return [].concat(listitemsF, listitemsM);
};
const LinkSelection = ({ className, style, color = getTheme().colors.theme1, files, }) => {
    const classes = useStyles({ color });
    const menuRef = React.useRef(null);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const onMenuOpen = React.useCallback(() => {
        setMenuOpen(true);
    }, []);
    const onMenuCloseCb = React.useCallback(() => {
        setMenuOpen(false);
    }, []);
    if (files.length === 1) {
        return (React.createElement(Text, { ellipsis: true, className: classnames({
                [classes.text]: true,
                [className]: !!className,
            }), style: style, children: files[0].data.name }));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: menuRef, style: { width: "fit-content" } },
            React.createElement(Link, { ellipsis: true, className: classnames({
                    [classes.text]: true,
                    [classes.textSelected]: menuOpen,
                    [className]: !!className,
                }), style: style, color: color, onClick: onMenuOpen, children: getLinkText(files) })),
        React.createElement(Popover, { open: menuOpen, anchorEl: menuRef.current, onClose: onMenuCloseCb, originAnchor: originAnchor, originTransf: originTransf },
            React.createElement(ListItem, { id: "title", label: "Current Selection", selected: true }),
            React.createElement(Divider, null),
            React.createElement(List, { className: classes.list }, getListItems(files).map(({ id, title, label, extension }) => (React.createElement(ListItem, { key: id, id: id, label: label, selected: title, style: { width: 200 }, children: React.createElement(Text, { style: { marginLeft: 10 }, children: extension || "" }) })))))));
};
export default LinkSelection;
