import * as React from "react";
import Btn from "@warda/library-ui/core/Btn";
import { ACT_VPORT } from "../reducer";
import { CtxDispatchViewport } from "../contexts";
const BtnRefresh = ({ className, style }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const cbOnRefresh = React.useCallback(() => {
        dispatchViewport({ type: ACT_VPORT.INIT_SEARCH_STARTED });
    }, [dispatchViewport]);
    return (React.createElement(Btn, { className: className, style: style, icon: "refresh", tooltip: "Refresh", onClick: cbOnRefresh }));
};
export default BtnRefresh;
