import { Service } from "@warda/library-ui/contexts/AppWebSocketContext";
import { INDEX_NAME } from "../../interfaces";
import apiUrls from "../../api/apiUrlsProducts";
import { fetchCookieJwtWithRefreshToken } from "../../api/fetchCookieJwt";
const getPropagation = async () => {
    const method = apiUrls.search.method;
    const fetchConfig = await fetchCookieJwtWithRefreshToken({
        url: apiUrls.search.url(INDEX_NAME.CONFIG, Service.SC),
        method,
        jsonBody: {
            track_total_hits: true,
            from: 0,
            size: 1,
            query: { terms: { _id: ["entity_structure"] } },
        },
    });
    const version = fetchConfig.hits.hits[0]._source.version;
    const url = apiUrls.search.url(INDEX_NAME.TABULARROOT, Service.SC);
    const fetch1 = await fetchCookieJwtWithRefreshToken({
        url,
        method,
        jsonBody: {
            track_total_hits: true,
            from: 0,
            size: 0,
        },
    });
    const fetch2 = await fetchCookieJwtWithRefreshToken({
        url,
        method,
        jsonBody: {
            track_total_hits: true,
            from: 0,
            size: 0,
            query: {
                bool: {
                    must: {
                        term: { configurationVersion: version },
                    },
                },
            },
        },
    });
    const total = fetch1.hits.total.value;
    const count = fetch2.hits.total.value;
    return { count, total };
};
export default getPropagation;
