import { MediaKind } from "@warda/library-ui/contexts/AppMediaTypeContext";
import { RENDITION } from "../interfaces";
const sizeImg = {
    0: RENDITION.image_xs,
    1: RENDITION.image_s,
    2: RENDITION.image_l,
    3: RENDITION.image_xxl,
};
const sizeVid = {
    0: RENDITION.video_LQ,
    1: RENDITION.video_LQ,
    2: RENDITION.video_LQ,
    3: RENDITION.video_HQ,
};
const getThumbRendition = (size, mimeType, mediaKind) => {
    if (mediaKind(mimeType) === MediaKind.VID) {
        return sizeVid[size];
    }
    return sizeImg[size];
};
export default getThumbRendition;
