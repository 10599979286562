import * as React from "react";
import { Severity } from "@warda/library-ui/interfaces";
import InputFile from "@warda/library-ui/core/InputFile";
import getErrorMsg from "@warda/library-ui/utils/getErrorMsg";
import { ACTION_MAIN } from "../../reducer";
import { CtxDispatchMain, CtxSetSnackbar } from "../../contexts";
import { getFolderPaths, getMapFolder, getTreesFromFolderPaths, buildIUploadFiles, } from "./utils/uploadFiles";
import { createFolders } from "../../../api/fetchesApiMediaLibrary";
import checkFiles from "../../Uploads/utils/checkFiles";
import { getQueryParams } from "../searchString";
const getUploadsML = async ({ directory, hrch, prnt, files, setSnackbar, }) => {
    if (!files.length) {
        setSnackbar(Severity.INFO, "No file found in the selected folder");
        return [];
    }
    files = checkFiles(files, []);
    if (!files.length) {
        return [];
    }
    if (directory) {
        try {
            const folderPaths = getFolderPaths(files);
            const trees = getTreesFromFolderPaths(folderPaths);
            if (trees.length != 1)
                throw "Failed to upload folder";
            const folderTree = await createFolders({
                hrch,
                prnt,
                tree: trees[0],
            });
            const mapFolder = getMapFolder(folderPaths, folderTree);
            return buildIUploadFiles(hrch, prnt, files, mapFolder);
        }
        catch (err) {
            const errMsg = await getErrorMsg(err);
            setSnackbar(Severity.WARN, errMsg);
            return [];
        }
    }
    return buildIUploadFiles(hrch, prnt, files, null);
};
const InputML = ({ onClose, directory, disabled }) => {
    const dispatchMain = React.useContext(CtxDispatchMain);
    const setSnackbar = React.useContext(CtxSetSnackbar);
    const { hrch, prnt } = getQueryParams();
    const onChangeInput = React.useCallback(async (event) => {
        const value = await getUploadsML({
            directory,
            hrch,
            prnt,
            files: Array.from(event.target.files),
            setSnackbar,
        });
        dispatchMain({ type: ACTION_MAIN.UPLOADS_ML, value });
        onClose(event);
    }, [directory, dispatchMain, hrch, onClose, prnt, setSnackbar]);
    return (React.createElement(InputFile, { acceptFiles: "*", onChangeInput: onChangeInput, multiple: true, directory: directory ? "" : undefined, disabled: disabled }));
};
export default InputML;
