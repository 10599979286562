import { Initialize } from "@warda/library-ui/interfaces";
import { v4 as uuidv4 } from "uuid";
import { PropagationStatus, } from "../interfaces";
import getFileId from "./Uploads/utils/getFileId";
import { UploadItemStatus } from "./Uploads";
import { wsItemFinish, wsItemUpdate, wsItemError } from "./websocketUpdates";
import updatePreferences from "../utils/updatePreferences";
export var ACTION_MAIN;
(function (ACTION_MAIN) {
    ACTION_MAIN["REFRESH_TIME"] = "REFRESH_TIME";
    ACTION_MAIN["PROPAGATION_UPDATE"] = "PROPAGATION_UPDATE";
    ACTION_MAIN["PROPAGATION_FINISH"] = "PROPAGATION_FINISH";
    ACTION_MAIN["DOWNLOADS__RESET"] = "DOWNLOADS__RESET";
    ACTION_MAIN["DOWNLOADS_TO_ADD"] = "DOWNLOADS_TO_ADD";
    ACTION_MAIN["DOWNLOADS_ERROR"] = "DOWNLOADS_ERROR";
    ACTION_MAIN["DOWNLOADS_UPDATE"] = "DOWNLOADS_UPDATE";
    ACTION_MAIN["DOWNLOADS_FINISH"] = "DOWNLOADS_FINISH";
    ACTION_MAIN["UPLOADS_SC"] = "UPLOADS_SC";
    ACTION_MAIN["UPLOADS_ML"] = "UPLOADS_ML";
    ACTION_MAIN["PREFERENCES_INIT_STARTED"] = "PREFERENCES_INIT_STARTED";
    ACTION_MAIN["PREFERENCES_INIT_LOADING"] = "PREFERENCES_INIT_LOADING";
    ACTION_MAIN["PREFERENCES_INIT_STOPPED"] = "PREFERENCES_INIT_STOPPED";
    ACTION_MAIN["INITIALIZE_LOADING"] = "INITIALIZE_LOADING";
    ACTION_MAIN["INITIALIZE_SUCC"] = "INITIALIZE_SUCC";
    ACTION_MAIN["UPDATE_CONFIGURATION"] = "UPDATE_CONFIGURATION";
    ACTION_MAIN["WS_INITIALIZED"] = "WS_INITIALIZED";
})(ACTION_MAIN || (ACTION_MAIN = {}));
export const reducerInitState = {
    propagationStatus: PropagationStatus.NONE,
    initialize: Initialize.START,
    preferencesInit: Initialize.NONE,
    preferencesJsId: undefined,
    preferences: undefined,
    downloads: [],
    uploadsSC: [],
    uploadsML: [],
    permissions: [],
    userProfile: undefined,
    users: [],
    m2ms: [],
    mimetypesSC: null,
    mimetypesDA: null,
};
export const reducer = (state, action) => {
    const newState = { ...state };
    switch (action.type) {
        case ACTION_MAIN.DOWNLOADS_TO_ADD: {
            const { correlationId, filename } = action.payload;
            const newDownloads = Array.from(newState.downloads);
            newDownloads.push({
                id: correlationId,
                loading: true,
                name: filename,
                url: "",
            });
            newState.downloads = newDownloads;
            return newState;
        }
        case ACTION_MAIN.DOWNLOADS_ERROR: {
            const newDownloads = wsItemError(newState.downloads, action.payload);
            // nothing to update - return state to avoid rerender
            if (!newDownloads.length)
                return state;
            newState.downloads = newDownloads;
            return newState;
        }
        case ACTION_MAIN.DOWNLOADS_UPDATE: {
            const newDownloads = wsItemUpdate(newState.downloads, action.payload);
            // nothing to update - return state to avoid rerender
            if (!newDownloads.length)
                return state;
            newState.downloads = newDownloads;
            return newState;
        }
        case ACTION_MAIN.DOWNLOADS_FINISH: {
            const newDownloads = wsItemFinish(newState.downloads, action.payload);
            // nothing to update - return state to avoid rerender
            if (!newDownloads.length)
                return state;
            newState.downloads = newDownloads;
            return newState;
        }
        case ACTION_MAIN.DOWNLOADS__RESET:
            newState.downloads = reducerInitState.downloads;
            return newState;
        case ACTION_MAIN.UPLOADS_SC: {
            const sessionUploadId = uuidv4();
            const { files, metadata } = action;
            newState.uploadsSC = files.map((f) => ({
                id: getFileId(f),
                sessionUploadId,
                status: UploadItemStatus.Waiting,
                file: f,
                metadata,
                deferTenantSpecificRenditions: true,
            }));
            return newState;
        }
        case ACTION_MAIN.UPLOADS_ML: {
            const value = action.value;
            if (value.length === 0)
                return state;
            newState.uploadsML = value;
            return newState;
        }
        case ACTION_MAIN.INITIALIZE_SUCC:
            newState.users = action.users;
            newState.m2ms = action.m2ms;
            newState.permissions = action.permissions;
            newState.userProfile = action.userProfile;
            newState.preferencesJsId = action.preferencesJsId;
            newState.preferences = action.preferences;
            newState.initialize = Initialize.SUCC;
            return newState;
        case ACTION_MAIN.INITIALIZE_LOADING:
            newState.initialize = Initialize.WAIT;
            return newState;
        case ACTION_MAIN.PROPAGATION_UPDATE:
            newState.propagationStatus = PropagationStatus.FETCHING;
            return newState;
        case ACTION_MAIN.PROPAGATION_FINISH:
            newState.propagationStatus = PropagationStatus.COMPLETE;
            return newState;
        case ACTION_MAIN.PREFERENCES_INIT_STARTED: {
            const oldPref = newState.preferences;
            const newPref = updatePreferences(newState.preferences, action.payload);
            const needToUpdate = JSON.stringify(oldPref) !== JSON.stringify(newPref);
            if (needToUpdate) {
                newState.preferencesInit = Initialize.START;
                newState.preferences = newPref;
                return newState;
            }
            else {
                return state;
            }
        }
        case ACTION_MAIN.PREFERENCES_INIT_LOADING:
            newState.preferencesInit = Initialize.WAIT;
            return newState;
        case ACTION_MAIN.PREFERENCES_INIT_STOPPED:
            newState.preferencesInit = Initialize.NONE;
            return newState;
        default:
            return newState;
    }
};
