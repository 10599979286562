export var ACT_PANEL;
(function (ACT_PANEL) {
    ACT_PANEL["RESET"] = "RESET";
    ACT_PANEL["OPEN_MANAGER"] = "OPEN_MANAGER";
    ACT_PANEL["OPEN_APPLIED"] = "OPEN_APPLIED";
    ACT_PANEL["SEARCH_INPUT"] = "SEARCH_INPUT";
})(ACT_PANEL || (ACT_PANEL = {}));
export const initState = {
    mngSetsOpen: false,
    appliedOpen: false,
    searchInput: "",
};
export const reducer = (state, action) => {
    const newState = { ...state };
    switch (action.type) {
        case ACT_PANEL.RESET:
            return initState;
        case ACT_PANEL.OPEN_MANAGER:
            newState.mngSetsOpen = action.open;
            return newState;
        case ACT_PANEL.OPEN_APPLIED:
            newState.appliedOpen = action.open;
            return newState;
        case ACT_PANEL.SEARCH_INPUT:
            newState.searchInput = action.value;
            return newState;
        default:
            return newState;
    }
};
