import * as React from "react";
import Placeholder from "@warda/library-ui/core/Placeholder";
import ViewerVideo from "@warda/library-ui/core/ViewerVideo";
import ImageZoom from "@warda/library-ui/core/ImageZoom";
import { MediaKind, useAppMediaTypeCtx, } from "@warda/library-ui/contexts/AppMediaTypeContext";
import getFileIcon from "@warda/library-ui/utils/getFileIcon";
import ViewerBox from "../../ViewerBox";
import apiUrls from "../../../api/apiUrlsAnonymous";
import { RENDITION, } from "../../../interfaces";
import { getViewerBoxData, getViewerImgSrcFirmed, } from "../../../api/fetchesApiAnonymous";
const Viewer = ({ file }) => {
    const appMediaTypeCtx = useAppMediaTypeCtx();
    const { fileId, mimeType } = file.data;
    const mediaKind = appMediaTypeCtx.mediaKind(mimeType);
    if (mediaKind === MediaKind.VID) {
        return (React.createElement(ViewerVideo, { src: apiUrls.mediaDownload.url(fileId, RENDITION.video_HQ) }));
    }
    if (mediaKind === MediaKind.IMG) {
        return (React.createElement(ImageZoom, { src: apiUrls.mediaZoom.url(fileId), getSrcFirmed: getViewerImgSrcFirmed }));
    }
    if (mediaKind === MediaKind.DOC) {
        return React.createElement(ViewerBox, { fileId: fileId, getData: getViewerBoxData });
    }
    return (React.createElement("div", { style: {
            position: "relative",
            flex: 1,
            display: "flex",
            alignItems: "stretch",
            overflow: "hidden",
        } },
        React.createElement(Placeholder, { icon: getFileIcon(mimeType, appMediaTypeCtx.mediaKind), iconStyle: { marginBottom: 10 }, label: "Zoom unavailable" })));
};
export default Viewer;
